import React, { useRef, useState, useEffect } from "react";
import { useCustomSurveyStepper } from "../../../../../contexts/custom-survey";
import { sendSurveyQuestion } from "../../../../../shared/analytics";
import {
  SurveyComponentProps,
  SurveyData,
} from "../../../../../types/CustomSurvey";
import { NumberInput } from "../../../../NewComponents/Common/NumberInput";
import { SurveyNavigationButtons } from "../../../Common/SurveyNavigationButtons";

function surveyToComponent(data: SurveyData) {
  return data.height;
}

function componentToSurvey(value: number) {
  return {
    height: value,
  };
}

export function HeightSurveyQuestion({ data, onDone }: SurveyComponentProps) {
  const _data = surveyToComponent(data);
  const inchesRef = useRef<HTMLInputElement>(null);
  const feetRef = useRef<HTMLInputElement>(null);

  const [inches, setInches] = useState<string>(`${_data ? _data % 12 : ""}`);
  const [feet, setFeet] = useState<string>(
    `${_data ? Math.floor(_data / 12) : ""}`
  );
  const { onBack } = useCustomSurveyStepper();

  useEffect(() => {
    if (feetRef.current) {
      feetRef.current.focus();
    }
  }, []);

  function submit() {
    if (inches === "" || feet === "") return;
    const inchesNumber = +inches;
    const feetNumber = +feet;

    sendSurveyQuestion("height");
    onDone(componentToSurvey(feetNumber * 12 + inchesNumber));
  }

  return (
    <div className="survey-question survey-question--small">
      <p className="survey-question__title">What is your height?</p>
      <p className="survey-question__descr paragraph-font--color" />
      <div className="survey-question__row">
        <NumberInput
          id="height-feet"
          name="height-feet"
          ref={feetRef}
          min={0}
          step={1}
          onChange={(value) => {
            if (/^[0-9]*$/.test(value)) {
              setFeet(value);
              if (value.length > 0 && inchesRef.current)
                inchesRef.current.focus();
            }
          }}
          required
          value={feet}
          label="Feet"
          className="survey-question__input"
        />
        <NumberInput
          id="height-inch"
          name="height-inch"
          ref={inchesRef}
          min={0}
          max={11}
          step={1}
          onChange={(value: any) => {
            if (/^[0-9]*$/.test(value) && value >= 0 && value <= 11) {
              setInches(value);
            }
          }}
          required
          value={inches}
          label="Inches"
          className="survey-question__input"
        />
      </div>
      <SurveyNavigationButtons
        submit={submit}
        onBack={onBack}
        disabledContinue={!(inches && feet) || +inches > 11}
      />
    </div>
  );
}
