import moment from "moment-timezone";
import { RegistrationSurvey } from "../../types/CustomSurvey";
import { PatientAccountType } from "../../types/Patient";

export function getAccountType(data: RegistrationSurvey) {
  const isMinorFromBirthDate =
    moment().diff(moment(data.birthDate.replaceAll(" ", "")), "years") < 18;

  if (data.isChild) {
    return PatientAccountType.PARENT;
  }

  if (isMinorFromBirthDate && !data.isChild) {
    return PatientAccountType.CHILD;
  }

  return PatientAccountType.ADULT;
}
