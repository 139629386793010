import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ENV_CONFIG } from "../../config";
import { AuthenticationService } from "../../services/cognito";
import { RealTimeNotification } from "../../utils/real-time";

const sendNotificationMutation = `
  mutation SendNotification($input: SendNotificationInput!) {
    sendNotification(input: $input) {
      id
      recipientIds
      roles
      broadcast
      message
      type
      timestamp
    }
  }
`;

export const notificationsApiSlice = createApi({
  reducerPath: "api/notifications",
  tagTypes: ["patient", "care-team", "note"],
  baseQuery: fetchBaseQuery({
    baseUrl: ENV_CONFIG.NOTIFICATION_SERVICE_GRAPHQL_URL,
    prepareHeaders: async (headers) => {
      const token = await AuthenticationService.getAccessToken();
      headers.set("Authorization", token);
      return headers;
    },
  }),
  endpoints: (builder: any) => ({
    sendNotification: builder.mutation<
      {
        recipientIds?: string[];
        roles?: string[];
        broadcast?: boolean;
        metadata?: string;
        message?: string;
        type: string;
      },
      RealTimeNotification
    >({
      query: ({ recipientIds, roles, broadcast, message, type, metadata }) => ({
        url: "",
        method: "POST",
        body: JSON.stringify({
          query: sendNotificationMutation,
          variables: {
            input: {
              recipientIds,
              roles,
              broadcast,
              metadata,
              message,
              type,
            },
          },
        }),
      }),
      transformResponse: (response: any) => {
        if (response.errors?.[0]?.message) {
          throw new Error(response.errors[0].message);
        }
        return response.data.sendNotification;
      },
    }),
  }),
});

export const { useSendNotificationMutation } = notificationsApiSlice;
