import React, { useState, useEffect, useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import "./style.scss";
import "./calendar.css";
import Select from "react-select";
import { TreatmentPlanTable } from "../../../Components/Common/TreatmentPlanTable";
import {
  getSessionState,
  setActivePatient,
} from "../../../../features/session";
import TreatmentHistory from "../../../Components/Common/TreatmentHistory";
import VisitsHistory from "../../../NewComponents/Common/VisitsHistory";
import FollowUpCard from "../../../NewComponents/Common/FollowUpCard";
import IpledgeDetails from "../../../NewComponents/Provider/IpldedgeDetails";
import { useGetEmployeeQuery } from "../../../../features/api/employees";
import {
  useGetFollowUpsByPatientIdQuery,
  useSubmitTreatmentPlanMutation,
} from "../../../../features/api/follow-ups";
import {
  FollowUp,
  FollowUpTreatmentPlan,
  TreatmentPlanMedication,
} from "../../../../types/FollowUp";
import { NotificationLayerContext } from "../../../NewComponents/Common/NotificationLayer";
import { Skeleton } from "../../../NewComponents/Common/Skeleton";
import { Button } from "../../../NewComponents/Common/Button";
import {
  useGetPatientByIdQuery,
  useSetFlagMutation,
} from "../../../../features/api/patients";
import { Provider } from "../../../../types/Employee";
import {
  getBloodSlipTemplate,
  getPregnancyTestTemplate,
  TemplatesList,
} from "../../../NewComponents/Common/SendBloodSlipPopup";
import BloodSlipFormPopupGenerator from "./bloodslipForm";
import { PopupLayerContext } from "../../../NewComponents/Common/PopupLayer";
import { ConfirmChoicePopupGenerator } from "../ConfirmChoicePopup";
import { Patient } from "../../../../types/Entities/Patient";
import { ENV_CONFIG } from "../../../../config";
import {
  useGetAccutaneByPatientIdQuery,
  usePopulateBloodSlipPdfMutation,
} from "../../../../features/api/accutane";
import { useGetAllMedicationsQuery } from "../../../../features/api/medications";
import "../../../NewComponents/Common/Select/style.scss";
import AdministratorDocuments from "../../../NewComponents/Administrator/AdministratorDocuments";
import { submitLabSlip } from "../../../../utils/lab-work/submit-lab-slip";
import { getLabCatalog } from "../../../../services/honeydew-api/labs/get-lab-catalog";
import { getLabPresets } from "../../../../services/honeydew-api/labs/get-lab-presets";
import { createLabOrder } from "../../../../services/honeydew-api/labs/create-lab-order";
import { getToday } from "../../../../utils/dates";
import { parseFieldsToPdf } from "../../../../utils/lab-work/parse-fields-to-pdf";
import { FEATURES } from "../../../../data/available-features";
import { useFeatureToggle } from "../../../../contexts/feature-toggle";
import { sendShow } from "../../../../shared/analytics";

enum VIEWS {
  TREATMENT_PLAN,
  MED_BACKGROUND,
  DOCUMENTS,
}

export interface FieldStructure {
  field_name: string;
  prefilled_text: string;
}

type TreatmentPlanPrefillData = {
  medications: TreatmentPlanMedication[] | null;
  message: string | null;
  preset: string | null;
  nextFollowUpDate: number | null;
};

const getFilledTreatmentPlanData = (
  followUps: FollowUp[]
): TreatmentPlanPrefillData | null => {
  const lastFollowUp = followUps.length < 1 ? null : followUps[0];

  if (lastFollowUp && lastFollowUp.treatmentPlan) {
    // treatment plan edit
    return {
      medications: lastFollowUp.treatmentPlan.medications,
      message: lastFollowUp.treatmentPlan.message,
      preset: lastFollowUp.treatmentPlan.preset || null,
      nextFollowUpDate: lastFollowUp.treatmentPlan.nextFollowUpDate,
    };
  }
  if (followUps.length <= 1) {
    return null;
  }

  const previousFollowUp = followUps[1];
  return {
    medications: previousFollowUp.treatmentPlan?.medications || null,
    nextFollowUpDate: null,
    message: null,
    preset: previousFollowUp.treatmentPlan?.preset || null,
  };
};

export function PatientFollowUpVisitMobileScreen() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { showError, showSuccess, showWarn } = useContext(
    NotificationLayerContext
  );
  const [view, setView] = useState(VIEWS.TREATMENT_PLAN);
  const [submitInProgress, setSubmitProgressStatus] = useState(false);
  const { userId: providerId, activePatientId } = useSelector(getSessionState);
  const [isPregnantCapability, setIsPregnantCapability] = useState(true);
  const [isBloodWorkRequired, setIsBloodWorkRequired] = useState(true);
  const [bloodSlipAutocreate, setBloodSlipAutocreate] = useState(true);
  const [initialTreatmentPlanData, setInitialTreatmentPlanData] = useState({});
  const { showPopup } = useContext(PopupLayerContext);
  const accutaneQuery = useGetAccutaneByPatientIdQuery(activePatientId);
  const providerQuery = useGetEmployeeQuery(providerId as string, {
    skip: !providerId,
  });
  const followUpsQuery = useGetFollowUpsByPatientIdQuery(
    activePatientId as string,
    {
      skip: !activePatientId,
    }
  );
  const patientQuery = useGetPatientByIdQuery(activePatientId as string, {
    skip: !activePatientId,
  });
  const [bloodSlipData, setBloodSlipData] = useState<Record<
    string,
    any
  > | null>(null);
  const [pregnancyTestData, setPregnancyTestData] = useState<Record<
    string,
    any
  > | null>(null);
  const { isFeatureEnabled } = useFeatureToggle();
  const enabled = useMemo(() => isFeatureEnabled(FEATURES.VITAL), []);

  const [submitTreatmentPlan] = useSubmitTreatmentPlanMutation();
  const [populatePdf] = usePopulateBloodSlipPdfMutation();
  const [catalog, setCatalog] = useState([]);
  const [presets, setPresets] = useState([]);

  const { userId: patientId } = useParams<{ userId: string }>();

  useEffect(() => {
    if (followUpsQuery && followUpsQuery?.data) {
      setInitialTreatmentPlanData(
        getFilledTreatmentPlanData(followUpsQuery?.data) || {}
      );
    }
  }, [followUpsQuery]);

  useEffect(() => {
    dispatch(setActivePatient(patientId || null));
  }, [patientId]);

  const getTests = async () => {
    const labCatalogQuery = getLabCatalog();
    const labPresetsQuery = getLabPresets();
    const [labCatalog, labPresets] = await Promise.all([
      labCatalogQuery,
      labPresetsQuery,
    ]);
    const mappedPresets = labPresets.map((preset) => ({
      ...preset,
      tests: preset.tests.map((test) => {
        const option = labCatalog.find(
          (catalogTest) => catalogTest.id === test
        );
        return {
          label: option?.name || "",
          value: option?.id || "",
        };
      }),
    }));
    setPresets(mappedPresets);
    setCatalog(labCatalog);
  };

  useEffect(() => {
    getTests();
    return () => {
      dispatch(setActivePatient(null));
    };
  }, []);

  useEffect(() => {
    setIsPregnantCapability(!patientQuery.data?.flags?.isNotPregnantCapability);
    setIsBloodWorkRequired(!patientQuery.data?.flags?.isStopBloodWorkTask);
  }, [patientQuery.data]);

  const [messageText, setMessageText] = useState("");
  const [date, setDate] = useState<number | null>(null);
  const [tableData, setTableData] = useState<TreatmentPlanMedication[] | null>(
    null
  );
  const [treatmentPlanPreset, setTreatmentPlanPreset] = useState<string | null>(
    null
  );
  const [loaded, setLoaded] = useState(false);
  const [loadingFollowUps, setLoadingFollowUps] = useState(false);
  const [accutaneActive, setAccutaneActive] = useState(false);
  const medicationsQuery = useGetAllMedicationsQuery(null);
  const [isNewMessageSelected, setIsNewMessageSelected] = useState(false);
  const [isChangeDetected, setIsChangeDetected] = useState(false);

  const medicationQuestions = [
    {
      label: "Can this patient get pregnant?",
      value: "pregnantCapability",
      when: () => patientQuery.data?.medicalBackground?.sex === "Female",
      popupModal: ConfirmChoicePopupGenerator,
      setOptionFlag: setIsPregnantCapability,
      optionFlag: isPregnantCapability,
      popupModalText:
        "Are you sure you want to change patient’s child bearing potential?",
      flagKey: "isNotPregnantCapability",
      options: [
        {
          value: true,
          label: "Yes, this patient can get pregnant",
        },
        {
          value: false,
          label: "No, this patient cannot get pregnant",
        },
      ],
    },
    {
      label: "Require additional blood work next month?",
      value: "requireAdditionalBloodWork",
      popupModal: ConfirmChoicePopupGenerator,
      setOptionFlag: setIsBloodWorkRequired,
      optionFlag: isBloodWorkRequired,
      popupModalText: "Please confirm that patient may stop bloodwork",
      flagKey: "isStopBloodWorkTask",
      buttonText: "YES, CONFIRM",
      options: [
        {
          value: true,
          label: "Yes",
        },
        {
          value: false,
          label: "No",
        },
      ],
    },
    {
      label: "Auto-generate the default lab slip for this treatment?",
      value: "autogenerateLabSlip",
      when: () =>
        !followUpsQuery.data?.[0]?.treatmentPlan &&
        !accutaneQuery.data?.bloodWork?.populated &&
        ((providerQuery.data as Provider)?.presignedBloodSlipId ||
          (providerQuery.data as Provider)?.internalPresignedBloodSlipKey) &&
        accutaneActive,
      popupModal: ConfirmChoicePopupGenerator,
      setOptionFlag: setBloodSlipAutocreate,
      optionFlag: bloodSlipAutocreate,
      popupModalText: "Please confirm the lab slip create automatically",
      additionalText: (
        <p className="paragraph-font medication-question__additional-text">
          Need to change something?{" "}
          <a
            href="#"
            onClick={() => {
              showPopup(
                BloodSlipFormPopupGenerator({
                  patient: patientQuery.data as Patient,
                  provider: providerQuery.data as Provider,
                  data: bloodSlipData,
                  onSave: (data) => setBloodSlipData(data),
                  isPregnantCapability,
                })
              );
            }}
          >
            Click here
          </a>{" "}
          to customize a lab slip instead.
        </p>
      ),
      buttonText: "YES, CONFIRM",
      options: [
        {
          value: true,
          label: "Yes",
        },
        {
          value: false,
          label: "No",
        },
      ],
    },
  ];

  const filteredQuestions = medicationQuestions.filter(
    (question) => !question.when || question.when()
  );

  useEffect(() => {
    if (
      patientQuery.isSuccess &&
      providerQuery.isSuccess &&
      presets.length > 0
    ) {
      const patient = patientQuery.data;
      const provider = providerQuery.data as Provider;
      const pregnantFlag =
        patientQuery.data?.medicalBackground?.sex === "Female" &&
        isPregnantCapability;
      const templateType = pregnantFlag
        ? TemplatesList.FEMALE_BASELINE_BLOODWORK
        : TemplatesList.MALE_BASELINE_OR_ANYONE_2_MONTH;
      const bloodSlipFormData = getBloodSlipTemplate(
        patient,
        provider,
        isPregnantCapability,
        presets.find((preset) => preset.type === templateType)
      );
      const pregnancyTestFormData = getPregnancyTestTemplate(
        patient,
        provider,
        true,
        presets.find(
          (preset) => preset.type === TemplatesList.FEMALE_FIRST_PREGNANCY
        )
      );

      setBloodSlipData(bloodSlipFormData);
      setPregnancyTestData(pregnancyTestFormData);
    }
  }, [patientQuery, providerQuery, isPregnantCapability, presets]);

  useEffect(() => {
    if (!medicationsQuery.data || !tableData) return;

    const isAccutaneInMedicationsList = !!tableData.find(
      ({ medicineKey }) =>
        medicationsQuery.data &&
        !!medicationsQuery.data.find(
          ({ id, isAccutane }) => id === medicineKey && isAccutane
        )
    );
    setAccutaneActive(isAccutaneInMedicationsList);
  }, [medicationsQuery, tableData]);

  const [setFlag] = useSetFlagMutation();

  const submit = async () => {
    if (!date) return;
    if (!tableData?.length) return;
    if (!tableData.every((item) => item.medicineKey)) return;
    if (submitInProgress) return;
    if (!activePatientId) return;
    if (!providerId) return;
    if (!followUpsQuery.data) return;
    setSubmitProgressStatus(true);

    const data: FollowUpTreatmentPlan = {
      message: messageText,
      nextFollowUpDate: date,
      medications: tableData,
      timestamp: Date.now(),
      submittedBy: providerId,
      preset: treatmentPlanPreset || "",
    };

    try {
      const isEdit = !!followUpsQuery.data[0].treatmentPlan;
      const result: any = await submitTreatmentPlan({
        followUpId: followUpsQuery.data[0].followUpId,
        payload: data,
        isEdit,
      });

      if (!isEdit) {
        sendShow({
          experiment: { variant: "show" },
          userId: patientQuery.data?.accountId,
        });
      }

      filteredQuestions.forEach(
        async ({ setOptionFlag, optionFlag, flagKey }) => {
          if (flagKey) {
            const response = await setFlag({
              patientId: activePatientId,
              flag: flagKey,
              state: !optionFlag,
            });
          }
        }
      );

      if (result.error) {
        showError({
          title: "Something went wrong...",
          description: result.error.message,
        });
        throw new Error("Unable to submit treatment plan");
      }

      showSuccess({
        title: "Success!",
        description: "Treatment plan has been successfully submitted",
      });
      if (
        bloodSlipAutocreate &&
        !!filteredQuestions
          .find(({ value }) => value === "autogenerateLabSlip")
          ?.when?.()
      ) {
        if (
          patientQuery.data?.medicalBackground?.sex === "Female" &&
          isPregnantCapability
        ) {
          const pdfMetadata = JSON.stringify({
            patientId: activePatientId,
            documentName: `Pregnancy Test Lab Slip for ${
              patientQuery.data?.fullName
            } ${getToday()}`,
            documentId: ENV_CONFIG.BLOOD_SLIP_TEMPLATE_ID, // TODO: <--- I don't think this field is necessary, it isn't used in populate-blood-slip
            fields: parseFieldsToPdf(pregnancyTestData ?? {}),
            isDefaultData: true, // TODO: <-- I don't think this field is necessary, it isn't used in populate-blood-slip
          });

          await createLabOrder({
            patientId: activePatientId,
            employeeId: providerId,
            pdfMetadata,
            featureToggle: enabled,
            tests: {
              pregnancy: pregnancyTestData?.[
                "bloodSlipDetails.levelsToBeMeasured"
              ]?.map((test: any) => test.value),
            },
          });
          if (!enabled) {
            await submitLabSlip({
              data: pregnancyTestData,
              name: "Pregnancy Test Lab Slip",
              patient: patientQuery.data as Patient,
              populatePdf,
              showWarn,
              showSuccess,
            });
          }
        }

        const pdfMetadata = JSON.stringify({
          patientId: activePatientId,
          documentName: `Lab Slip for ${
            patientQuery.data?.fullName
          } ${getToday()}`,
          documentId: ENV_CONFIG.BLOOD_SLIP_TEMPLATE_ID, // TODO: <-- I don't think this field is necessary, it isn't used in populate-blood-slip
          fields: parseFieldsToPdf(bloodSlipData ?? {}),
          isDefaultData: true, // TODO: <-- I don't think this field is necessary, it isn't used in populate-blood-slip
        });
        await createLabOrder({
          patientId: activePatientId,
          employeeId: providerId,
          pdfMetadata,
          featureToggle: enabled,
          tests: {
            bloodwork: bloodSlipData?.[
              "bloodSlipDetails.levelsToBeMeasured"
            ]?.map((test: any) => test.value),
          },
        });
        if (!enabled) {
          await submitLabSlip({
            data: bloodSlipData,
            name: "Lab Slip",
            patient: patientQuery.data as Patient,
            populatePdf,
            showWarn,
            showSuccess,
          });
        }
      }
      history.push(`/${activePatientId}`);
    } catch (error) {
      setSubmitProgressStatus(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (
        loadingFollowUps ||
        (followUpsQuery.isSuccess &&
          followUpsQuery.data &&
          followUpsQuery.data.length > 0)
      ) {
        setLoadingFollowUps(false);

        if (loaded) {
          // showWarn({
          //   title: "Treatment plan was changed",
          //   description:
          //     "Someone has submitted changes to the treatment plan. Please, recheck the updated treatment plan",
          // });
          return;
        }
        const treatmentPlanData = getFilledTreatmentPlanData(
          followUpsQuery?.data
        );
        if (treatmentPlanData) {
          setDate(treatmentPlanData.nextFollowUpDate);
          setMessageText(treatmentPlanData.message || "");
          setTreatmentPlanPreset(treatmentPlanData.preset);
          setTableData(treatmentPlanData.medications);
        }

        setLoaded(true);
      }

      setLoadingFollowUps(true);

      await followUpsQuery.refetch();
    };

    if (!activePatientId) {
      return;
    }

    fetchData();
  }, [activePatientId, followUpsQuery, loaded, loadingFollowUps]);
  function getFirstName(value) {
    if (value && value.data && value.data.fullName) {
      return value.data.fullName.split(" ")[0];
    }
    return null; // Return null if any of the properties are missing
  }
  function generateProviderString(providerData) {
    if (!providerData.data) return "";

    const { firstName, lastName, title } = providerData.data;
    return `${firstName || ""} ${lastName || ""}, ${title || ""}`;
  }
  const treatmentPlanHash = [
    {
      id: 1,
      name: "Accutane message to someone who can get pregnant",
      value: `Hello ${getFirstName(
        patientQuery
      )}!  It was really nice to meet you today on the zoom. I’m excited to take this journey with you to clear your skin. Our Accutane team will be in touch with you once you sign up for Honeydew. On Accutane make sure you are moisturizing your lips with a good lip moisturizer like Aquaphor and use our Honeydew DewRx facial moisturizer religiously. You can take Advil or Motrin as needed if you feel any soreness while working out or running. Always wear Honeydew’s DewRx SPF 50 sunscreen - Accutane can make you “sun-sensitive”. Remember to be responsible about alcohol - no major drinking on the medicine.

It will take a few weeks to start helping (10-15% of patients will flare in the first few weeks and then start to clear). Always take the pills on a full stomach (it gets absorbed better and is more effective). You should never share your pills with anyone else, and do not donate blood while on Accutane. You will also need to confirm two forms of birth control or abstinence while on Accutane.

Submit new photos in one month at the time of starting Accutane. Our team will help you enroll in iPledge, coordinate the blood work and hold your hand throughout the process. Good luck and go for it, and I will see you in a month with the new pictures. ${generateProviderString(
        providerQuery
      )}`,
    },

    {
      id: 2,
      name: "Accutane message to someone who cannot get pregnant",
      value: `Hello ${getFirstName(
        patientQuery
      )}! It was really nice to meet you today on the zoom. I’m excited to take this journey with you to clear your skin. Our Accutane team will be in touch with you once you sign up for Honeydew. On Accutane make sure you are moisturizing your lips with a good lip moisturizer like Aquaphor and use our Honeydew DewRx facial moisturizer religiously. You can take Advil or Motrin as needed if you feel any soreness while working out or running. Always wear Honeydew’s DewRx SPF 50 sunscreen - Accutane can make you “sun-sensitive”. Remember to be responsible about alcohol - no major drinking on the medicine.

It will take a few weeks to start helping (10-15% of patients will flare in the first few weeks and then start to clear). Always take the pills on a full stomach ( it gets absorbed better and is more effective ). You should never share your pills with anyone else, and do not donate blood while on Accutane.

Our team will help you enroll in iPledge, coordinate the blood work and hold your hand throughout the process. Good luck and go for it, and I will see you in a month with the new pictures. ${generateProviderString(
        providerQuery
      )}`,
    },

    {
      id: 3,
      name: "Accutane First Follow-up after their 30 day wait",
      value: `Hello ${getFirstName(
        patientQuery
      )}! Excited to get you started! Don’t forget to take Accutane with a large meal, wear your sunscreen and moisturize! ${generateProviderString(
        providerQuery
      )}`,
    },

    {
      id: 4,
      name: "Accutane Follow-up",
      value: `Hello ${getFirstName(
        patientQuery
      )}! Your skin is starting to improve. Keep it going! 
As always, remember to take Accutane with a large meal, wear your sunscreen, and moisturize. Reach out through the chat if you have any questions. ${generateProviderString(
        providerQuery
      )}`,
    },

    {
      id: 5,
      name: "One Month before finishing Accutane",
      value: `Hello ${getFirstName(
        patientQuery
      )}! Your skin looks great. This should be your last month on Accutane as long as you do not breakout this month. I can start you on maintenance topical care for your next visit to help prevent breakouts and this will also help act as an anti-aging regimen. 

As always, remember to take Accutane with a large meal, wear sunscreen and moisturize. Reach out through the chat if you have any questions. ${generateProviderString(
        providerQuery
      )}`,
    },

    {
      id: 6,
      name: "Stopping Accutane Message",
      value: `Hello ${getFirstName(
        patientQuery
      )}! Your skin looks great and has been great for a few months. It is fine to stop taking Accutane. You can finish whatever pills you have left. Repeat bloods in 4 weeks and you can send final pictures at that time as well. Congrats! If you have any questions or want to work on marks or scarring please let me know on the next follow-up pictures in 4 weeks. Remember – Accutane will remain in your system for 30 days so continue to avoid pregnancy and alcohol intake. ${generateProviderString(
        providerQuery
      )}`,
    },
    {
      id: 7,
      name: "Initial Visit Message to Patient",
      value: `Hello ${getFirstName(
        patientQuery
      )}! Really nice meeting you tonight. Follow the treatment plan and you will start seeing improvement in the next few weeks. Don't forget to use less than a pea-size amount of the creams and rub them in until they disappear into the skin. Do not dot each pimple but apply the medication to areas that are prone to breakout. Always apply the moisturizer on top of the creams after they dry. Reach out to our clinical care coordinators on our 24/7 chat with any questions or concerns. Upload new photos in four weeks and I will assess your progress at that point. Good luck and go for it! ${generateProviderString(
        providerQuery
      )}`,
    },
    {
      id: 8,
      name: "Follow-up Message to Patient",
      value: `Hello ${getFirstName(
        patientQuery
      )}! Your skin is looking much better. Keep it going! We will refill the any medication you are running low on, just let your care coordinator know in the chat what you need. Upload new photos in 4 weeks. ${generateProviderString(
        providerQuery
      )}`,
    },
  ];

  function mapTreatmentPlansToSelect(treatmentPlans: any) {
    return treatmentPlans.map(({ id, name, value }) => ({
      value: id.toString(),
      label: name,
      message: value,
    }));
  }
  const resetPatientMessage = () => {
    if (initialTreatmentPlanData) {
      setMessageText(initialTreatmentPlanData?.message || "");
    } else {
      setMessageText("");
    }
    setIsNewMessageSelected(false);
  };

  useEffect(() => {
    if (
      !date ||
      !tableData?.length ||
      (tableData && !tableData.every((item) => item.medicineKey)) ||
      submitInProgress ||
      !activePatientId ||
      !providerId ||
      !followUpsQuery.data ||
      !messageText
    ) {
      setIsChangeDetected(false);
    } else {
      setIsChangeDetected(true);
    }
  }, [
    date,
    tableData,
    submitInProgress,
    activePatientId,
    providerId,
    followUpsQuery.data,
    messageText,
  ]);

  return (
    <>
      <div
        className=""
        style={{
          width: "100%",
          height: "60px",
          backgroundColor: "#fff",
          display: "flex",
          alignItems: "center",
          paddingLeft: "20px",
          margin: "10px 0px",
        }}
      >
        <p
          style={{
            fontSize: "24px",
            fontWeight: "500",
            fontFamily: "Cera Pro",
          }}
        >
          {view === VIEWS.TREATMENT_PLAN ? "Submit visit" : "Visit submission"}
        </p>
      </div>
      <div className="follow-up-visit">
        <div
          className={`follow-up-visit__blocker-overlay ${
            submitInProgress ? "follow-up-visit__blocker-overlay--active" : ""
          }`}
        />
        {/* <div className="follow-up-visit__submit-area">
          <Button
            size="big"
            text="submit visit"
            onClick={submit}
            disabled={!isChangeDetected}
          />
          <Button
            size="big"
            text="Save Updates"
            className="follow-up-visit__update-button"
            // onClick={submit}
            // disabled={!isChangeDetected}
          />
        </div> */}

        <div className="follow-up-visit__picker">
          <div
            className={`follow-up-visit__picker-item follow-up-visit__mobile-picker-item  ${
              view === VIEWS.TREATMENT_PLAN ? "selected" : ""
            }`}
            onClick={() => setView(VIEWS.TREATMENT_PLAN)}
          >
            Treatment plan
          </div>

          <div
            className={`follow-up-visit__picker-item follow-up-visit__mobile-picker-item ${
              view === VIEWS.MED_BACKGROUND ? "selected" : ""
            }`}
            onClick={() => setView(VIEWS.MED_BACKGROUND)}
          >
            Medical background
          </div>
          <div
            className={`follow-up-visit__picker-item follow-up-visit__mobile-picker-item ${
              view === VIEWS.DOCUMENTS ? "selected" : ""
            }`}
            onClick={() => setView(VIEWS.DOCUMENTS)}
          >
            Documents
          </div>
          {/* <div
            className={`follow-up-visit__picker-item follow-up-visit__mobile-picker-item`}
            onClick={() => setView(VIEWS.TREATMENT_PLAN)}
          >
            Diagnosis codes
          </div> */}
        </div>
        <div
          className={`follow-up-visit__med-background ${
            view === VIEWS.MED_BACKGROUND ? "" : "hide"
          }`}
        >
          <div className="follow-up-visit__med-background__info">
            <FollowUpCard />
            <IpledgeDetails patientId={activePatientId || undefined} />
          </div>
          <div style={{ width: "100%" }}>
            <div>
              <span>Treatment Plan</span>
            </div>
            <TreatmentHistory />
            <VisitsHistory disableFollowUpVisitButton />
          </div>
          {/* <ShortMedicalBackground /> */}
        </div>
        <div
          className={`follow-up-visit__med-background ${
            view === VIEWS.DOCUMENTS ? "" : "hide"
          }`}
        >
          <AdministratorDocuments
            userId={activePatientId}
            employeeId={providerId}
            viewOnly
          />
        </div>
        <div
          className={`follow-up-visit__follow-up ${
            view === VIEWS.TREATMENT_PLAN ? "" : "hide"
          }`}
        >
          <div className="follow-up-visit__table-container">
            {loaded ? (
              <TreatmentPlanTable
                date={date}
                setDate={setDate}
                tableData={tableData}
                treatmentPlanPreset={treatmentPlanPreset}
                onChange={(data) => {
                  setTableData(data);
                }}
                onPresetChange={(data) => {
                  setTreatmentPlanPreset(data);
                }}
              />
            ) : (
              <Skeleton fullWidth style={{ height: 300 }} />
            )}
          </div>
          <div className="follow-up-visit" style={{ paddingTop: "0px" }}>
            <div>
              {(!!accutaneQuery?.data || accutaneActive) &&
                filteredQuestions.length > 0 && (
                  <div style={{ marginBottom: "15px" }}>
                    <p style={{ marginBottom: "10px", fontWeight: "bold" }}>
                      Medication-related questions
                    </p>
                    <div>
                      {filteredQuestions.map((question) => (
                        <div
                          key={question.value}
                          className="medication-question"
                        >
                          <p>{question.label}</p>
                          {question.additionalText || null}
                          <div className="radio-options">
                            {question.options.map((option) => (
                              <label
                                key={option.value}
                                style={{
                                  marginRight: "20px",
                                  marginBottom: "10px",
                                  display: "block",
                                }}
                              >
                                <input
                                  type="radio"
                                  className="follow-up-visit__radio-button"
                                  name={question.value + option.value}
                                  value={option.value}
                                  checked={question.optionFlag === option.value}
                                  onChange={() =>
                                    showPopup(
                                      question.popupModal(
                                        option.value,
                                        question.setOptionFlag,
                                        question.popupModalText,
                                        question?.buttonText
                                      )
                                    )
                                  }
                                  style={{
                                    marginRight: "10px",
                                    accentColor: "#60B257",
                                  }}
                                />
                                {option.label}
                              </label>
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
            </div>

            <div
              className="follow-up-visit__textarea-container"
              style={{ marginBottom: "15px" }}
            >
              <div
                className="follow-up-visit__container"
                style={{ marginBottom: "15px" }}
              >
                <div className="follow-up-visit__heading-text">
                  Message to patient
                </div>

                <Select
                  className="follow-up-visit__select"
                  id="select-treatment-plan"
                  name="treatment-plan"
                  options={mapTreatmentPlansToSelect(treatmentPlanHash)}
                  onChange={(e) => {
                    setIsNewMessageSelected(true);
                    setMessageText(e?.message || "");
                  }}
                  placeholder="Leave a note or description"
                />
                <div
                  style={{
                    visibility: isNewMessageSelected ? "visible" : "hidden",
                  }}
                >
                  <Button
                    onClick={resetPatientMessage}
                    text="Reset"
                    view="secondary"
                    tooltip={
                      initialTreatmentPlanData.message
                        ? "Click this button to reset the selected patient message to the previously filled patient message."
                        : "Click here to clear the text field."
                    }
                  />
                </div>
              </div>
              {loaded ? (
                <textarea
                  className="follow-up-visit__textarea"
                  defaultValue={messageText}
                  value={messageText}
                  placeholder="Leave a note or description here..."
                  rows={5}
                  onChange={(event) => {
                    setMessageText(event.target.value);
                  }}
                />
              ) : (
                <Skeleton fullWidth style={{ height: 300 }} />
              )}
            </div>
            <div
              className="follow-up-visit__submit-area"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <Button
                size="big"
                text="submit visit"
                onClick={submit}
                disabled={
                  !isChangeDetected ||
                  presets.length === 0 ||
                  catalog.length === 0
                }
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
