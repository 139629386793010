import React, { useContext, useState } from "react";
import { Document, Page } from "react-pdf/dist/esm/entry.webpack";
import { useSelector } from "react-redux";
import { Box, Grid } from "@mui/material";
import { OverviewPopupTemplate } from "../../../Components/Common/OverviewPopup";
import { Button } from "../../Common/Button";
import { Button as MuiButton } from "../../Common/Material/Button";
import { PopupLayerContext } from "../../Common/PopupLayer";
import "./style.scss";
import { ProviderTask } from "../../../../types/Provider";
import { getMomentDate } from "../../../../utils/get-date-pretty";
import { StyledSelectCreatable } from "../../Common/StyledSelect";
import { AssignTaskModalPopupGenerator } from "../AssignTaskModalPopup";
import { downloadDocument } from "../../../../utils/download-document";
import { useGetPatientByIdQuery } from "../../../../features/api/patients";
import { LoadingBlock } from "../../Common/LoadingBlock";
import {
  useGetAllEmployeesQuery,
  useGetEmployeeQuery,
} from "../../../../features/api/employees";
import {
  useAddDocumentCommentMutation,
  useGetTasksQuery,
  useUpdateTaskStatusMutation,
} from "../../../../features/api/accutane";
import { Employee } from "../../../../types/Employee";
import { USER_ROLES } from "../../../../types/Main";
import { NotificationLayerContext } from "../../Common/NotificationLayer";
import { getSessionState } from "../../../../features/session";
import { Checkbox } from "../../Common/Checkbox";

interface Props {
  onClose: () => void;
}

interface TitleExtraContent {
  documentTitle: string;
  onAssign: () => void;
  documentUrl: string;
  disableFeatures?: boolean;
  isEsignature?: boolean;
}

export interface Task {
  label: string;
  value: string;
}

export interface DocumentsForm {
  currentTask: string;
  comment: Task | string;
}

interface DocumentOverviewProps {
  userId: string;
  onClose: () => void;
  url: string;
  documentId: string;
  employeeId: string;
  disableFeatures?: boolean;
  isEsignature: boolean;
}

function getDocumentExtencionByUrl(url: string) {
  return url.split(".").pop();
}

const taskSelectGroup = (data: any) => (
  <div>
    <span>{data.label.toUpperCase()}</span>
  </div>
);

const documentSelectOptions = [
  {
    label: "all tasks",
    options: [
      {
        label: "Reviewed blood work. All within normal limits",
        value: "bloodWorkResultsNormal",
      },
      {
        label: "Confirmed negative at-home urine pregnancy test",
        value: "confirmedNegativeUrinePregnancyTestAtHome",
      },
      {
        label: "Confirmed negative pregnancy test (urine HCG)",
        value: "reviewedNegativeUrineHCGPregnancyTest",
      },
      {
        label: "Confirmed negative pregnancy test (blood HCG)",
        value: "reviewedNegativeBloodHCGPregnancyTest",
      },
      {
        label:
          "Reviewed blood work. All within normal limits. Confirmed negative pregnancy test",
        value: "reviewedBloodWork&PregnancyTest",
      },
    ],
  },
];

const governmentIdDocumentSelectOptions = [
  {
    label: "Government Issued Id",
    options: [
      {
        label: "Reviewed ID and verified that name and DOB matches",
        value: "reviewedGovernmentIssuedId",
      },
    ],
  },
];

const minorConsentFormOptions = [
  {
    label: "Document",
    options: [
      {
        label: "Document was signed correctly",
        value: "minorConsentFormCorrectlySigned",
      },
      {
        label: "Document was not signed correctly",
        value: "minorConsentFormNotCorrectlySigned",
      },
    ],
  },
];

function getSelectOptions(taskType: string) {
  switch (taskType) {
    case "governmentIssuedId":
      return governmentIdDocumentSelectOptions;
    case "reviewSignedConsentForm":
      return minorConsentFormOptions;
    default:
      return documentSelectOptions;
  }
}

const getAssignedEmployeeFullName = (
  allEmployees: Employee[],
  assignedToId: string
) => {
  const employee = allEmployees.find(
    (_employee) => _employee.id === assignedToId
  );
  if (!employee) return "-";

  return `${employee.firstName} ${employee.lastName}${
    employee.role === USER_ROLES.PROVIDER ? `, ${employee.title}` : ""
  }`;
};

function DocumentTaskForm({
  task,
  employees,
  userId,
  documentId,
  employeeId,
  onClose,
}: {
  task: ProviderTask;
  employees: Employee[];
  userId: string;
  documentId: string;
  employeeId: string;
  onClose: () => void;
}) {
  const { showSuccess, showError } = useContext(NotificationLayerContext);

  const [documentsForm, setFormField] = useState<DocumentsForm>({
    currentTask: task.id,
    comment: "",
  });
  const [selectOptions, setSelectOptions] = useState(documentSelectOptions);
  const [selectOptionLoading, setOptionsLoading] = useState(false);
  const { userRole } = useSelector(getSessionState);
  const getTasksQuery = useGetTasksQuery({
    patientId: userId,
    documentId,
    employeeId,
  });
  const employeeQuery = useGetEmployeeQuery(employeeId);
  const [consentToUseESignature, setConsentToUseESignature] = useState(false);
  const [updateTaskStatus] = useUpdateTaskStatusMutation();
  const [loading, setLoading] = useState(false);
  const [addDocumentComment] = useAddDocumentCommentMutation();
  const handleCommentChange = (comment: { label: string; value: string }) => {
    setFormField({ ...documentsForm, comment });
  };

  const onSubmit = async () => {
    if (!documentsForm.currentTask) return;

    const { data: tasksData } = getTasksQuery;
    const { data: employeeData } = employeeQuery;

    const taskToComplete = tasksData?.find(
      (_task: ProviderTask) => _task.id === documentsForm.currentTask
    );
    const taskToCompleteKey = taskToComplete?.compositeKey;

    if (!taskToCompleteKey) return;

    setLoading(true);

    const updatedBy = {
      id: employeeId || "",
      role: employeeData?.role || "",
    };

    const signedBy =
      employeeData && userRole
        ? {
            id: employeeId,
            name: `${employeeData.firstName} ${employeeData.lastName}${
              employeeData.role === USER_ROLES.PROVIDER
                ? `, ${employeeData.title}`
                : ""
            }`,
            role: userRole,
            createdAt: new Date().toISOString(),
          }
        : null;

    try {
      const [taskResult, documentCommentResult] = await Promise.all([
        updateTaskStatus({
          taskId: documentsForm.currentTask,
          compositeKey: taskToCompleteKey,
          patientId: userId,
          updatedBy,
          status: "COMPLETED",
        }),
        signedBy
          ? addDocumentComment({
              documentId,
              patientId: userId,
              signedBy,
              comment: (documentsForm.comment as Task).label,
            })
          : Promise.resolve(null),
      ]);

      if (taskResult?.error) {
        showError({
          title: "Something went wrong...",
          description: "Unable to complete task",
        });
        return;
      }

      if (documentCommentResult?.error) {
        showError({
          title: "Something went wrong...",
          description: "Unable to add comment to the document",
        });
        return;
      }

      showSuccess({
        title: "Success",
        description: "Task successfully completed",
      });
      setTimeout(() => {
        onClose();
      }, 1000);
    } catch (error) {
      showError({
        title: "Unexpected Error",
        description: "An unexpected error occurred. Please try again later.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="overview-popup__item-wrap">
      <p className="overview-popup__overview-form__item__extra--bold">
        {task.message}
      </p>
      <p className="overview-popup__overview-form__item__extra">
        Assigned to{" "}
        {getAssignedEmployeeFullName(employees, task.assignedTo?.id)}, added on{" "}
        {getMomentDate(task.createdAt).format("MM/DD/YYYY")}
      </p>
      {task.compositeKey.includes(employeeId) ? (
        <div className="overview-popup__overview-form__item__comment-wrapper">
          <StyledSelectCreatable
            id={task.id}
            name={task.id}
            options={getSelectOptions(task.type)}
            value={documentsForm.comment}
            isDisabled={!task.compositeKey.includes(employeeId)}
            onChange={(comment: { label: string; value: string }) => {
              setOptionsLoading(true);
              setTimeout(() => {
                handleCommentChange(comment);
                setOptionsLoading(false);
              }, 0);
            }}
            filterOption={(option: any, searchText: any) => {
              if (!searchText) {
                return true;
              }
              return option.label
                .toLowerCase()
                .includes(searchText.toLowerCase());
            }}
            onCreateOption={(option) => {
              setOptionsLoading(true);
              const newTask = { label: option, value: option };
              setTimeout(() => {
                handleCommentChange(newTask);
                setOptionsLoading(false);
              }, 0);
            }}
            formatGroupLabel={taskSelectGroup}
            placeholder="Enter your comment"
            createOptionPosition="first"
            isSearchable
            disabled={selectOptionLoading}
          />
          <Box mt={2} mb={2}>
            <Checkbox
              onChange={() =>
                setConsentToUseESignature(!consentToUseESignature)
              }
            >
              I confirm that this document has been reviewed by me and consent
              to using an e-signature as confirmation.
            </Checkbox>
          </Box>
          <MuiButton
            text="Submit & Sign"
            className="overview-popup__overview-form__item__comment-wrapper__submit"
            onClick={onSubmit}
            disabled={
              !documentsForm.comment || !consentToUseESignature || loading
            }
            isLoading={loading}
            size="small"
          />
        </div>
      ) : null}
    </div>
  );
}

function DocumentOverviewPopup({
  userId,
  onClose,
  url,
  documentId,
  employeeId,
  disableFeatures = false,
  isEsignature = false,
}: DocumentOverviewProps) {
  const allEmployeesQuery = useGetAllEmployeesQuery(null, {
    skip: disableFeatures,
  });
  const tasksQuery = useGetTasksQuery(
    {
      patientId: userId,
      documentId,
    },
    {
      skip: disableFeatures,
    }
  );
  const [pages, setPage] = useState({
    currentPage: 1,
    totalPages: 0,
  });
  const [selectedTask, setSelectedTask] = useState<string>();

  const getTasksOptions =
    !allEmployeesQuery.data || !tasksQuery.data
      ? null
      : tasksQuery.data
          .filter(
            (task: ProviderTask) =>
              task.status !== "COMPLETED" && task.status !== "DELETED"
          )
          .map((task: ProviderTask) => ({
            label: task.message,
            value: task.id,
            key: task.id,
            disabled: !task.compositeKey.includes(employeeId),
            extra: (
              <DocumentTaskForm
                userId={userId}
                employeeId={employeeId}
                documentId={documentId}
                task={task}
                onClose={onClose}
                employees={allEmployeesQuery.data as Employee[]}
              />
            ),
          }));

  // const handleSelectOption = (option: string) => {
  //   setFormField({ comment: "", currentTask: option });
  // };

  const documentExtencion = getDocumentExtencionByUrl(url);

  // eslint-disable-next-line prefer-spread
  const documentPages = Array.apply(null, Array(pages.totalPages))
    .map((x, i) => i + 1)
    .map((page) => <Page pageNumber={page} />);

  return (
    <div className="overview-popup">
      <div className="overview-popup__document-wrapper">
        {documentExtencion === "pdf" && (
          <Document
            file={url}
            onLoadSuccess={({ numPages }) => {
              setPage({ ...pages, totalPages: numPages });
            }}
            onLoadError={(...err) => {
              console.log("err", err);
            }}
            onSourceError={(...err) => {
              console.log("source err", err);
            }}
          >
            {documentPages}
          </Document>
        )}
        {documentExtencion === "jpeg" && (
          <img
            className="overview-popup__document-wrapper__image"
            src={url}
            alt="document-preview"
          />
        )}
        {isEsignature && (
          <div style={{ width: "100%", height: "100%", position: "relative" }}>
            <iframe
              src={url}
              frameBorder={0}
              style={{
                overflow: "hidden",
                overflowX: "hidden",
                overflowY: "hidden",
                height: "100%",
                width: "100%",
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
              }}
              height="100%"
              width="100%"
            />
          </div>
        )}
      </div>
      <div className="overview-popup__overview-form">
        <div className="overview-popup__overview-form__item">
          <p className="overview-popup__overview-form__item__title">
            Outstanding tasks
          </p>
          {!disableFeatures &&
            getTasksOptions
              ?.filter(({ disabled }) => !disabled)
              .map(({ extra }) => extra)}
          {!disableFeatures &&
            getTasksOptions
              ?.filter(({ disabled }) => disabled)
              .map(({ extra }) => extra)}
        </div>
      </div>
    </div>
  );
}

function TitleExtraContent({
  documentTitle,
  onAssign,
  documentUrl,
  disableFeatures = false,
  isEsignature = false,
}: TitleExtraContent) {
  return (
    <div className="extra-content">
      {!disableFeatures && (
        <Button
          text="Assign a task"
          view="secondary"
          onClick={onAssign}
          size="small"
          className="extra-content__button"
        />
      )}
      {!isEsignature && (
        <Button
          text="Download"
          view="secondary"
          size="small"
          onClick={downloadDocument(documentUrl, documentTitle)}
          className="extra-content__button"
        />
      )}
    </div>
  );
}

function DocumentOverviewPopupGenerator(
  popupTitle: string,
  userId: string,
  documentId: string,
  url: string,
  employeeId: string,
  disableFeatures?: boolean,
  isEsignature?: boolean
) {
  return function render({ onClose }: Props) {
    const { showPopup } = useContext(PopupLayerContext);
    const patientQuery = useGetPatientByIdQuery(userId);

    if (!patientQuery.data) {
      return <LoadingBlock />;
    }

    if (!url) {
      return <div />;
    }

    if (isEsignature) {
      return (
        <OverviewPopupTemplate
          title={popupTitle}
          onClose={onClose}
          extra={
            <TitleExtraContent
              documentTitle={popupTitle}
              onAssign={() => null}
              documentUrl={url}
              disableFeatures={disableFeatures}
              isEsignature
            />
          }
        >
          <DocumentOverviewPopup
            userId={userId}
            onClose={onClose}
            url={url}
            documentId={documentId}
            employeeId={employeeId}
            disableFeatures={disableFeatures}
            isEsignature
          />
        </OverviewPopupTemplate>
      );
    }

    if (disableFeatures) {
      return (
        <OverviewPopupTemplate
          title={popupTitle}
          onClose={onClose}
          extra={
            <TitleExtraContent
              documentTitle={popupTitle}
              onAssign={() => null}
              documentUrl={url}
              disableFeatures={disableFeatures}
            />
          }
        >
          <DocumentOverviewPopup
            userId={userId}
            onClose={onClose}
            url={url}
            documentId={documentId}
            employeeId={employeeId}
            disableFeatures={disableFeatures}
            isEsignature={false}
          />
        </OverviewPopupTemplate>
      );
    }

    const onAssign = () => {
      if (patientQuery.data) {
        showPopup(
          AssignTaskModalPopupGenerator(
            popupTitle,
            patientQuery.data.fullName,
            documentId,
            userId,
            employeeId
          )
        );
      }
    };

    return (
      <OverviewPopupTemplate
        title={popupTitle}
        onClose={onClose}
        extra={
          <TitleExtraContent
            documentTitle={popupTitle}
            onAssign={onAssign}
            documentUrl={url}
          />
        }
      >
        <DocumentOverviewPopup
          userId={userId}
          onClose={onClose}
          url={url}
          documentId={documentId}
          employeeId={employeeId}
          isEsignature={false}
        />
      </OverviewPopupTemplate>
    );
  };
}

export default DocumentOverviewPopupGenerator;
