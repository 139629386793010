export enum PatientTabs {
  TREATMENT_HISTORY = "Treatment history",
  DOCUMENTS = "Documents",
}

export enum PatientAccountType {
  PARENT = "PARENT",
  CHILD = "CHILD",
  ADULT = "ADULT",
}
