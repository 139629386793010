import React from "react";
import { Typography, Divider } from "@mui/material";
import { useBloodWorkStepper } from "../../../../contexts/blood-work";
import { usePregnancyWorkStepper } from "../../../../contexts/pregnancy-work";
import { BloodWorkPopupSteps } from "../BloodWorkPopup/steps";
import { PregnancyWorkPopupSteps } from "../PregnancyTestPopup/steps";
import { Button } from "../../Common/Material/Button";
import "./styles.scss";

export default function LabTips({ type }: { type: "blood" | "pregnancy" }) {
  const bloodWork = useBloodWorkStepper();
  const pregnancyWork = usePregnancyWorkStepper();

  const { handleNext, handleBack } =
    type === "blood" ? bloodWork : pregnancyWork;

  const handleBackToSteps = () => {
    const steps =
      type === "blood" ? BloodWorkPopupSteps : PregnancyWorkPopupSteps;
    handleBack(steps.CHECKOUT);
  };

  const testType = type === "blood" ? "lab" : "pregnancy";

  return (
    <div className="lab-tips">
      <div className="lab-tips__content">
        <Typography variant="h5" gutterBottom>
          Tips For Your {type === "blood" ? "Lab" : "Pregnancy Test"} Visit
        </Typography>

        <div className="lab-tips__section">
          <div className="lab-tips__section-title">
            Before your test - review and follow any special instructions from
            your doctor.
          </div>
          <div className="lab-tips__section-text">
            Some tests may require you to fast for a certain period, refrain
            from taking certain medications and supplements and/or refrain from
            certain activities.
          </div>
        </div>

        <Divider className="lab-tips__divider" />

        <div className="lab-tips__section">
          <div className="lab-tips__section-title">What to bring?</div>
          <ul className="lab-tips__section-list">
            <li>
              • <span className="lab-tips__bold">Photo identification</span>
            </li>
            <li>
              •{" "}
              <span className="lab-tips__bold">
                {testType === "lab" ? "Lab order form" : "Test order form"}
              </span>
              : Sent electronically to the{" "}
              {testType === "lab" ? "lab" : "testing center"}. No need to print
              it
            </li>
          </ul>
        </div>

        <Divider className="lab-tips__divider" />

        <div className="lab-tips__section">
          <div className="lab-tips__section-title">
            At the{" "}
            {testType === "lab"
              ? "lab / patient service center"
              : "testing center"}
          </div>
          <ul className="lab-tips__section-list">
            <li>
              • Do <span className="lab-tips__bold">NOT</span> make any payments
            </li>
            <li>
              • Do <span className="lab-tips__bold">NOT</span> show proof of
              insurance
            </li>
          </ul>
        </div>

        <Divider className="lab-tips__divider" />

        <div className="lab-tips__section">
          <div className="lab-tips__section-title">
            What's the name of the doctor who submitted for the{" "}
            {type === "blood" ? "labwork" : "test"} on my behalf?
          </div>
          <div className="lab-tips__section-text">Leo Damasco, MD.</div>
        </div>

        <Divider className="lab-tips__divider" />

        <div className="lab-tips__section">
          <div className="lab-tips__section-title">Need help?</div>
          <div className="lab-tips__section-text">
            Reach out to your care team through the chat or call us at
            516-210-5600.
          </div>
        </div>
      </div>

      <Divider className="lab-tips__divider" />

      <div className="lab-tips__buttons">
        <Button
          text="Thanks, go to care hub"
          size="small"
          onClick={handleNext}
        />
        <Button
          view="secondary"
          text={`Back to ${type === "blood" ? "lab" : "pregnancy"} test steps`}
          size="small"
          onClick={handleBackToSteps}
        />
      </div>
    </div>
  );
}
