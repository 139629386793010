import React, { useEffect } from "react";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { ENV_CONFIG } from "../../../../../config";
import { sendAppointmentScheduled } from "../../../../../shared/analytics";
import { SurveyComponentProps } from "../../../../../types/CustomSurvey";
import { Paywall } from "./paywall";
import { getCustomerInfo } from "../../../../../services/honeydew-api/payments";

export function CalendlySurveyQuestion({ onDone, data }: SurveyComponentProps) {
  const { patient } = data;
  const widgetUrl = ENV_CONFIG.CALENDLY_APPOINTMENT_WIGDET_URLS[patient.state];

  const [showPaywall, setShowPaywall] = React.useState(false);
  const [hasPaymentMethod, setHasPaymentMethod] = React.useState(false);
  const enabled = useFeatureIsOn("paywall");

  const handleTimeSelected = async () => {
    if (!hasPaymentMethod && enabled) {
      setShowPaywall(true);
    }
  };

  useCalendlyEventListener({
    onEventScheduled: () => {
      localStorage.setItem(
        `appointment-submitted-${patient.patientId}`,
        "true"
      );
      sendAppointmentScheduled(patient.patientId);
      onDone({});
    },
    onDateAndTimeSelected: () => {
      handleTimeSelected();
    },
  });

  useEffect(() => {
    if (data?.patient) {
      getCustomerInfo(data?.patient.patientId).then((response) => {
        setHasPaymentMethod(!!response.paymentMethods.length);
      });
    }
  }, [data]);

  return (
    <div className="survey-question submit-appointment__calendly">
      <p className="survey-question__title">
        Schedule your initial visit below.
      </p>
      <p className="survey-question__descr paragraph-font--color">
        Schedule a consultation with one of our licensed dermatology providers.
      </p>
      <InlineWidget
        // url={widgetOptions.url}
        url={widgetUrl}
        utm={{
          utmCampaign: ENV_CONFIG.ENVIRONMENT,
          utmSource: patient.patientId,
        }}
        prefill={{
          email: patient.email,
          name: patient.fullName,
        }}
      />
      {showPaywall && (
        <Paywall
          patient={data?.patient}
          onSuccess={() => setShowPaywall(false)}
        />
      )}
    </div>
  );
}
