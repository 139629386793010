import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ENV_CONFIG } from "../../config";
import { AuthenticationService } from "../../services/cognito";
import { PatientDashboardItem } from "../../types/Dashboard";
import { Employee } from "../../types/Employee";

const employeeFieldsQuery = `{
  id
  firstName
  lastName
  role
  email
  phone
  image
  npiNumber
  title
  availableStates
  detachedFromNewPatients
  canCoverTemporaryPatients
  archived
  presignedBloodSlipId
  internalPresignedBloodSlipKey
  stateLicenses {
    license
    expiry
    state
    stateAbbreviation
    priority
  }
  isTaggable
  isSmsEnabled
}`;

const timeOffFieldsQuery = `{
  id
  compositeKey
  employeeId
  employeeRole
  eventType
  leaveId
  ttl
  createdBy
  lastModifiedBy
}`;

const timeOffHistoryFieldsQuery = `{
    leaveId
    employeeId
    employeeRole
    start
    end
}`;

const miscellaneousTasksQuery = `{
    miscTasks {
      accountId
      dateOfBirth
      careTeam {
        employeeId
        role
      }
      flags
      followUp {
        id
        isInitialFollowUp
        isPrescriptionSubmitted
        isTreatmentPlanSet
        nextFollowUpDate
        timestamp
      }
      subscription {
          accutane
          membership
      }
      fullName
      appointmentStatus
      appointmentId
      dateOfService
      patientId
      phone
      state
    }
    count
    lastKeyEvaluated {
        id
        compositeKey
    }
}`;

const futureTimeOffFieldsQuery = `{
    leaveId
    employeeId
    employeeRole
    start
    end
    createdBy
    lastModifiedBy
}`;

export const employeesApiSlice = createApi({
  reducerPath: "api/employees",
  tagTypes: ["employee"],
  baseQuery: fetchBaseQuery({
    baseUrl: ENV_CONFIG.EMPLOYEES_SERVICE_GRAPHQL_URL,
    prepareHeaders: async (headers) => {
      const token = await AuthenticationService.getAccessToken();
      headers.set("Authorization", token);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getEmployee: builder.query<Employee, string>({
      query: (id: string) => ({
        url: "",
        params: {
          query: `query {
            getEmployee(id: "${id}") ${employeeFieldsQuery}
          }`,
        },
      }),
      transformResponse: (response: any) => response.data.getEmployee,
      providesTags: (response) =>
        response ? [{ type: "employee", id: response.id }] : [],
    }),
    getAllEmployees: builder.query<Employee[], null>({
      query: () => ({
        url: "",
        params: {
          query: `query { getAllEmployees ${employeeFieldsQuery} }`,
        },
      }),
      transformResponse: (response: any) => response.data.getAllEmployees,
      providesTags: (response) =>
        response ? response.map(({ id }) => ({ type: "employee", id })) : [],
    }),
    getAllEmployeesOnTimeOff: builder.query<Employee[], null>({
      query: () => ({
        url: "",
        params: {
          query: `query { getAllEmployeesOnTimeOff ${timeOffHistoryFieldsQuery} }`,
        },
      }),
      transformResponse: (response: any) =>
        response.data.getAllEmployeesOnTimeOff,
      providesTags: (response) =>
        response ? response.map(({ id }) => ({ type: "employee", id })) : [],
    }),
    getEmployeesByRole: builder.query<Employee[], string>({
      query: (role: string) => ({
        url: "",
        params: {
          query: `query { getEmployeesByRole(role: "${role}") ${employeeFieldsQuery} }`,
        },
      }),
      transformResponse: (response: any) => response.data.getEmployeesByRole,
      providesTags: (response) =>
        response ? response.map(({ id }) => ({ type: "employee", id })) : [],
    }),
    createEmployee: builder.mutation<{ id: string }, Partial<Employee>>({
      query: (newEmployee) => ({
        url: "",
        method: "POST",
        body: JSON.stringify({
          query:
            "mutation ($payload: EmployeeInput!) { createEmployee(payload: $payload) { id } }",
          variables: JSON.stringify({
            payload: newEmployee,
          }),
        }),
      }),
      transformResponse: (result: any) => {
        if (result.errors) {
          throw new Error("Error occured during employee submission");
        }
        return result.data.createEmployee;
      },
      invalidatesTags: (result) => (result ? [{ type: "employee" }] : []),
    }),

    updateEmployeeCognitoEmail: builder.mutation<{
      userId: string;
      userName: string;
      newEmail: string;
    }>({
      query: ({ userId, userName, newEmail }) => ({
        url: "",
        method: "POST",
        body: JSON.stringify({
          query: `
            mutation ($userId: String!, $userName: String!, $newEmail: String!) {
              updateEmployeeCognitoEmail(userId: $userId,userName: $userName, newEmail: $newEmail) {
                success
                error
              }
            }
          `,
          variables: {
            userId,
            userName,
            newEmail,
          },
        }),
      }),
      invalidatesTags: (result) => [{ type: "cognito" }],
      transformResponse: (response: any) => {
        if (response.errors?.[0]?.message) {
          throw new Error(response.errors?.[0]?.message);
        }
        return response.data.updateEmployeeCognitoEmail;
      },
    }),

    updateEmployee: builder.mutation<
      { id: string },
      { employeeId: string; payload: Partial<Employee> }
    >({
      query: ({ employeeId, payload }) => ({
        url: "",
        method: "POST",
        body: JSON.stringify({
          query:
            "mutation ($employeeId: String!, $payload: UpdateEmployeeInput!) { updateEmployee(employeeId: $employeeId, payload: $payload) { id } }",
          variables: JSON.stringify({
            employeeId,
            payload,
          }),
        }),
      }),
      transformResponse: (result: any) => {
        if (result.errors) {
          throw new Error("Error occured during employee submission");
        }
        return result.data.updateEmployee;
      },
      invalidatesTags: (result) =>
        result ? [{ type: "employee", id: result.id }] : [],
    }),
    updateProviderStatePriority: builder.mutation<
      { success: boolean; message: string },
      { payload: any }
    >({
      query: ({ payload }) => ({
        url: "",
        method: "POST",
        body: JSON.stringify({
          query: `
          mutation updateProviderStatPriority($payload: ProviderStatePriorityInput!) {
            updateProviderStatePriority(payload: $payload) {
              success
              message
            }
          }
        `,
          variables: { payload }, // Correct way to pass the variables
        }),
      }),
      transformResponse: (result: any) => {
        if (result.errors) {
          // Log or throw the GraphQL errors
          console.error("GraphQL errors:", result.errors);
          throw new Error(
            result.errors.map((error: any) => error.message).join(", ")
          );
        }

        if (!result.data || !result.data.updateProviderStatePriority) {
          // If the data is null or undefined, return an appropriate structure
          throw new Error("Mutation response was null or missing.");
        }

        return result.data.updateProviderStatePriority;
      },
      invalidatesTags: (result) =>
        result ? [{ type: "employee", id: result.id }] : [],
    }),
    archiveEmployee: builder.mutation<{ id: string }, { employeeId: string }>({
      query: ({ employeeId }) => ({
        url: "",
        method: "POST",
        body: JSON.stringify({
          query:
            "mutation ($employeeId: String!, $archived: Boolean!) { archiveEmployee(id: $employeeId, archived: $archived) { id } }",
          variables: JSON.stringify({
            employeeId,
            archived: true,
          }),
        }),
      }),
      transformResponse: (result: any) => {
        if (result.errors) {
          throw new Error("Error occured during employee submission");
        }
        return result.data.archiveEmployee;
      },
      invalidatesTags: (result) =>
        result ? [{ type: "employee", id: result.id }] : [],
    }),
    getTimeOffByEmployeeId: builder.query<Employee[], string>({
      query: (employeeId: string) => ({
        url: "",
        params: {
          query: `query { getTimeOffByEmployeeId(employeeId: "${employeeId}") ${timeOffFieldsQuery} }`,
        },
      }),
      // transformResponse: (response: any) => response.data,
      // providesTags: (response) =>
      //   response ? response.data.map(({ id }) => ({ type: "employee", id })) : [],
    }),

    getTimeOffHistoryByEmployeeId: builder.query<Employee[], string>({
      query: (employeeId: string) => ({
        url: "",
        params: {
          query: `query { getTimeOffHistoryByEmployeeId(employeeId: "${employeeId}") ${timeOffHistoryFieldsQuery} }`,
        },
      }),
    }),
    getActiveTimeOffByEmployeeId: builder.query<Employee[], string>({
      query: (employeeId: string) => ({
        url: "",
        params: {
          query: `query { getActiveTimeOffEventByEmployeeId(employeeId: "${employeeId}") ${timeOffHistoryFieldsQuery} }`,
        },
      }),
    }),
    getMiscellaneousTasks: builder.query<
      {
        count: number;
        miscTasks: PatientDashboardItem[];
        lastKeyEvaluated: { id: string; compositeKey: string };
      },
      {
        employeeId: string;
        lastKeyEvaluated?: { id: string; compositeKey: string };
      }
    >({
      query: ({ employeeId, lastKeyEvaluated }) => ({
        url: "",
        params: {
          query: `query { getMiscellaneousTasks(employeeId: "${employeeId}" ${
            lastKeyEvaluated
              ? `, previousLastKeyInTable: { id: "${lastKeyEvaluated.id}", compositeKey: "${lastKeyEvaluated.compositeKey}" }`
              : ""
          }) ${miscellaneousTasksQuery} }`,
        },
      }),
      transformResponse: (result: any) => {
        console.log("result", result);
        if (result.errors) {
          throw new Error("Error occured during employee submission");
        }
        return {
          ...result.data.getMiscellaneousTasks,
          miscTasks: result.data.getMiscellaneousTasks.miscTasks.map(
            (task) => ({ ...task, flags: JSON.parse(task.flags) })
          ),
        };
      },
    }),
    getFutureTimeOffByEmployeeId: builder.query<Employee[], string>({
      query: (employeeId: string) => ({
        url: "",
        params: {
          query: `query { getFutureTimeOffByEmployeeId(employeeId: "${employeeId}") ${futureTimeOffFieldsQuery} }`,
        },
      }),
    }),
    createEmployeeTimeOffEvent: builder.mutation<
      { id: string },
      Partial<Employee>
    >({
      query: (newTimeOff) => ({
        url: "",
        method: "POST",
        body: JSON.stringify({
          query:
            "mutation ($payload: EmployeeTimeOffEventInput!) { createEmployeeTimeOffEvent(payload: $payload) { id } }",
          variables: JSON.stringify({
            payload: newTimeOff,
          }),
        }),
      }),
      transformResponse: (result: any) => {
        if (result.errors) {
          throw new Error(
            "Error occured during employee timeoff event submission"
          );
        }
        return result.data.createEmployeeTimeOffEvent;
      },
      invalidatesTags: (result) => (result ? [{ type: "employee" }] : []),
    }),
    updateEmployeeTimeOffEvent: builder.mutation<
      { leaveId: string },
      { leaveId: string; payload: Partial<any> }
    >({
      query: ({ leaveId, payload }) => {
        // Remove undefined values from the payload
        const cleanedPayload = Object.fromEntries(
          Object.entries(payload).filter(([_, v]) => v !== undefined)
        );

        return {
          url: "",
          method: "POST",
          body: JSON.stringify({
            query:
              "mutation ($leaveId: String!, $payload: EmployeeTimeOffEventEditPayload!) { updateEmployeeTimeOffEvent(leaveId: $leaveId, payload: $payload) { leaveId } }",
            variables: {
              leaveId,
              payload: cleanedPayload,
            },
          }),
        };
      },
      transformResponse: (result: any) => {
        if (result.errors) {
          throw new Error(
            "Errors occurred during employee time-off event update"
          );
        }
        return result.data.updateEmployeeTimeOffEvent;
      },
      invalidatesTags: (result) =>
        result ? [{ type: "employee", id: result.leaveId }] : [],
    }),
    deleteEmployeeTimeOffEvent: builder.mutation<
      { id: string },
      { leaveId: string }
    >({
      query: ({ leaveId }) => ({
        url: "",
        method: "POST",
        body: JSON.stringify({
          query:
            "mutation ($leaveId: String!) { deleteEmployeeTimeOffEvent(leaveId: $leaveId) { id } }",
          variables: {
            leaveId,
          },
        }),
      }),
      transformResponse: (result: any) => {
        if (result.errors) {
          throw new Error(
            "Errors occurred during employee time-off event deletion"
          );
        }
        return result.data.deleteEmployeeTimeOffEvent;
      },
      invalidatesTags: (result) =>
        result ? [{ type: "employee", id: result.id }] : [],
    }),
    replaceCareTeamMemberIfOnLeave: builder.mutation<
      string,
      { employeeId: string; patientId: string }
    >({
      query: ({ employeeId, patientId }) => ({
        url: "",
        method: "POST",
        body: JSON.stringify({
          query:
            "mutation ($employeeId: String!, $patientId: String!) { replaceCareTeamMemberIfOnLeave(employeeId: $employeeId, patientId: $patientId) { id } }",
          variables: JSON.stringify({
            employeeId,
            patientId,
          }),
        }),
      }),
      transformResponse: (result: any) => {
        if (result.errors) {
          throw new Error("Error occured during employee submission");
        }
        return result.data.replaceCareTeamMemberIfOnLeave;
      },
      invalidatesTags: (result) =>
        result ? [{ type: "employee", id: result.id }] : [],
    }),
  }),
});

export const {
  useGetEmployeeQuery,
  useGetEmployeesByRoleQuery,
  useGetAllEmployeesQuery,
  useGetAllEmployeesOnTimeOffQuery,
  useCreateEmployeeMutation,
  useUpdateEmployeeMutation,
  useUpdateProviderStatePriorityMutation,
  useArchiveEmployeeMutation,
  useGetTimeOffByEmployeeIdQuery,
  useGetTimeOffHistoryByEmployeeIdQuery,
  useGetActiveTimeOffByEmployeeIdQuery,
  useGetMiscellaneousTasksQuery,
  useCreateEmployeeTimeOffEventMutation,
  useUpdateEmployeeTimeOffEventMutation,
  useDeleteEmployeeTimeOffEventMutation,
  useGetFutureTimeOffByEmployeeIdQuery,
  useReplaceCareTeamMemberIfOnLeaveMutation,
  useUpdateEmployeeCognitoEmailMutation,
} = employeesApiSlice;
