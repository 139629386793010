import React, { useEffect, useState } from "react";
import "./style.scss";
import { Grid } from "@mui/material";
import { ReactComponent as CheckSign } from "../../../../Assets/NewIcons/checkmark-filled.svg";
import { ReactComponent as CrossSign } from "../../../../Assets/NewIcons/close-icon-filled.svg";
import { ReactComponent as PharmacySign } from "../../../../Assets/NewIcons/special-pharmacy.svg";
import { ReactComponent as AddSign } from "../../../../Assets/NewIcons/add-new.svg";
import { ReactComponent as SelectedPharmacySign } from "../../../../Assets/NewIcons/selected_pharmacy_icon.svg";
import { ReactComponent as SelectedAddSign } from "../../../../Assets/NewIcons/add-new-selected.svg";
import { Button } from "../../Common/Button";
import { InfoBlock } from "../../Common/InfoBlock";

function PharmacySelectPopup({
  onNext,
  onBack,
  billingOption,
  setPharmacyOption,
  hasAccutane,
}: any) {
  const [selectedOption, setSelectedOption] = useState("special-pharmacy");
  const [isInsurance, setIsInsurance] = useState(billingOption); // isInsurance + Accutane Flow

  const [pickUpPrice, setPickUpPrice] = useState("$$$$");
  const [infoText, setInfoText] = useState(
    "A care coordinator will reach out through your Care Team Chat to confirm your pharmacy order shortly."
  );

  useEffect(() => {
    if (hasAccutane) {
      setPickUpPrice("~$350");
      if (isInsurance === "Cash") {
        setInfoText(
          "A care coordinator will reach out through your Care Team Chat to discuss next steps before your prescriptions are sent."
        );
      }
    }
  }, [hasAccutane, isInsurance]);

  useEffect(() => {
    switch (selectedOption) {
      case "special-pharmacy":
        setPharmacyOption("SPECIAL");
        break;
      case "other-special-pharmacy-one":
        setPharmacyOption("SPECIAL");
        break;
      case "other-special-pharmacy-two":
        setPharmacyOption("LOCAL");
        break;
      case "local-pharmacy":
        setPharmacyOption("LOCAL");
        break;
      default:
        setPharmacyOption("SPECIAL");
    }
  }, [selectedOption]);

  useEffect(() => {
    if (billingOption === "Insurance")
      setSelectedOption("other-special-pharmacy-one");
  }, []);

  return (
    <div className="pharmacy-selection-form">
      <div className="pharmacy-selection-form__container">
        <div className="pharmacy-selection-form__headings">
          <h2 className="pharmacy-selection-form__question-heading">
            Choose your preferred pharmacy
          </h2>
          {hasAccutane === true ? (
            <h4 className="pharmacy-selection-form__sub-heading">
              We will send your prescription(s) to your preferred pharmacy once
              all necessary steps have been completed.
            </h4>
          ) : (
            <h4 className="pharmacy-selection-form__sub-heading">
              We will send your prescription(s) to the pharmacy for processing.
            </h4>
          )}
        </div>
        {isInsurance === "Insurance" ? (
          <div className="pharmacy-selection-form__pharmacy-options-container-two">
            <div
              className={`pharmacy-selection-form__other-special-pharmacy-one ${
                selectedOption === "other-special-pharmacy-one"
                  ? "selected"
                  : ""
              }`}
              onClick={() => setSelectedOption("other-special-pharmacy-one")}
            >
              <div className="pharmacy-selection-form__option-point">
                <div className="pharmacy-icon">
                  {selectedOption === "other-special-pharmacy-one" ? (
                    <SelectedPharmacySign />
                  ) : (
                    <PharmacySign />
                  )}
                </div>
                <div
                  className={`pharmacy-selection-form__text ${
                    selectedOption === "other-special-pharmacy-one"
                      ? "selected"
                      : ""
                  }`}
                >
                  Partner pharmacy
                </div>
              </div>
              <div className="pharmacy-selection-form__option-point">
                <div>
                  <CheckSign />
                </div>
                <div className="pharmacy-selection-form__points-points-text">
                  Best pricing ($0 for most copays)
                </div>
              </div>
              <div className="pharmacy-selection-form__option-point">
                <div>
                  <CheckSign />
                </div>
                <div className="pharmacy-selection-form__points-text">
                  Free delivery
                </div>
              </div>
              <div className="pharmacy-selection-form__option-point">
                <div>
                  <CheckSign />
                </div>
                <div className="pharmacy-selection-form__points-text">
                  Always in stock
                </div>
              </div>
              <div className="pharmacy-selection-form__option-point">
                <div>
                  <CrossSign />
                </div>
                <div className="pharmacy-selection-form__points-text">
                  Wait 2-3 business days for delivery
                </div>
              </div>
            </div>
            <div
              className={`pharmacy-selection-form__other-special-pharmacy-two ${
                selectedOption === "other-special-pharmacy-two"
                  ? "selected"
                  : ""
              }`}
              onClick={() => setSelectedOption("other-special-pharmacy-two")}
            >
              <div className="pharmacy-selection-form__option-point">
                <div className="pharmacy-icon">
                  {selectedOption === "other-special-pharmacy-two" ? (
                    <SelectedAddSign />
                  ) : (
                    <AddSign />
                  )}
                </div>
                <div
                  className={`pharmacy-selection-form__text ${
                    selectedOption === "other-special-pharmacy-two"
                      ? "selected"
                      : ""
                  }`}
                >
                  Choose a local pharmacy
                </div>
              </div>
              <div className="pharmacy-selection-form__option-point">
                <div>
                  <CheckSign />
                </div>
                <div className="pharmacy-selection-form__points-text">
                  Same-day pickup (usually)
                </div>
              </div>
              <div className="pharmacy-selection-form__option-point">
                <div>
                  <CheckSign />
                </div>
                <div className="pharmacy-selection-form__points-text">
                  Affordable if you have strong insurance coverage
                </div>
              </div>
              <div className="pharmacy-selection-form__option-point">
                <div>
                  <CrossSign />
                </div>
                <div className="pharmacy-selection-form__points-text">
                  More expensive if you have a copay or deductible
                </div>
              </div>
              <div className="pharmacy-selection-form__option-point">
                <div>
                  <CrossSign />
                </div>
                <div className="pharmacy-selection-form__points-text">
                  Can be out-of-stock
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="pharmacy-selection-form__pharmacy-options-container">
            <div
              className={`pharmacy-selection-form__special-pharmacy ${
                selectedOption === "special-pharmacy" ? "selected" : ""
              }`}
              onClick={() => setSelectedOption("special-pharmacy")}
            >
              <div className="pharmacy-selection-form__option-point">
                <div className="pharmacy-icon">
                  {selectedOption === "special-pharmacy" ? (
                    <SelectedPharmacySign />
                  ) : (
                    <PharmacySign />
                  )}
                </div>
                <div
                  className={`pharmacy-selection-form__text ${
                    selectedOption === "special-pharmacy" ? "selected" : ""
                  }`}
                >
                  Specialty pharmacy (Best Price)
                </div>
              </div>
              <div className="pharmacy-selection-form__option-point">
                <div>
                  <CheckSign />
                </div>
                <div className="pharmacy-selection-form__points-text">
                  {hasAccutane ? "Best pricing ($50 per box)" : "Best pricing"}
                </div>
              </div>
              <div className="pharmacy-selection-form__option-point">
                <div>
                  <CheckSign />
                </div>
                <div className="pharmacy-selection-form__points-text">
                  Free delivery
                </div>
              </div>
              <div className="pharmacy-selection-form__option-point">
                <div>
                  <CheckSign />
                </div>
                <div className="pharmacy-selection-form__points-text">
                  Always in stock
                </div>
              </div>
              <div className="pharmacy-selection-form__option-point">
                <div>
                  <CrossSign />
                </div>
                <div className="pharmacy-selection-form__points-text">
                  Wait 2-3 business days for delivery
                </div>
              </div>
            </div>
            <div
              className={`pharmacy-selection-form__custom-pharmacy ${
                selectedOption === "local-pharmacy" ? "selected" : ""
              }`}
              onClick={() => setSelectedOption("local-pharmacy")}
            >
              <div className="pharmacy-selection-form__custom-option">
                <div className="pharmacy-icon">
                  {selectedOption === "local-pharmacy" ? (
                    <SelectedAddSign />
                  ) : (
                    <AddSign />
                  )}
                </div>
                <div
                  className={`pharmacy-selection-form__text ${
                    selectedOption === "local-pharmacy" ? "selected" : ""
                  }`}
                >
                  Or add a local pharmacy{" "}
                  <span className="pharmacy-selection-form__light-text">
                    for same-day pickup (but {pickUpPrice})
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="pharmacy-selection-form__info-block">
          <InfoBlock text={`${infoText}`} view="info" />
        </div>
        <Grid container columnGap={2} mb={2}>
          <Grid item>
            <div className="pharmacy-selection-form__button">
              <Button
                text="BACK"
                view="secondary"
                size="small"
                onClick={onBack}
              />
            </div>
          </Grid>
          <Grid>
            <div className="pharmacy-selection-form__button">
              <Button text="SUBMIT" size="small" onClick={onNext} />
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default PharmacySelectPopup;
