import React, { useContext, useEffect, useState } from "react";
import moment from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import { ActionPopupTemplate } from "../../../Components/Common/ActionPopup";
import { Button } from "../../Common/Button";
import "./style.scss";
import { Patient } from "../../../../types/Entities/Patient";
import { PopupLayerContext } from "../../Common/PopupLayer";
import { IncompleteFormPopupGenerator } from "../../Common/IncompleteFormPopup";
import { OrderedSignaturesPopupGenerator } from "../../Common/OrderedSignaturesPopup";
import { Accutane } from "../../../../types/Entities/Accutane";
import { getMomentDate } from "../../../../utils/get-date-pretty";
import { Loader } from "../../Common/Loader";
import {
  accutaneApiSlice,
  useGenerateDocumentMutation,
  useGenerateIpledgeLinksMutation,
  useGetAccutaneByPatientIdQuery,
  useUpdateTaskStatusMutation,
} from "../../../../features/api/accutane";
import { NotificationLayerContext } from "../../Common/NotificationLayer";
import { HoneydewAPI } from "../../../../services/honeydew-api";
import { useMobile } from "../../../../hooks/use-mobile";
import { getSessionState } from "../../../../features/session";

interface Props {
  onClose: () => void;
}

enum SignRoles {
  patient = "Patient",
  patientMinor = "PatientMinor",
  parent = "Parent/Guardian",
  provider = "Provider",
}

interface PopupProps extends Props {
  activePatient: Patient;
  role: string;
  accutane: Accutane;
  taskId?: string;
  compositeKey?: string;
  onCompleteSign?: any;
  setSignStep: (step: number) => void;
  setDocumentGroupId: (documentGroupId: string) => void;
  setParentFormSigned: any;
  setContainsParentForm: any;
}

function getRoleByStep(step: number, role: string, isAdult: boolean) {
  if (role === SignRoles.provider) {
    return SignRoles.provider;
  }
  if (role === SignRoles.patient) {
    if (isAdult) {
      return SignRoles.patient;
    }
    switch (step) {
      case 1: {
        return SignRoles.patientMinor;
      }
      case 2: {
        return SignRoles.parent;
      }
      default:
        return null;
    }
  }

  return null;
}

const dateFormat = "MM/DD/YYYY";

function SignIpledgeConsentPopup({
  onClose,
  activePatient,
  role,
  accutane,
  setDocumentGroupId,
  taskId,
  compositeKey,
  onCompleteSign,
  setSignStep,
  setParentFormSigned,
  setContainsParentForm,
}: PopupProps) {
  const dispatch = useDispatch();
  const { showError } = useContext(NotificationLayerContext);
  const accutaneQuery = useGetAccutaneByPatientIdQuery(activePatient.patientId);
  const [onLoadingText, setLoadingText] = useState("");
  const { userId } = useSelector(getSessionState);
  const [generateDocument] = useGenerateDocumentMutation();
  const [generateIpledgeLinks] = useGenerateIpledgeLinksMutation();
  const [updateTaskStatus] = useUpdateTaskStatusMutation();
  const { showPopup } = useContext(PopupLayerContext);
  const patientAdult = moment().diff(activePatient.dateOfBirth, "years") >= 18;
  const [linksLoading, setLinksLoading] = useState(false);
  const [patientInfoShown, setPatientInfoShown] = useState(false);
  const [parentInfoShown, setParentInfoShown] = useState(false);
  const [links, setLinks] = useState<{
    uniqueLink: string;
    uniqueParentLink: string;
  }>();
  const [currentStep, setStep] = useState<number>(1);
  const currentStepLink =
    currentStep === 1 ? links?.uniqueLink : links?.uniqueParentLink;
  const hasUniqueLink = links?.uniqueLink;
  const { isMobile } = useMobile();

  useEffect(() => {
    setSignStep(currentStep);
  }, [currentStep]);

  useEffect(() => {
    setLinksLoading(true);

    (async () => {
      setLoadingText("Generating documents for you. Please wait.");
      const documentResponse: any = await generateDocument({
        patientId: activePatient.patientId,
        documentGroupName: `iPledge consent for ${
          activePatient.fullName
        } ${getMomentDate(Date.now()).format(dateFormat)}`,
      });

      if (documentResponse.error) {
        showError({
          title: "Something went wrong...",
          description: "Unable to generate document",
        });
        return;
      }

      setLoadingText(
        "Generating unique embedded link for signing. Please wait."
      );
      const linksResponse: any = await generateIpledgeLinks({
        patientId: activePatient.patientId,
        documentGroupId: documentResponse.data.documentGroupId,
        role,
      });

      if (linksResponse.error) {
        showError({
          title: "Something went wrong...",
          description: "Unable to generate embedded link",
        });
        return;
      }

      setDocumentGroupId(documentResponse.data.documentGroupId);
      setLinksLoading(false);
      setLinks({
        uniqueLink: linksResponse.data.uniqueLink.data.link,
        uniqueParentLink: linksResponse.data.uniqueParentLink?.data.link ?? "",
      });
      if (linksResponse.data.uniqueParentLink?.data.link)
        setContainsParentForm(true);
      else setContainsParentForm(false);
    })();
  }, []);

  const onComplete = async () => {
    const whoSign = getRoleByStep(currentStep, role, patientAdult);
    if (!accutaneQuery.data) return;
    const response = await HoneydewAPI.accutane.checkDocumentStatus(
      activePatient.patientId,
      whoSign as string,
      accutaneQuery.data.iPledgeConsent.documentGroupId,
      accutaneQuery.data.iPledgeConsent.inviteId
    );

    if (response.fulfilled) {
      setParentFormSigned(true);
      if (taskId && compositeKey) {
        const updatedBy = { id: userId || "", role: role || "" };

        await updateTaskStatus({
          taskId,
          compositeKey,
          updatedBy,
          patientId: activePatient.patientId,
          status: "COMPLETED",
        });
        if (onCompleteSign) await onCompleteSign();
        onClose();
      } else if (activePatient && activePatient.patientId) {
        dispatch(
          accutaneApiSlice.util.invalidateTags([
            {
              type: "accutane",
              id: activePatient.patientId,
            },
          ])
        );
        onClose();
      }
    } else {
      showPopup(IncompleteFormPopupGenerator());
    }
  };

  const handleNextStep = async () => {
    const whoSign = getRoleByStep(currentStep, role, patientAdult);
    if (
      accutaneQuery.data?.iPledgeConsent?.documentGroupId &&
      accutaneQuery.data?.iPledgeConsent?.inviteId
    ) {
      const response = await HoneydewAPI.accutane.checkDocumentStatus(
        activePatient.patientId,
        whoSign as string,
        accutaneQuery.data.iPledgeConsent.documentGroupId,
        accutaneQuery.data.iPledgeConsent.inviteId
      );

      if (response.fulfilled) {
        setParentFormSigned(false);
        if (!patientAdult) {
          setStep(currentStep + 1);
        } else {
          onComplete();
        }
      } else {
        showPopup(IncompleteFormPopupGenerator());
      }
    }
  };

  const showInfoPopup = () => {
    if (!patientAdult && role !== SignRoles.provider)
      if (currentStep === 1 && !patientInfoShown) {
        showPopup(OrderedSignaturesPopupGenerator(currentStep));
        setPatientInfoShown(true);
      }
    if (currentStep === 2 && !parentInfoShown) {
      showPopup(OrderedSignaturesPopupGenerator(currentStep));
      setParentInfoShown(true);
    }
  };

  if (linksLoading || !hasUniqueLink) {
    return (
      <div className="document-loading-wrapper">
        <p className="document-loading-wrapper__text">{onLoadingText}</p>
        <div className="document-loading-wrapper__loader-wrapper">
          <Loader />
        </div>
      </div>
    );
  }

  return (
    <div
      className="sign-ipledge-consent-popup"
      style={{ paddingBottom: isMobile ? "10vh" : 0 }}
    >
      <div
        style={{
          position: "relative",
          margin: "24px 0 0",
          height: isMobile ? "calc(70vh - 20px)" : "calc(100vh - 20px)",
          overflow: isMobile ? "scroll" : "hidden",
        }}
      >
        <iframe
          src={currentStepLink}
          onLoad={() => showInfoPopup()}
          style={{
            overflow: "scroll",
            height: "100%",
            width: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            border: "none",
          }}
        />
      </div>
      {!patientAdult && links?.uniqueParentLink && currentStep !== 2 ? (
        <Button
          text="Next"
          size="small"
          view="primary"
          className="sign-ipledge-consent-popup__extra-button"
          onClick={handleNextStep}
        />
      ) : (
        <Button
          text="Complete"
          size="small"
          className="sign-ipledge-consent-popup__extra-button"
          onClick={onComplete}
        />
      )}
    </div>
  );
}

export function SignIpledgeConsentPopupGenerator(
  activePatient: Patient,
  role: string,
  accutane: Accutane,
  taskId?: string,
  compositeKey?: string,
  onComplete?: any
) {
  return function render({ onClose }: Props) {
    const [documentGroupId, setDocumentGroupId] = useState("");
    const [step, setStep] = useState<number>();
    const [parentFormAvailable, setParentFormAvailable] = useState(null);
    const [containsParentForm, setContainsParentForm] = useState(null);

    function canModalClose() {
      if (!step) {
        return false;
      }
      if (step === 1 && parentFormAvailable === false) {
        return true;
      }
      if (parentFormAvailable === true && step === 2) {
        return true;
      }
      return true;
    }
    return (
      <ActionPopupTemplate
        title="Sign iPledge consent forms"
        onClose={onClose}
        onCloseModal={{
          onConfirm: async () => {
            if (canModalClose()) onClose();
          },
          modalText:
            "This form is not submitted yet. By closing it now, all progress will be lost and the fields will reset. Are you sure you want to close it?",
          modalHeaderText: "Close iPledge consent",
        }}
      >
        <SignIpledgeConsentPopup
          accutane={accutane}
          activePatient={activePatient}
          onClose={onClose}
          role={role}
          onCompleteSign={onComplete}
          taskId={taskId}
          compositeKey={compositeKey}
          setDocumentGroupId={setDocumentGroupId}
          setSignStep={setStep}
          setParentFormSigned={setParentFormAvailable}
          setContainsParentForm={setContainsParentForm}
        />
      </ActionPopupTemplate>
    );
  };
}

export default SignIpledgeConsentPopupGenerator;
