/* eslint-disable react/destructuring-assignment */
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { isNull } from "util";
import { getSessionState } from "../../../../features/session";
import { AcneAwayAPI } from "../../../../services/acneaway-api";
import {
  PatientsTableActions,
  PatientsTableTab,
} from "../../../../types/Table";
import {
  getLocalDateNonMilitary,
  getMomentDate,
  getGivenTimezoneDate,
} from "../../../../utils/get-date-pretty";
import { getLastName } from "../../../../utils/patient/get-last-name";
import { alphabetical, byDate } from "../../../../utils/sortings";
import {
  DoctorGreetingBanner,
  TaskColors,
} from "../../../NewComponents/Common/DoctorGreetingBanner";
import { PatientsTable } from "../../../NewComponents/Common/PatientsTable";
import "./style.scss";
import { PopupLayerContext } from "../../../NewComponents/Common/PopupLayer";
import { NotificationLayerContext } from "../../../NewComponents/Common/NotificationLayer";
import { LoadingBlock } from "../../../NewComponents/Common/LoadingBlock";
import { ReactComponent as CheckmarkStroke } from "../../../../Assets/NewIcons/checkmark-stroke.svg";
import { ReactComponent as CalendarIcon } from "../../../../Assets/NewIcons/calendar-icon.svg";
import { ReactComponent as CheckmarkFill } from "../../../../Assets/NewIcons/check-rounded.svg";
import { ReactComponent as CrossIcon } from "../../../../Assets/NewIcons/close-cross.svg";
import { ActionPopupGenerator } from "../../../NewComponents/Common/ActionPopup";
import { ProviderTask } from "../../../../types/Provider";
import { patientsCustomSorting } from "../../../../features/utils/patientsCustomSorting";
import { getProvidersInfoState } from "../../../../features/providers";
import { PatientsTableTabs } from "../../../NewComponents/Common/PatientsTableElastic/tableTabs";
import { PatientsTableElastic } from "../../../NewComponents/Common/PatientsTableElastic";
import { PatientDashboardItem } from "../../../../types/Dashboard";
import {
  useGetEmployeeQuery,
  useGetEmployeesByRoleQuery,
} from "../../../../features/api/employees";
import { EnrollmentCoordinator, Provider } from "../../../../types/Employee";
import { TableHeadPayload } from "../../../../types/Table/TableHeadPayload";
import { Skeleton } from "../../../NewComponents/Common/Skeleton";
import { USER_ROLES } from "../../../../types/Main";
import { useConfirmAppointmentMutation } from "../../../../features/api/appointments";
import { useSetFlagMutation } from "../../../../features/api/patients";
import {
  getPatientDashboardForEnrollmentCoordinatorFiltersDeclinedVisits,
  getPatientDashboardForEnrollmentCoordinatorFiltersPostEnrollment,
  getPatientDashboardForEnrollmentCoordinatorFiltersUpcomingVisits,
} from "./filters";
import {
  patientDashboardForEnrollmentCoordinatorSortDeclinedVisits,
  patientDashboardForEnrollmentCoordinatorSortPostEnrollment,
  patientDashboardForEnrollmentCoordinatorSortUpcomingVisits,
} from "./sortings";
import {
  getStyleForPostEnrollmentDashboard,
  getStyleForUpcomingVisitsDashboard,
} from "./styles";
import { wait } from "../../../../utils/wait";
import {
  patientDashboardForEnrollmentCoordinatorOrderDeclinedVisits,
  patientDashboardForEnrollmentCoordinatorOrderPostEnrollment,
  patientDashboardForEnrollmentCoordinatorOrderUpcomingVisits,
} from "./orders";
import { APPLICATION_START_POINT_TIMESTAMP } from "../../../../constants";
import { DashboardDataContext } from "../../../../contexts/dashboard-data";

function ClinicianNames({ providerIds }: { providerIds: string[] }) {
  const employeesQuery = useGetEmployeesByRoleQuery("provider");
  const providers = employeesQuery.data as Provider[] | undefined;
  const names = providers
    ?.filter(({ id }) => providerIds.includes(id))
    .map(({ firstName, lastName, title }) => (
      <p>
        {firstName} {lastName}
        {title ? `, ${title}` : ""}
      </p>
    ));

  // eslint-disable-next-line no-nested-ternary, react/jsx-no-useless-fragment
  return names ? names.length ? <>{names}</> : <p>"-"</p> : <Skeleton />;
}

function RescheduleAppointmentButton({
  payload,
}: {
  payload: PatientDashboardItem;
}) {
  const history = useHistory();

  return (
    <div
      className="patients-table__action-button"
      onClick={(e) => {
        e.stopPropagation();
        history.push(`/reschedule/${payload.patientId}`);
      }}
      data-tooltip="Reschedule Appointment"
    >
      <CalendarIcon />
    </div>
  );
}

function CancelAppointmentButton({
  payload,
}: {
  payload: PatientDashboardItem;
}) {
  return (
    <div
      className="patients-table__action-button"
      onClick={(e) => {
        e.stopPropagation();
        window.location.assign(
          `https://calendly.com/app/scheduled_events/team/151216?trigger_cancel=${payload.appointmentId}&uuid=${payload.appointmentId}`
        );
      }}
      data-tooltip="Cancel Appointment"
    >
      <CrossIcon />
    </div>
  );
}

function ConfirmAppointmentButton({
  payload,
  reload,
}: {
  payload: PatientDashboardItem;
  reload: () => Promise<void>;
}) {
  const { showPopup } = useContext(PopupLayerContext);
  const { showSuccess, showError } = useContext(NotificationLayerContext);
  const [confirmAppointment] = useConfirmAppointmentMutation();
  const pinnedFlag = "pinnedByCareCoordinator";
  const pinned = !!payload.flags[pinnedFlag];

  return (
    <div
      className="patients-table__action-button"
      onClick={(e) => {
        e.stopPropagation();
        if (payload.appointmentStatus === "CONFIRMED") return;
        showPopup(
          ActionPopupGenerator({
            title: "Confirm appointment of the patient?",
            text: `Are you sure you want to confirm an appointment of ${payload.fullName}?`,
            action: async () => {
              const response: any = await confirmAppointment(payload.patientId);
              if (response.error) {
                showError({
                  title: "Something went wrong...",
                  description: "Unable to confirm an appointment",
                });
                throw new Error("Unable to confirm an appointment");
              }
              await wait(3000);
              reload();
              showSuccess({
                title: "Success!",
                description: "You've successfuly confirmed an appointment",
              });
            },
            actionText: "Confirm appointment",
          })
        );
      }}
      data-tooltip={
        payload.appointmentStatus === "CONFIRMED"
          ? "Appointment confirmed"
          : "Confirm appointment"
      }
    >
      {payload.appointmentStatus === "CONFIRMED" ? (
        <CheckmarkFill />
      ) : (
        <CheckmarkStroke />
      )}
    </div>
  );
}

function MarkAsContactedButton({
  payload,
  reload,
}: {
  payload: PatientDashboardItem;
  reload: () => Promise<void>;
}) {
  const { showPopup } = useContext(PopupLayerContext);
  const { showSuccess, showError } = useContext(NotificationLayerContext);
  const [setFlag] = useSetFlagMutation();
  const flag = "contacted";
  const flagValue = !!payload.flags[flag];

  return (
    <div
      className="patients-table__action-button"
      onClick={(e) => {
        e.stopPropagation();
        showPopup(
          ActionPopupGenerator({
            title: flagValue ? "Revert contacted mark" : "Mark as contacted",
            text: flagValue
              ? `Are you sure you want to mark ${payload.fullName} as 'Uncontacted'?`
              : `Are you sure you want to mark ${payload.fullName} as 'Contacted'?`,
            action: async () => {
              const response: any = await setFlag({
                patientId: payload.patientId,
                flag,
                state: !flagValue,
              });
              if (response.error) {
                showError({
                  title: "Something went wrong...",
                  description: "Unable to change status of a patient",
                });
                throw new Error("Unable to change status of a patient");
              }
              await wait(3000);
              reload();
              showSuccess({
                title: "Success!",
                description: `You've successfuly marked patient as "${
                  !flagValue ? "Uncontacted" : "Contacted"
                }"`,
              });
            },
            actionText: flagValue
              ? "Revert contacted flag"
              : "Mark as contacted",
          })
        );
      }}
      data-tooltip={
        payload.appointmentStatus === "CONFIRMED"
          ? "Appointment confirmed"
          : "Confirm appointment"
      }
    >
      {payload.flags[flag] ? <CheckmarkFill /> : <CheckmarkStroke />}
    </div>
  );
}

const tableColumns: TableHeadPayload<PatientDashboardItem>[] = [
  {
    title: "Full name",
    get: (payload) => getLastName(payload.fullName),
    getPretty: (payload) => (
      <p>
        {payload.fullName}{" "}
        {!payload.followUp ? (
          <span
            className="patients-table__attention-sign"
            data-tooltip="No medical background"
          >
            !
          </span>
        ) : null}
        {payload.subscription?.accutane ? (
          <span className="patients-table__accutane-sign">Accutane</span>
        ) : null}
        {payload.subscription?.membership ? (
          <span className="patients-table__membership-sign">Membership</span>
        ) : null}
      </p>
    ),
    sort: (dir) => [
      {
        filter: {
          bool: {
            must: {
              exists: {
                field: "lastNameNumberValue",
              },
            },
          },
        },
        script_score: {
          script: {
            source: `${
              dir === "asc" ? "1 -" : ""
            } (doc['lastNameNumberValue'].value  / 100000000.0)`,
          },
        },
      },
      {
        filter: {
          match_all: {},
        },
        weight: 1,
      },
    ],
  },
  {
    title: "Phone",
    get: (payload) => payload.phone || "N/A",
    getPretty: (payload) => <p>{payload.phone}</p>,
    // sort: alphabetical,
  },
  {
    title: "Date of Birth",
    get: (payload) => payload.dateOfBirth,
    getPretty: (payload) => (
      <p>
        {getMomentDate(payload.dateOfBirth)
          .add(12, "hours")
          .format("MMM Do, YYYY")}
      </p>
    ),
    sort: (dir) => [
      {
        filter: {
          bool: {
            must: {
              exists: {
                field: "dateOfBirthDate",
              },
            },
          },
        },
        script_score: {
          script: {
            source: `${
              dir === "desc" ? "1 -" : ""
            } doc['dateOfBirthDate'].value / 100000.0`,
          },
        },
      },
      {
        filter: {
          match_all: {},
        },
        weight: 1,
      },
    ],
  },
  {
    title: "Date of Service",
    get: () => 0,
    getPretty: ({ appointmentDate }) => (
      <p>{appointmentDate ? getLocalDateNonMilitary(appointmentDate) : "-"}</p>
    ),
    sort: (dir) => [
      {
        filter: {
          bool: {
            must: [
              {
                exists: {
                  field: "appointmentDate",
                },
              },
            ],
          },
        },
        script_score: {
          script: {
            source: `${
              dir === "desc" ? "1 -" : ""
            } 10 / Math.log((doc['appointmentDate'].value - ${APPLICATION_START_POINT_TIMESTAMP}L) / 60000)`,
          },
        },
      },
      {
        filter: {
          match_all: {},
        },
        weight: 1,
      },
    ],
  },
  {
    title: "Time of appt. for patient",
    get: () => 0,
    getPretty: ({ appointmentDate, inviteeTimezone }) => (
      <p>
        {inviteeTimezone
          ? getGivenTimezoneDate(appointmentDate, inviteeTimezone)
          : "-"}
      </p>
    ),
    sort: (dir) => [
      {
        filter: {
          bool: {
            must: [
              {
                exists: {
                  field: "appointmentDate",
                },
              },
            ],
          },
        },
        script_score: {
          script: {
            source: `${
              dir === "desc" ? "1 -" : ""
            } 10 / Math.log((doc['appointmentDate'].value - ${APPLICATION_START_POINT_TIMESTAMP}L) / 60000)`,
          },
        },
      },
      {
        filter: {
          match_all: {},
        },
        weight: 1,
      },
    ],
  },
  {
    title: "Clinician",
    get: (payload) => "",
    getPretty: (payload) => (
      <ClinicianNames
        providerIds={
          payload.careTeam
            ?.filter(({ role }) => role === USER_ROLES.PROVIDER)
            .map(({ employeeId }) => employeeId) || []
        }
      />
    ),
    sort: (dir) => [
      {
        filter: {
          bool: {
            must: {
              exists: {
                field: "providerLastNameNumberValue",
              },
            },
          },
        },
        script_score: {
          script: {
            source: `${
              dir === "asc" ? "1 -" : ""
            } (doc['providerLastNameNumberValue'].value  / 100000000.0)`,
          },
        },
      },
      {
        filter: {
          match_all: {},
        },
        weight: 1,
      },
    ],
  },
  {
    title: "Actions",
    get: () => "",
    getPretty: (payload, reload, handleMedBackgroundReminderCount) => {
      const handleCountUpdate = (e: any) => {
        e.stopPropagation();
        handleMedBackgroundReminderCount(payload);
      };
      return (
        <div
          className="patients-table__actions"
          style={{ alignItems: "center" }}
        >
          {/* {!payload.isMedicalBackgroundExists && ( */}
          <div
            type="button"
            className="radio-button-box"
            onClick={(e) => handleCountUpdate(e)}
            data-tooltip="Reminder count"
            // disabled={payload.medBackgroundReminderCount >= 3}
          >
            {payload.medBackgroundReminderCount || 0}
          </div>
          {/* )} */}
          {(payload.appointmentStatus === "PENDING" ||
            payload.appointmentStatus === "CONFIRMED") &&
          (!payload.followUp ||
            (payload.followUp.isInitialFollowUp &&
              !payload.followUp.isTreatmentPlanSet)) ? (
            <ConfirmAppointmentButton payload={payload} reload={reload} />
          ) : null}
          {payload.followUp?.isInitialFollowUp === true &&
          !payload.followUp?.isTreatmentPlanSet ? (
            <RescheduleAppointmentButton payload={payload} />
          ) : null}
          {(payload.appointmentStatus === "PENDING" ||
            payload.appointmentStatus === "CONFIRMED") &&
          (!payload.followUp ||
            (payload.followUp.isInitialFollowUp &&
              !payload.followUp.isTreatmentPlanSet)) ? (
            <CancelAppointmentButton payload={payload} />
          ) : null}
          {payload.followUp &&
          (!payload.followUp.isInitialFollowUp ||
            (payload.followUp.isInitialFollowUp &&
              payload.followUp.isTreatmentPlanSet)) ? (
            <MarkAsContactedButton payload={payload} reload={reload} />
          ) : null}
        </div>
      );
    },
  },
];

export function PatientsPageForEnrollmentCoordinator() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [tab, setTab] = useState(0);
  const [tableParams, setTableParams] = useState<
    { filters: any; sort: any; order: any }[]
  >([]);

  const { userId: enrollmentCoordinatorId } = useSelector(getSessionState);
  const enrollmentCoordinatorQuery = useGetEmployeeQuery(
    enrollmentCoordinatorId as string,
    {
      skip: !enrollmentCoordinatorId,
    }
  );
  const { showPopup } = useContext(PopupLayerContext);
  const { showSuccess } = useContext(NotificationLayerContext);
  const activeDashboardData = useContext(DashboardDataContext);
  const prepopulatedList = {
    0: activeDashboardData,
  };

  useEffect(() => {
    if (!enrollmentCoordinatorId) return;
    setTableParams([
      {
        filters:
          getPatientDashboardForEnrollmentCoordinatorFiltersUpcomingVisits(),
        sort: patientDashboardForEnrollmentCoordinatorSortUpcomingVisits,
        order: patientDashboardForEnrollmentCoordinatorOrderUpcomingVisits,
      },
      {
        filters:
          getPatientDashboardForEnrollmentCoordinatorFiltersPostEnrollment(),
        sort: patientDashboardForEnrollmentCoordinatorSortPostEnrollment,
        order: patientDashboardForEnrollmentCoordinatorOrderPostEnrollment,
      },
      {
        filters:
          getPatientDashboardForEnrollmentCoordinatorFiltersDeclinedVisits(),
        sort: patientDashboardForEnrollmentCoordinatorSortDeclinedVisits,
        order: patientDashboardForEnrollmentCoordinatorOrderDeclinedVisits,
      },
    ]);
  }, [enrollmentCoordinatorId]);

  useEffect(() => {
    const currentIndex = localStorage.getItem("currentIndex");
    const currentIndexFormatted = JSON.parse(currentIndex);
    if (!isNull(currentIndexFormatted)) {
      setTab(currentIndexFormatted.currentTab);
    }
  }, []);

  const enrollmentCoordinator = enrollmentCoordinatorQuery.data as
    | EnrollmentCoordinator
    | undefined;
  if (!enrollmentCoordinator) return null;
  const enrollmentCoordinatorName = enrollmentCoordinator
    ? [enrollmentCoordinator.firstName, enrollmentCoordinator.lastName]
    : null;

  function renderTable() {
    if (!tableParams[tab]) return null;
    switch (tab) {
      case 0:
        return (
          <PatientsTableElastic<PatientDashboardItem>
            filters={tableParams[tab].filters}
            sort={tableParams[tab].sort}
            order={tableParams[tab].order}
            columns={tableColumns}
            showMembershipStatus
            prepopulatedItems={prepopulatedList[tab]}
            stylizeRow={getStyleForUpcomingVisitsDashboard}
            onClick={(item, state) => {
              window.scrollTo(0, 0);
              const myState = { currentSubTab: state, currentTab: tab };
              localStorage.setItem("currentIndex", JSON.stringify(myState));
              history.push(`/${item.patientId}`);
            }}
          />
        );
      case 1:
        return (
          <PatientsTableElastic<PatientDashboardItem>
            filters={tableParams[tab].filters}
            sort={tableParams[tab].sort}
            order={tableParams[tab].order}
            columns={tableColumns}
            stylizeRow={getStyleForPostEnrollmentDashboard}
            onClick={(item, state) => {
              window.scrollTo(0, 0);
              const myState = { currentSubTab: state, currentTab: tab };
              localStorage.setItem("currentIndex", JSON.stringify(myState));
              history.push(`/${item.patientId}`);
            }}
          />
        );
      case 2:
        return (
          <PatientsTableElastic<PatientDashboardItem>
            filters={tableParams[tab].filters}
            sort={tableParams[tab].sort}
            order={tableParams[tab].order}
            columns={tableColumns}
            onClick={(item, state) => {
              window.scrollTo(0, 0);
              const myState = { currentSubTab: state, currentTab: tab };
              localStorage.setItem("currentIndex", JSON.stringify(myState));
              history.push(`/${item.patientId}`);
            }}
          />
        );
      default:
        return null;
    }
  }

  const tabs = ["Upcoming visits", "Post-visit enrollment", "Declined visits"];

  return (
    <div className="greeting-container">
      <DoctorGreetingBanner
        items={[
          {
            text: "Upcoming visits to confirm",
            filter:
              getPatientDashboardForEnrollmentCoordinatorFiltersUpcomingVisits().find(
                ({ text }) => text === "Unconfirmed"
              )?.filter,
            color: TaskColors.Pink,
          },
          {
            text: "Unenrolled patients to contact",
            filter:
              getPatientDashboardForEnrollmentCoordinatorFiltersPostEnrollment().find(
                ({ text }) => text === "Uncontacted"
              )?.filter,
            color: TaskColors.LightBlue,
          },
          {
            text: "Miscellaneous tasks",
            count: activeDashboardData.miscellaneousTasks?.count,
            color: TaskColors.Orange,
          },
        ]}
        name={
          enrollmentCoordinatorName ? enrollmentCoordinatorName.join(" ") : null
        }
      />
      <div className="patients-table">
        <div className="patients-table__tabs">
          <PatientsTableTabs
            tabs={tabs}
            onChange={(state) => {
              setTab(state);
            }}
          />
        </div>
      </div>
      {renderTable()}
    </div>
  );
}
