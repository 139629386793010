import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ENV_CONFIG } from "../../config";
import { AuthenticationService } from "../../services/cognito";
import { Medication, TreatmentPlanPreset } from "../../types/Medication";
import { TreatmentPlan } from "../../types/Entities/TreatmentPlan";

const medicationFieldsQuery = `{
  id
  instructions
  isAccutane
  name
  refillCount
  refillExpiration
  size
  specialInstructions
  strength
  archived
}`;

const treatmentPlanFieldsQuery = `{
  id
  name
  groups
  archived
}`;

export const medicationsApiSlice = createApi({
  reducerPath: "api/medications",
  tagTypes: ["medication", "treatment-plan"],
  baseQuery: fetchBaseQuery({
    baseUrl: ENV_CONFIG.MEDICATIONS_SERVICE_GRAPHQL_URL,
    prepareHeaders: async (headers) => {
      const token = await AuthenticationService.getAccessToken();
      headers.set("Authorization", token);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getMedication: builder.query<Medication, string>({
      query: (medicationId: string) => ({
        url: "",
        params: {
          query: `query {
            getMedication(medicationId: "${medicationId}") ${medicationFieldsQuery}
          }`,
          variables: JSON.stringify({
            medicationId,
          }),
        },
      }),
      transformResponse: (response: any) => response.data.getMedication,
      providesTags: (result) =>
        result ? [{ type: "medication", id: result.id }] : [],
    }),
    getAllMedications: builder.query<Medication[], null>({
      query: () => ({
        url: "",
        params: {
          query: `query { getAllMedications ${medicationFieldsQuery} }`,
        },
      }),
      transformResponse: (response: any) =>
        response.data.getAllMedications || [],
      providesTags: (result) =>
        result ? result.map(({ id }) => ({ type: "medication", id })) : [],
    }),
    createMedication: builder.mutation<{ id: string }, Partial<Medication>>({
      query: (newMedication) => ({
        url: "",
        method: "POST",
        body: JSON.stringify({
          query:
            "mutation ($payload: MedicationInput!) { createMedication(payload: $payload) { id } }",
          variables: JSON.stringify({
            payload: newMedication,
          }),
        }),
      }),
      transformResponse: (result: any) => {
        if (result.errors) {
          throw new Error("Error occured during medication submission");
        }
        return result.data.createMedication;
      },
      invalidatesTags: (result) => (result ? [{ type: "medication" }] : []),
    }),
    updateMedication: builder.mutation<
      { id: string },
      { medicationId: string; payload: Partial<Medication> }
    >({
      query: ({ medicationId, payload }) => ({
        url: "",
        method: "POST",
        body: JSON.stringify({
          query:
            "mutation ($medicationId: String!, $payload: MedicationInput!) { updateMedication(medicationId: $medicationId, payload: $payload) { id } }",
          variables: JSON.stringify({
            medicationId,
            payload,
          }),
        }),
      }),
      transformResponse: (result: any) => {
        if (result.errors) {
          throw new Error("Error occured during medication submission");
        }
        return result.data.updateMedication;
      },
      invalidatesTags: (result) =>
        result ? [{ type: "medication", id: result.id }] : [],
    }),
    archiveMedication: builder.mutation<
      { id: string },
      { medicationId: string; archived: boolean }
    >({
      query: ({ medicationId, archived }) => ({
        url: "",
        method: "POST",
        body: JSON.stringify({
          query:
            "mutation ($medicationId: String!, $archived: Boolean!) { archiveMedication(medicationId: $medicationId, archived: $archived) { id } }",
          variables: JSON.stringify({
            medicationId,
            archived,
          }),
        }),
      }),
      transformResponse: (result: any) => {
        if (result.errors) {
          throw new Error("Error occured during medication submission");
        }
        return result.data.archiveMedication;
      },
      invalidatesTags: (result) =>
        result ? [{ type: "medication", id: result.id }] : [],
    }),
    getAllTreatmentPlans: builder.query<TreatmentPlan[], null>({
      query: () => ({
        url: "",
        params: {
          query: `query { getAllTreatmentPlans ${treatmentPlanFieldsQuery} }`,
        },
      }),
      transformResponse: (response: any) =>
        response.data.getAllTreatmentPlans || [],
      providesTags: (result) =>
        result ? result.map(({ id }) => ({ type: "treatment-plan", id })) : [],
    }),
    createTreatmentPlan: builder.mutation<
      { id: string },
      Partial<TreatmentPlanPreset>
    >({
      query: (newTreatmentPlan) => ({
        url: "",
        method: "POST",
        body: JSON.stringify({
          query:
            "mutation ($payload: TreatmentPlanPresetInput!) { createTreatmentPlan(payload: $payload) { id } }",
          variables: JSON.stringify({
            payload: newTreatmentPlan,
          }),
        }),
      }),
      transformResponse: (result: any) => {
        if (result.errors) {
          throw new Error("Error occured during treatment plan submission");
        }
        return result.data.createTreatmentPlan;
      },
      invalidatesTags: (result) => (result ? [{ type: "treatment-plan" }] : []),
    }),
    updateTreatmentPlan: builder.mutation<
      { id: string },
      { treatmentPlanId: string; payload: Partial<TreatmentPlanPreset> }
    >({
      query: ({ treatmentPlanId, payload }) => ({
        url: "",
        method: "POST",
        body: JSON.stringify({
          query:
            "mutation ($treatmentPlanId: String!, $payload: TreatmentPlanPresetInput!) { updateTreatmentPlan(treatmentPlanId: $treatmentPlanId, payload: $payload) { id } }",
          variables: JSON.stringify({
            treatmentPlanId,
            payload,
          }),
        }),
      }),
      transformResponse: (result: any) => {
        if (result.errors) {
          throw new Error("Error occured during treatment plan submission");
        }
        return result.data.updateTreatmentPlan;
      },
      invalidatesTags: (result) =>
        result ? [{ type: "treatment-plan", id: result.id }] : [],
    }),
    archiveTreatmentPlan: builder.mutation<
      { id: string },
      { treatmentPlanId: string; archived: boolean }
    >({
      query: ({ treatmentPlanId, archived }) => ({
        url: "",
        method: "POST",
        body: JSON.stringify({
          query:
            "mutation ($treatmentPlanId: String!, $archived: Boolean!) { archiveTreatmentPlan(treatmentPlanId: $treatmentPlanId, archived: $archived) { id } }",
          variables: JSON.stringify({
            treatmentPlanId,
            archived,
          }),
        }),
      }),
      transformResponse: (result: any) => {
        if (result.errors) {
          throw new Error("Error occured during treatment plan submission");
        }
        return result.data.archiveTreatmentPlan;
      },
      invalidatesTags: (result) =>
        result ? [{ type: "treatment-plan", id: result.id }] : [],
    }),
  }),
});

export const {
  useGetMedicationQuery,
  useGetAllMedicationsQuery,
  useCreateMedicationMutation,
  useUpdateMedicationMutation,
  useGetAllTreatmentPlansQuery,
  useCreateTreatmentPlanMutation,
  useUpdateTreatmentPlanMutation,
  useArchiveMedicationMutation,
  useArchiveTreatmentPlanMutation,
} = medicationsApiSlice;
