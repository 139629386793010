import moment from "moment-timezone";
import React, { useContext, useState } from "react";
import Select from "react-select";
import { v4 } from "uuid";
import { Button } from "../../Common/Button";
import { NotificationLayerContext } from "../../Common/NotificationLayer";
import {
  CustomPopupTemplate,
  CustomPopupTemplateContext,
} from "../../Common/PopupLayer/custom";
import "./style.scss";
import { HoneydewAPI } from "../../../../services/honeydew-api";

const MONTHS_AMOUNT = 12;
const TIMEZONE = "America/New_York";

interface PopupProps {
  providerId: string;
}

interface Props {
  onClose: () => void;
}

function ProvidersReportPickerPopup({ providerId }: PopupProps) {
  const { showError } = useContext(NotificationLayerContext);
  const [timestamp, setTimestamp] = useState<number | null>(null);
  const { onClose } = useContext(CustomPopupTemplateContext);

  async function submit() {
    if (!timestamp) return;

    try {
      const startDate = moment.tz(timestamp, TIMEZONE).startOf("month");
      const endDate = startDate.clone().add(1, "month");

      const dateFrom = startDate.clone().utc().valueOf();
      const dateTo = endDate.clone().utc().valueOf();

      const link = await HoneydewAPI.providers.generateReport(providerId, {
        dateFrom,
        dateTo,
      });

      const { Location } = JSON.parse(link.billingReport);
      window.location.assign(Location);
    } catch {
      showError({
        title: "Something went wrong",
        description:
          "Could not generate provider report. Please, try again later",
      });
    }
  }

  function generateSelectItems() {
    const result = [];
    const now = moment.tz(TIMEZONE);

    for (let i = 0; i < MONTHS_AMOUNT; i++) {
      const dateOption = now.clone().subtract(i, "months").startOf("month");

      const selectedTime = dateOption.valueOf();

      result.push({
        value: selectedTime,
        label: dateOption.format("MMMM, YYYY"),
      });
    }

    return result;
  }

  function getDateRangeDisplay(selectedTime: number) {
    const start = moment.tz(selectedTime, TIMEZONE).startOf("month");
    const end = start.clone().add(1, "month").subtract(1, "millisecond");

    return `${start.format("MMM D, YYYY HH:mm")} to ${end.format(
      "MMM D, YYYY HH:mm"
    )} ET`;
  }

  return (
    <div className="providers-report-picker">
      <div className="providers-report-picker__message-block">
        <p className="providers-report-picker__title">
          Please select a month to generate a report
        </p>
        <Select
          className="providers-report-picker__select"
          options={generateSelectItems()}
          onChange={(data) => setTimestamp(data?.value as number)}
          menuPosition="fixed"
          maxMenuHeight={300}
          id={v4()}
        />
        {timestamp && (
          <p className="providers-report-picker__period">
            {getDateRangeDisplay(timestamp)}
          </p>
        )}
      </div>
      <div className="providers-report-picker__actions">
        <Button
          text="Cancel"
          view="secondary"
          onClick={onClose}
          className="providers-report-picker__action"
        />
        <Button
          text="Generate"
          className="providers-report-picker__action"
          onClick={submit}
          disabled={!timestamp}
        />
      </div>
    </div>
  );
}

export function ProvidersReportPickerPopupGenerator(props: PopupProps) {
  return function render({ onClose }: Props) {
    return (
      <CustomPopupTemplate onClose={onClose}>
        <ProvidersReportPickerPopup {...props} />
      </CustomPopupTemplate>
    );
  };
}
