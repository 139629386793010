/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
  useRef,
} from "react";
import { argv0 } from "process";
import { BloodWorkPopupSteps } from "../app/NewComponents/Patient/BloodWorkPopup/steps";
import { getNextStep, getPreviousStep } from "../utils/blood-work-stepper";
import { BloodWorkForm } from "../types/BloodWorkForm";
import { getLabsPrices } from "../services/honeydew-api/labs/get-labs-prices";
import { LabPricesResponse } from "../types/LabPricesResponse";
import { getLabsLocation } from "../services/honeydew-api/labs";
import {
  getPricedResponse,
  PricedLabs,
} from "../app/NewComponents/Common/FindLab/utils";
import { Lab } from "../types/Entities/Lab";

type BloodWorkStepper = {
  handleNext: (nextStep?: number) => void;
  handleBack: (prevStep?: number) => void;
  updateValues: (form: Partial<BloodWorkForm>) => void;
  setIsNextDisabled: (value: boolean) => void;
  setCloseFunction: (close: () => void) => void;
  setCurrentStep: (step: number) => void;
  cleanup: () => void;
  onClose?: () => void;
  currentStep: number;
  bloodwork: BloodWorkForm;
  isNextDisabled: boolean;
  labsWithPricing?: PricedLabs[];
  prices?: LabPricesResponse[];
  labLocations?: Lab[];
  updateLabLocations: (zipCode?: string) => Promise<Lab[]>;
  resetLabLocations: () => void;
  isStepReady: () => boolean;
};

const DEFAULT_VALUE = {
  handleNext: () => {},
  handleBack: () => {},
  updateValues: () => {},
  setIsNextDisabled: () => {},
  setCloseFunction: () => {},
  setCurrentStep: () => {},
  cleanup: () => {},
  onClose: () => {},
  currentStep: 0,
  bloodwork: {},
  isNextDisabled: false,
  labsWithPricing: [],
  prices: [],
  labLocations: [],
  updateLabLocations: (zipCode?: string) => Promise.resolve([]),
  resetLabLocations: () => {},
  isStepReady: () => false,
};

const BloodWorkStepperContext = createContext<BloodWorkStepper>(DEFAULT_VALUE);

export function BloodWorkStepperProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [currentStep, setCurrentStep] = useState(BloodWorkPopupSteps.WELCOME);
  const [bloodwork, setBloodwork] = useState<BloodWorkForm>({});
  const [onClose, setOnClose] = useState<() => void>();
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [prices, setLabPrices] = useState<LabPricesResponse[]>();
  const [labLocations, setLabLocations] = useState<Lab[]>();

  const labLocationsCache = useRef<Record<string, Lab[]>>({});

  const cleanup = () => {
    setCurrentStep(BloodWorkPopupSteps.WELCOME);
    setBloodwork({});
    setIsNextDisabled(false);
  };

  useEffect(() => () => cleanup(), []); // cleanup when unmounted

  const updateLabLocations = async (zipCode?: string) => {
    if (zipCode) {
      if (zipCode in labLocationsCache.current) {
        const cachedLocations = [...labLocationsCache.current[zipCode]];
        setLabLocations(cachedLocations);
        return Promise.resolve(cachedLocations); // Return element from cache
      }
      return getLabsLocation(
        bloodwork.patient?.shippingInfo?.state ||
          bloodwork.patient?.state ||
          "",
        zipCode
      ).then((locations) => {
        labLocationsCache.current[zipCode] = locations;
        setLabLocations(locations);
        return locations;
      });
    }
    setLabLocations([]);
    return Promise.resolve([]);
  };

  const resetLabLocations = () => {
    setLabLocations([]);
  };

  const getPricing = () => {
    if (bloodwork.task?.id)
      return getLabsPrices(bloodwork.task.id).then(setLabPrices);
    return Promise.resolve([]);
  };

  useEffect(() => {
    Promise.all([
      updateLabLocations(bloodwork.patient?.shippingInfo?.zipCode),
      getPricing(),
    ]);
  }, [bloodwork.task?.id]);

  const handleNext = (nextStep?: number) => {
    if (
      currentStep === BloodWorkPopupSteps.CHECKOUT ||
      currentStep === BloodWorkPopupSteps.CHECKOUT_INSURANCE ||
      currentStep === BloodWorkPopupSteps.TIPS_FOR_LAB
    ) {
      cleanup();
      onClose?.();
    } else if (nextStep) {
      setCurrentStep(nextStep);
    } else {
      setCurrentStep(getNextStep(currentStep));
    }
  };

  const handleBack = (prevStep?: number) => {
    if (currentStep === BloodWorkPopupSteps.WELCOME) {
      cleanup();
      onClose?.();
    } else if (prevStep) {
      setCurrentStep(prevStep);
    } else {
      setCurrentStep(getPreviousStep(currentStep));
    }
  };

  const setCloseFunction = (close: () => void) => {
    setOnClose(close);
  };

  const updateValues = (form: BloodWorkForm) => {
    setBloodwork({ ...bloodwork, ...form });
  };

  const isStepReady = () => {
    switch (currentStep) {
      case BloodWorkPopupSteps.INSURANCE_SELECTION:
        return !!prices && !!labLocations;
      default:
        return true;
    }
  };

  const value = useMemo(
    () => ({
      handleNext,
      handleBack,
      updateValues,
      setIsNextDisabled,
      setCloseFunction,
      setCurrentStep,
      cleanup,
      onClose,
      currentStep,
      bloodwork,
      isNextDisabled,
      labsWithPricing: getPricedResponse(prices, labLocations),
      prices,
      labLocations,
      updateLabLocations,
      resetLabLocations,
      isStepReady,
    }),
    [currentStep, bloodwork, isNextDisabled, onClose, prices, labLocations]
  );
  return (
    <BloodWorkStepperContext.Provider value={value}>
      {children}
    </BloodWorkStepperContext.Provider>
  );
}

export const useBloodWorkStepper = () => useContext(BloodWorkStepperContext);
