import { MODE } from "../../constants";
import { storeUTM } from "../../services/honeydew-api/marketing/store-utm";

const getCookie = () => {
  try {
    const name = "honeydew_utm_data=";
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookiesArray = decodedCookie.split(";");
    for (let i = 0; i < cookiesArray?.length; i++) {
      const cookie = cookiesArray[i].trim();
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length);
      }
    }
    return null;
  } catch (e) {
    console.error("Error occurred while getting cookie", e);
    return null;
  }
};

const deleteCookie = () => {
  document.cookie = `honeydew_utm_data=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

const parseQuery = (query: string) => {
  try {
    const cleanedQueryString = query?.startsWith("?") ? query?.slice(1) : query;

    // Split the query string into key-value pairs
    return cleanedQueryString?.split("&").map((param) => {
      const [key, value] = param.split("=");
      return { key, value };
    });
  } catch (e) {
    console.error("Error occurred while parsing query", e);
    return [];
  }
};

export const storeUTMParameters = async (patientId: string) => {
  if (MODE !== "PROD" && MODE !== "TEST") return;
  const utmQuery = getCookie();
  if (utmQuery) {
    try {
      await storeUTM({ patientId, utmValues: parseQuery(utmQuery) });
      deleteCookie();
    } catch (error) {
      console.error("Error occurred. Cannot store UTM parameters", error);
    }
  }
};
