import React from "react";
import Welcome from "./Welcome";
import InsuranceSelection from "./InsuranceSelection";
import AddNewCard from "./Payment/AddNewCard";
import FindLab from "./FindLab";
import ChangePaymentMethod from "./Payment/ChangePaymentMethod";
import LabSelection from "./LabSelection";
import PaymentDetailsWrapper from "./Payment";
import CheckoutMedicalOffice from "./CheckoutMedicalOffice";
import CheckoutLab from "./CheckoutLab";
import Checkout from "./Checkout";
import LabTips from "../LabTips";

export enum PregnancyWorkPopupSteps {
  WELCOME = 0,
  INSURANCE_SELECTION = 1,
  LAB_SELECTION = 2,
  FIND_LAB = 3,
  PAYMENT_DETAILS = 4,
  PAYMENT_METHOD = 5,
  NEW_CARD = 6,
  CHECKOUT = 7,
  INSURANCE = 8,
  MEDICAL_OFFICE = 9,
  INSURANCE_LAB = 10,
  TIPS_FOR_LAB = 11,
}

export const renderStep = (step: PregnancyWorkPopupSteps) => {
  switch (step) {
    case PregnancyWorkPopupSteps.WELCOME:
      return <Welcome />;
    case PregnancyWorkPopupSteps.INSURANCE_SELECTION:
      return <InsuranceSelection />;
    case PregnancyWorkPopupSteps.LAB_SELECTION:
      return <LabSelection />;
    case PregnancyWorkPopupSteps.FIND_LAB:
      return <FindLab />;
    case PregnancyWorkPopupSteps.PAYMENT_DETAILS:
      return <PaymentDetailsWrapper />;
    case PregnancyWorkPopupSteps.PAYMENT_METHOD:
      return <ChangePaymentMethod />;
    case PregnancyWorkPopupSteps.NEW_CARD:
      return <AddNewCard />;
    case PregnancyWorkPopupSteps.CHECKOUT:
      return <Checkout />;
    case PregnancyWorkPopupSteps.MEDICAL_OFFICE:
      return <CheckoutMedicalOffice />;
    case PregnancyWorkPopupSteps.INSURANCE_LAB:
      return <CheckoutLab />;
    case PregnancyWorkPopupSteps.TIPS_FOR_LAB:
      return <LabTips type="pregnancy" />;
    default:
      return <Welcome />;
  }
};
