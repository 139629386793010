import React, {
  FormEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import TextareaAutosize from "react-textarea-autosize";
import {
  Conversation,
  Message,
  Paginator,
  Participant,
} from "@twilio/conversations";
import { STATUS_CODES } from "http";
import PubNub from "pubnub";
import { getSessionState } from "../../../../features/session";
import { AcneAwayAPI } from "../../../../services/acneaway-api";
import { logError } from "../../../../shared/logger";
import { SubmitButton } from "../Button";
import { Loader } from "../Loader";
import { ChatPopupTemplate } from "../PopupLayer/chat";
import { ChatChannel } from "./ChatChannel";
import { ReactComponent as LockIcon } from "../../../../Assets/NewIcons/lock.svg";
import { ReactComponent as CloseIcon } from "../../../../Assets/NewIcons/close-icon-filled.svg";
import "./style.scss";
import { Patient } from "../../../../types/Entities/Patient";
// import { TwilioController } from "../../../../services/twilio";
import { getTwilioState } from "../../../../features/twilio";
import { NotificationLayerContext } from "../NotificationLayer";
import { ReactComponent as ConfirmSign } from "../../../../Assets/NewIcons/check-rounded.svg";
import {
  useGetCareTeamQuery,
  useGetPatientsByAccountIdQuery,
  useGetPatientByIdQuery,
  useUpdateChatDataMutation,
} from "../../../../features/api/patients";
import { useGetSubscriptionsByPatientIdQuery } from "../../../../features/api/payment";
import { hasActiveMembership } from "../../../../utils/has-active-membership";
import { HoneydewAPI } from "../../../../services/honeydew-api";
import { pubnubClient } from "../../../../services/pubnub";
import {
  useGetAllEmployeesQuery,
  useGetEmployeeQuery,
  useGetEmployeesByRoleQuery,
  useReplaceCareTeamMemberIfOnLeaveMutation,
} from "../../../../features/api/employees";
import { USER_ROLES } from "../../../../types/Main";
import { pubnubChatCount } from "../../../../utils/patient/pubnubChatCount";
import { useSendNotificationMutation } from "../../../../features/api/notifications";
import { REAL_TIME_TYPES } from "../../../../utils/real-time";

interface Props {
  onClose: () => void;
}

enum STATUSES {
  NO_MEMBESHIP,
  NO_CHAT,
  LOADING,
  ERROR,
  IDLE,
  SENDING,
}

// function getChatUserRole(userRole: string) {
//   switch (userRole) {
//     case "patients":
//       return "user";
//     case "providers":
//       return "provider";
//     case "care-coordinators":
//       return "care-coordinator";
//     case "admins":
//       return "admin";
//     default:
//       return null;
//   }
// }

const paginators = new WeakMap<Conversation, Paginator<Message>>();

// async function getMessages(conversation: Conversation) {
//   return await getAllTwilioElements(conversation.getMessages(100));
// }

export function ChatComponent() {
  const { showSuccess } = useContext(NotificationLayerContext);
  const dispatch = useDispatch();

  // const { initialized: contextInitialized } = useSelector(getTwilioState);
  const { showError } = useContext(NotificationLayerContext);
  const [replaceCareTeamMemberIfOnLeave] =
    useReplaceCareTeamMemberIfOnLeaveMutation();
  const [updateChatData] = useUpdateChatDataMutation();
  const { userRole, activePatientId, userId, newMsgsCount } =
    useSelector(getSessionState);
  const [triggerNotification, { isLoading, isError, error }] =
    useSendNotificationMutation();
  const [pubnub, setPubNub] = useState<PubNub | null>(null);
  const patientQuery = useGetPatientByIdQuery(activePatientId as string, {
    skip: !activePatientId,
  });
  const subscriptionsQuery = useGetSubscriptionsByPatientIdQuery(
    activePatientId as string,
    {
      skip: !activePatientId,
    }
  );
  const { refetch } = useGetCareTeamQuery(activePatientId as string, {
    skip: !activePatientId,
  });

  const patientsByAccountId = useGetPatientsByAccountIdQuery(
    patientQuery.data?.accountId
  );

  const [conversation, setConversation] = useState<Conversation>();
  const [message, setMessage] = useState("");
  const [messagesList, setMessagesList] = useState<Message[]>([]);
  const [status, setStatus] = useState(STATUSES.LOADING);
  const [prevMessagesLoading, setPrevMessagesLoading] = useState(false);
  const [allMessagesLoaded, setAllMessagesLoaded] = useState(false);
  const messagesListRef = useRef(messagesList);
  const [messages, setMessages] = useState([]);
  const [msgToDeleteId, setMsgToDeleteId] = useState("");
  const [pubnubToken, setPubnubToken] = useState(null);
  const [updateMetaData, setUpdateMetaData] = useState(false);
  const [pubNubSubscribeKey, setPubNubSubscribeKey] = useState(null);

  const allEmployeesQuery = useGetAllEmployeesQuery(null);

  const adminEmployeeIds =
    allEmployeesQuery?.data &&
    allEmployeesQuery.data
      .filter((employee) => employee.role === USER_ROLES.ADMINISTRATOR)
      .map((employee) => employee.id);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const subscribeKey = await HoneydewAPI.chats.pubNubSubscribeKey();
        setPubNubSubscribeKey(subscribeKey);
      } catch (_error) {
        console.error("Error fetching PubNub token:", _error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (patientsByAccountId?.data) {
      const patientIds =
        patientsByAccountId?.data?.map((patient) => patient.patientId) || [];
      const fetchData = async () => {
        try {
          const token = await HoneydewAPI.chats.getTokenGenerated(
            patientIds,
            userId
          );
          setPubnubToken(token);
        } catch (_error) {
          console.error("Error fetching PubNub token:", _error);
        }
      };

      fetchData();
    }
  }, [patientsByAccountId?.data, userId]);

  useEffect(() => {
    if (pubnubToken && pubNubSubscribeKey && patientQuery.data?.patientId) {
      const pubnubInstance = pubnubClient({
        userId: patientQuery.data?.patientId,
        pubNubSubscribeKey,
      });
      pubnubInstance.setToken(pubnubToken);
      setPubNub(pubnubInstance);
    }
  }, [pubnubToken, pubNubSubscribeKey, patientQuery.data?.patientId]);

  // const [messages, setMessages] = useState([]);
  const [lastMessageTimestamp, setLastMessageTimestamp] = useState(null);

  async function fetchChannelMessages(channel, start) {
    const response = await pubnub.fetchMessages({
      channels: [channel],
      count: 20,
      includeMessageActions: true,
      start, // Use the start parameter to fetch the next batch
    });

    return response;
  }

  // Function to fetch messages for multiple patients
  async function fetchMessagesForPatients(patientProf, start) {
    let allChannelsResponse = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const patient of patientProf) {
      // eslint-disable-next-line no-await-in-loop
      const pubnubResponse = await fetchChannelMessages(
        patient.patientId,
        start
      );
      allChannelsResponse = {
        ...allChannelsResponse,
        ...pubnubResponse.channels,
      };
    }

    let mergedMsgs = [];
    Object.values(allChannelsResponse).forEach((patientChatMessages) => {
      mergedMsgs = mergedMsgs.concat(patientChatMessages);
    });

    const sortedMsgs = mergedMsgs.sort(
      (a, b) => new Date(a.message.timestamp) - new Date(b.message.timestamp)
    );

    const latestMsgs = sortedMsgs.slice(-20);

    return latestMsgs;
  }

  // Main function to fetch messages
  async function fetchMessages(channel, start) {
    const response = await fetchChannelMessages(channel, start);

    if (!start) {
      pubnubChatCount(
        pubNubSubscribeKey,
        pubnubToken,
        patientQuery.data?.accountId,
        userId,
        channel,
        userRole,
        dispatch,
        newMsgsCount
      );
    }

    await updateChatData({
      patientId: activePatientId,
      isUnread: false,
      userIds: [userId],
    });

    if (patientsByAccountId?.data && patientsByAccountId.data.length > 1) {
      const sortedMsgs = await fetchMessagesForPatients(
        patientsByAccountId.data,
        start
      );

      const finalResponse = {
        [activePatientId]: sortedMsgs,
      };
      if (Object.keys(finalResponse[activePatientId]).length === 0) {
        // setMessages([]);
        setStatus(STATUSES.IDLE);
        setAllMessagesLoaded(true);
        return false;
      }

      return {
        channels: finalResponse || {},
        start: response.start, // Update start for pagination
      };
    }

    if (Object.keys(response.channels).length === 0) {
      // setMessages([]);
      setStatus(STATUSES.IDLE);
      setAllMessagesLoaded(true);
      return false;
    }

    return {
      channels: response.channels || {},
      start: response.start, // Update start for pagination
    };
  }

  function formatMessages(chatMessages) {
    return chatMessages.map((msg) => ({
      text: msg.message.text,
      author: msg.message.author,
      timestamp: new Date(msg.timetoken / 10000).toISOString(),
      messageId: msg.timetoken,
      isDeleted: msg?.actions?.deleted,
      attributes: msg?.message?.attributes,
    }));
  }

  async function fetchHistory(patientId) {
    let allMessages = [];

    // Define a recursive function to fetch messages
    const fetchMessagesRecursive = async (start) => {
      const response = await fetchMessages(patientId, start); // Fetch messages

      if (response) {
        // eslint-disable-next-line no-restricted-syntax
        for (const channel of Object.keys(response.channels)) {
          // eslint-disable-next-line no-restricted-syntax
          const channelMessages = response.channels[channel];
          const formattedMessages = formatMessages(channelMessages);
          if (formattedMessages.length < 20) {
            setAllMessagesLoaded(true);
          }
          const updatedHistory = formattedMessages.filter(
            (formattedMessage) => formattedMessage.isDeleted === undefined
          );
          allMessages = allMessages.concat(updatedHistory);
        }

        if (response.start) {
          await fetchMessagesRecursive(response.start); // Recursively fetch messages for the next batch
        }
      }
    };

    await fetchMessagesRecursive(); // Start fetching messages recursively

    return allMessages;
  }

  async function fetchInitialMessages(patientId) {
    const messageHistory = await fetchHistory(patientId);
    setStatus(STATUSES.IDLE);
    setMessages(messageHistory);
    if (messageHistory.length > 0) {
      const lastMessage = messageHistory[0];
      setLastMessageTimestamp(lastMessage.messageId);
    }

    return messageHistory;
  }

  // const [allMessagesCount, setAllMessagesCount] = useState(0);

  // useEffect(() => {
  //   (async () => {
  //     if (pubnubToken && pubnub) {
  //       await pubnub.messageCounts(
  //         {
  //           channels: [activePatientId],
  //           channelTimetokens: ["0"],
  //         },
  //         (countStatus, results) => {
  //           console.log(countStatus);
  //           setAllMessagesCount(results.channels[activePatientId]);
  //         }
  //       );
  //     }
  //   })();
  // }, [pubnub]);

  async function fetchMoreMessages() {
    try {
      const limit = 20; // Number of messages to fetch per request
      const newMessages = [];
      let nextPage = lastMessageTimestamp;

      const fetchMessagesRecursive = async () => {
        const response = await fetchMessages(activePatientId, nextPage);
        const channelMessages = response.channels[activePatientId];
        const formattedMessages = formatMessages(channelMessages);

        // Filter out duplicate messages
        const filteredMessages = formattedMessages.filter(
          (chatMessage) =>
            !messages.some(
              (existingMessage) =>
                existingMessage.messageId === chatMessage.messageId
            )
        );

        if (filteredMessages.length === 0) {
          return;
        }

        newMessages.push(...filteredMessages);

        // Update nextPage to fetch the next chunk of messages
        if (response.channels && Object.keys(response.channels).length > 0) {
          const lastChannel = Object.keys(response.channels).pop();
          const lastMessage = response.channels[lastChannel][0];
          if (lastMessage) {
            nextPage = lastMessage.timetoken;
          }
        }

        // Fetch next chunk of messages if needed
        if (filteredMessages.length >= limit) {
          await fetchMessagesRecursive();
        }
      };

      await fetchMessagesRecursive();

      if (newMessages.length > 0) {
        // New messages fetched, update state
        setMessages((prevMessages) => {
          // Combine new messages with existing messages and remove duplicates
          const uniqueMessages = [
            ...newMessages,
            ...prevMessages.filter(
              (existingMessage) =>
                !newMessages.some(
                  (newMessage) =>
                    newMessage.messageId === existingMessage.messageId
                )
            ),
          ];
          // Sort messages by messageId
          uniqueMessages.sort((a, b) => a.messageId - b.messageId);
          return uniqueMessages;
        });
      }

      setAllMessagesLoaded(true);
    } catch (_error) {
      console.error("Error fetching more messages:", _error);
      throw _error;
    }
  }

  const sendRealTimeEvent = async (accountIds: string) => {
    try {
      const result = await triggerNotification({
        recipientIds: accountIds,
        message: "New message",
        type: REAL_TIME_TYPES.NEW_CHAT_MESSAGE,
      }).unwrap();
      console.log("Notification sent:", result);
    } catch (err) {
      console.error("Error sending notification:", err);
    }
  };

  const sendChatNotification = async (role) => {
    if (patientQuery.data?.accountId) {
      try {
        const token = await HoneydewAPI.chats.getPubnubMessage(
          patientQuery.data?.accountId,
          role
        );
      } catch (_error) {
        console.error("Error fetching PubNub token:", _error);
      }
    }
  };

  async function send(twilioMessage) {
    // event.preventDefault();
    // if (status === STATUSES.NO_MEMBESHIP) return;
    // const _message = message.trim();
    // if (!_message || !conversation || !userRole) return;

    // setStatus(STATUSES.SENDING);
    try {
      await conversation.sendMessage(twilioMessage);
      // await conversation.setAllMessagesRead();
      // setMessage("");
    } catch (e) {
      logError("Error during chat message sending", e);
    }
  }

  const updateChat = async (
    currentUserRole: string,
    allCareCoordinators: string[]
  ) => {
    let userIds: any[] = [];

    if (
      currentUserRole === "patients" ||
      currentUserRole === "admins" ||
      currentUserRole === "providers"
    ) {
      const allCoordinatorsId = allCareCoordinators?.map((obj) => obj?.id);
      userIds = [...allCoordinatorsId, ...adminEmployeeIds];
    } else if (currentUserRole === "care-coordinators") {
      userIds = [...adminEmployeeIds];
    }
    await updateChatData({
      patientId: activePatientId,
      isUnread: true,
      userIds,
    });
  };

  const refreshPubNubTokenAndRetry = async (retryCallback) => {
    const patientIds =
      patientsByAccountId?.data?.map((patient) => patient.patientId) || [];
    try {
      const token = await HoneydewAPI.chats.getTokenGenerated(
        patientIds,
        userId
      );
      if (token) {
        setPubnubToken(token);
        pubnub.setToken(token); // Set the new token for PubNub client
        retryCallback(true); // Retry sending the message
      }
    } catch (_error) {
      logError("Failed to refresh PubNub token", _error);
    }
  };

  async function sendMessage(event: FormEvent) {
    event.preventDefault();
    if (status === STATUSES.NO_MEMBESHIP) return;
    const _message = message.trim();
    const timestamp = new Date().toISOString();
    if (!_message || !pubnub || !userRole) return;
    const currentMsg = {
      text: _message,
      author: userRole === "patients" ? activePatientId : userId,
      timestamp,
    };
    setStatus(STATUSES.SENDING);
    const sendMessageWithRetry = async (retry = false) => {
      try {
        // Publish the message using PubNub
        await pubnub?.publish({
          channel: activePatientId, // Use a unique channel per patient or conversation
          message: {
            text: _message,
            author: userRole === "patients" ? activePatientId : userId,
            timestamp,
            messageId: msgToDeleteId,
            // Add other message properties as needed
          },
        });
        setMessage("");
        let existingMetadata;
        try {
          existingMetadata = await pubnub.objects.getChannelMetadata({
            channel: activePatientId,
          });
        } catch (_error) {
          if (_error?.status?.statusCode === 404) {
            // Handle the case where channel metadata does not exist (404)
            existingMetadata = { data: {} }; // Initialize with empty metadata
          } else {
            throw _error; // Rethrow other errors
          }
        }
        const careTeam = await refetch();
        const allCareCoordinators = careTeam?.data?.filter(
          (obj) => obj.role === "care-coordinator"
        );
        const existingCustom = existingMetadata?.data?.custom || {};

        let newCustom = {};
        if (
          userRole === "patients" ||
          userRole === "admins" ||
          userRole === "providers"
        ) {
          const primaryCareCoordinator = allCareCoordinators?.find(
            (record) => record.isRemovable !== true
          );
          let allCoordinatorsId = allCareCoordinators
            ?.filter(
              (obj) => obj.employeeId !== primaryCareCoordinator?.employeeId
            )
            .map((obj) => obj.employeeId);

          if (primaryCareCoordinator?.employeeId) {
            const updatedPrimaryCC = await replaceCareTeamMemberIfOnLeave({
              employeeId: primaryCareCoordinator.employeeId,
              patientId: activePatientId,
            });

            if (updatedPrimaryCC?.data?.id) {
              allCoordinatorsId = [
                ...allCoordinatorsId,
                updatedPrimaryCC.data.id,
              ];
            }
          }
          console.log("allCoordinatorsId", allCoordinatorsId);

          const currentTimestamp = new Date().toISOString();
          newCustom = {
            ...existingCustom,
            ...allCoordinatorsId.reduce((acc, employeeId) => {
              const existingData = existingCustom[employeeId]
                ? JSON.parse(existingCustom[employeeId])
                : null;
              acc[employeeId] = JSON.stringify({
                timestamp: existingData?.isUnread
                  ? existingData.timestamp
                  : currentTimestamp,
                isUnread: true, // Always set isUnread to true
              });

              return acc;
            }, {}),
            ...adminEmployeeIds.reduce((acc, id) => {
              const existingData = existingCustom[id]
                ? JSON.parse(existingCustom[id])
                : null;
              acc[id] = JSON.stringify({
                timestamp: existingData?.isUnread
                  ? existingData.timestamp
                  : currentTimestamp,
                isUnread: true, // Always set isUnread to true
              });

              return acc;
            }, {}),
          };
          sendRealTimeEvent(allCoordinatorsId);
        } else if (userRole === "care-coordinators") {
          const currentTimestamp = new Date().toISOString();

          // Serialize data into a string
          newCustom = {
            ...existingCustom,
            ...adminEmployeeIds.reduce((acc, id) => {
              const existingData = existingCustom[id]
                ? JSON.parse(existingCustom[id])
                : null;

              acc[id] = JSON.stringify({
                timestamp: existingData?.isUnread
                  ? existingData.timestamp
                  : currentTimestamp,
                isUnread: true, // Always set isUnread to true
              });
              return acc;
            }, {}),
          };
        }
        sendChatNotification(
          userRole === "patients" ? "patient" : "care-coordinator"
        );
        await pubnub.objects.setChannelMetadata({
          channel: activePatientId,
          data: {
            ...existingMetadata.data,
            custom: newCustom,
          },
        });
        setUpdateMetaData(true);
        updateChat(userRole, allCareCoordinators);
      } catch (e) {
        if (
          e?.status &&
          e?.status?.errorData &&
          e.status.errorData?.status === 403 &&
          e.status.errorData?.message === "Token is expired." &&
          !retry
        ) {
          logError("Token expired. Refreshing token...");
          // Call the token refresh logic and retry
          await refreshPubNubTokenAndRetry(sendMessageWithRetry);
        } else {
          logError("Error during chat message sending", e);
        }
      } finally {
        setStatus(STATUSES.IDLE);
      }
    };

    sendMessageWithRetry();
  }

  useEffect(() => {
    (async () => {
      if (pubnub && updateMetaData) {
        try {
          await pubnub.subscribe({
            channels: [activePatientId],
            withPresence: true,
          });
          const messageListener = {
            message(event) {
              // Handle incoming message
              const messageId = event?.timetoken;
              // setMsgToDeleteId(messageId);
              setMessages((prevMessages) => [
                ...prevMessages,
                { ...event.message, messageId },
              ]);
              setAllMessagesLoaded(true);
            },
            messageAction(e) {
              const id = e?.data.messageTimetoken;
              setMessages((prevMessages) =>
                prevMessages.filter(
                  (prevMessage) => prevMessage.messageId !== id
                )
              );
            },
          };
        } catch (_error) {
          console.error("Error subscribing to channel:", _error);
        }
      }
    })();
  }, [pubnub, updateMetaData]);

  useEffect(() => {
    let isSubscribed = false; // Flag to track subscription status
    (async () => {
      if (
        subscriptionsQuery.isSuccess &&
        !hasActiveMembership(subscriptionsQuery.data)
      ) {
        if (userRole === "patients") {
          setStatus(STATUSES.NO_MEMBESHIP);
          return;
        }
      }

      if (!patientQuery.isSuccess) {
        setStatus(STATUSES.LOADING);
        return;
      }

      const messageListener = {
        message(event) {
          // Handle incoming message
          const messageId = event?.timetoken;
          // setMsgToDeleteId(messageId);
          setMessages((prevMessages) => [
            ...prevMessages,
            { ...event.message, messageId },
          ]);
          setAllMessagesLoaded(true);
        },
        messageAction(e) {
          const id = e?.data.messageTimetoken;
          setMessages((prevMessages) =>
            prevMessages.filter((prevMessage) => prevMessage.messageId !== id)
          );
          // setMessages(updatedMessagesList);
        },
      };

      const subscribeToConversation = async () => {
        if (pubnub && !isSubscribed && messages && messages.length === 0) {
          isSubscribed = true;
          await pubnub.subscribe({
            channels: [activePatientId],
            withPresence: true,
          });
          pubnub.addListener(messageListener);
          try {
            // await fetchHistoryPromise;
            const messageHistory = await fetchInitialMessages(activePatientId);
            setMessages(messageHistory);
            if (!messageHistory || messageHistory.length === 0) {
              if (
                subscriptionsQuery.isSuccess &&
                !hasActiveMembership(subscriptionsQuery.data)
              ) {
                setStatus(STATUSES.NO_CHAT);
                return;
              }
              // setAllMessagesLoaded(true);
              setMessages([]);
              setStatus(STATUSES.IDLE);
            }
          } catch (_error) {
            console.error("Error fetching message history:", _error);
          }
        }
      };

      subscribeToConversation();
    })();
  }, [pubnub, patientQuery, subscriptionsQuery, activePatientId]);

  const deleteMessage = async (msgId: any) => {
    try {
      const messageActionResponse = await pubnub.addMessageAction({
        channel: activePatientId,
        messageTimetoken: msgId,
        action: {
          type: "deleted",
          value: ".",
        },
      });

      if (messageActionResponse.data) {
        showSuccess({
          title: "Success",
          description: "Message was successfully deleted",
        });
        const { messageTimetoken } = messageActionResponse.data;
        // updateMessageList(messageTimetoken);
      }
    } catch (_error) {
      console.error("Error soft deleting message:", _error);
    }
  };

  const onMessageDelete = async (msgId: string) => {
    const deleteMsgResponse = await deleteMessage(msgId);
  };

  const updateMessageList = (deletedMessageTimetoken) => {
    const updatedMessagesList = messages.filter(
      (msg) => msg.messageId !== deletedMessageTimetoken
    );
    setMessages(updatedMessagesList);
  };

  function renderBody() {
    switch (status) {
      case STATUSES.NO_MEMBESHIP:
        return (
          <div className="chat-popup__no-membership">
            <LockIcon className="chat-popup__no-membership-icon" />
            <p className="chat-popup__no-membership-title">
              You don't have subscription
            </p>
            <p className="chat-popup__no-membership-descr">
              Buy a subscription to obtain full access to Honeydew features
            </p>
          </div>
        );
      case STATUSES.NO_CHAT:
        return (
          <div className="chat-popup__no-membership">
            <CloseIcon className="chat-popup__no-membership-icon" />
            <p className="chat-popup__no-membership-title">
              Chat is not created yet
            </p>
            <p className="chat-popup__no-membership-descr">
              The chat will be created once the membership is activated
            </p>
          </div>
        );
      case STATUSES.IDLE:
      case STATUSES.SENDING:
        return messages ? (
          <>
            {messages?.length > 0 && activePatientId ? (
              <ChatChannel
                messages={messages}
                conversation={conversation}
                onMessageDelete={onMessageDelete}
                patientId={activePatientId}
              />
            ) : null}

            <div className="chat-popup__loader-wrap">
              {allMessagesLoaded && activePatientId ? (
                <>
                  <ConfirmSign className="chat-popup__loader" />
                  <p className="chat-popup__loader-text paragraph-font paragraph-font--color">
                    All messages loaded
                  </p>
                </>
              ) : (
                <Loader className="chat-popup__loader" />
              )}
            </div>
          </>
        ) : null;
      case STATUSES.LOADING:
        return (
          <div className="chat-popup__loader">
            <Loader />
          </div>
        );
      case STATUSES.ERROR:
        return (
          <div className="chat-popup__no-membership">
            <CloseIcon className="chat-popup__no-membership-icon" />
            <p className="chat-popup__no-membership-title">
              Something went wrong...
            </p>
            <p className="chat-popup__no-membership-descr">
              We cannot load your chat for some reason. Please, try to reload
              the application. If this issue persists, then please contact us.
            </p>
          </div>
        );
      default:
        return null;
    }
  }

  async function loadMessages(initial = false) {
    try {
      // setPrevMessagesLoading(true);
      const newMessages = initial
        ? await fetchInitialMessages()
        : await fetchMoreMessages();
      if (newMessages.length === 0) {
        setAllMessagesLoaded(true);
      } else {
        setMessagesList((prevMessages) => [...prevMessages, ...newMessages]);
      }
      setPrevMessagesLoading(false);
    } catch (_error) {
      // Handle errors
      console.error("Error loading messages:", _error);
    }
  }

  // useEffect(() => {
  //   if (conversation) {
  //     (async () => {
  //       await loadMessages(conversation, true);
  //       setStatus(STATUSES.IDLE);
  //       conversation.on("messageAdded", (_message) => {
  //         const updatedMessages = [...messagesListRef.current, _message];
  //         messagesListRef.current = updatedMessages;
  //         setMessagesList(updatedMessages);
  //       });
  //     })();
  //   }

  //   return function cleanup() {
  //     conversation?.removeAllListeners("messageAdded");
  //   };
  // }, [conversation]);

  // useEffect(() => {
  //   (async () => {
  //     // if (userRole === "patients") {
  //     //   if (
  //     //     subscriptionsQuery.isSuccess &&
  //     //     !hasActiveMembership(subscriptionsQuery.data)
  //     //   ) {
  //     //     setStatus(STATUSES.NO_MEMBESHIP);
  //     //     return;
  //     //   }
  //     // }
  //     // if (status === STATUSES.NO_MEMBESHIP) return;
  //     // if (!contextInitialized || !patientQuery.isSuccess) {
  //     //   setStatus(STATUSES.LOADING);
  //     //   return;
  //     // }

  //     const conversations = TwilioController.getConversations();
  //     const _conversation = conversations?.[patientQuery.data.accountId];
  //     setConversation(_conversation);
  //   })();
  // }, [contextInitialized, patientQuery]);

  return (
    <div className="chat-popup">
      <div
        className="chat-popup__body"
        onScrollCapture={(event) => {
          if (
            !prevMessagesLoading &&
            !allMessagesLoaded &&
            event.currentTarget.scrollHeight +
              event.currentTarget.scrollTop -
              event.currentTarget.clientHeight -
              100 <
              0
          ) {
            loadMessages();
          }
        }}
      >
        {renderBody()}
      </div>
      <form className="chat-popup__input-area" onSubmit={sendMessage}>
        <TextareaAutosize
          className="chat-popup__textarea text-input__field"
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Your message..."
          disabled={status !== STATUSES.IDLE}
          maxRows={5}
          value={message}
        />
        <SubmitButton
          text="send"
          size="small"
          status={
            status !== STATUSES.IDLE || !message.trim() ? "disabled" : undefined
          }
        />
      </form>
    </div>
  );
}

export function ChatPopupGenerator() {
  return function render({ onClose }: Props) {
    return (
      <ChatPopupTemplate title="Care Team Chat" onClose={onClose}>
        <ChatComponent />
      </ChatPopupTemplate>
    );
  };
}
