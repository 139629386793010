import React, { useContext, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";
import { Button } from "../../Common/Button";
import { Props } from "../FormsOfPregnancyPopup/types";
import { ActionPopupTemplate } from "../../../Components/Common/ActionPopup";
import { Steps, Step } from "../../Common/Steps";
import { Accutane } from "../../../../types/Entities/Accutane";
import { getMomentDate } from "../../../../utils/get-date-pretty";
import { NotificationLayerContext } from "../../Common/NotificationLayer";
import { AccutaneDocument } from "../../../../services/acneaway-api/entities/accutane/get-documents";
import { ReactComponent as DocumentIcon } from "../../../../Assets/NewIcons/document.svg";
import { ReactComponent as InfoIcon } from "../../../../Assets/NewIcons/info.svg";
import { ReactComponent as MessageIcon } from "../../../../Assets/NewIcons/message.svg";
import { ReactComponent as DownloadIcon } from "../../../../Assets/NewIcons/download-cloud.svg";
import { downloadDocument } from "../../../../utils/download-document";
import "./style.scss";
import { getSessionState } from "../../../../features/session";
import { useGetPatientByIdQuery } from "../../../../features/api/patients";
import { useAddBloodWorkMutation } from "../../../../features/api/accutane";
import { BloodWorkForm } from "./BloodWorkForm";
import { useFeatureToggle } from "../../../../contexts/feature-toggle";
import { FEATURES } from "../../../../data/available-features";
import { useBloodWorkStepper } from "../../../../contexts/blood-work";
import { useHotReload } from "../../../../contexts/hot-reload";

const dateFormat = "MMMM Do, YYYY"; // September 29th, 2022

const textByConfirmationDate = (accutane: Accutane | null) => {
  if (accutane && accutane.nextConfirmationDate) {
    const formattedDate = getMomentDate(accutane.nextConfirmationDate).format(
      dateFormat
    );
    return `Your next confirmation date is ${formattedDate}. Each month, please get your blood
    work done 3-5 days before to leave enough time for your results to come
    in and be reviewed by your clinician.`;
  }

  return `Each month, please get your blood
    work done 3-5 days before your next confirmation date. This will leave enough time for your results to come
    in and be reviewed by your clinician.`;
};

function BloodWorkDocument({ document }: { document: AccutaneDocument }) {
  const documentTitle = document?.title ?? "Lab work document";
  const onDownloadClick = downloadDocument(document.url, documentTitle);

  return (
    <div className="blood-work-document">
      <p className="blood-work-document__title">
        <DocumentIcon />
        <span>{documentTitle}</span>
      </p>
      <div className="blood-work-document__icons">
        <MessageIcon className="blood-work-document__icons__message" />
        <DownloadIcon
          className="blood-work-document__icons__download"
          onClick={onDownloadClick}
        />
      </div>
    </div>
  );
}

export function BloodWorkPopup({
  onClose,
  onClick,
}: {
  onClose: () => void;
  onClick: any;
}) {
  return (
    <div className="blood-work-popup">
      <p className="blood-work-popup__description">
        <p className="blood-work-popup-description__heading">
          <div>
            <InfoIcon />
          </div>
          <div>Here is when to expect bloodwork:</div>
        </p>
        <ul className="blood-work-popup-description__list">
          <li>Before starting treatment (baseline) </li>
          <li>At the 2-month mark</li>
          <li>One month after finishing treatment</li>
        </ul>
        <p className="blood-work-popup-description__subheading">
          {" "}
          You and/or your clinician may request additional bloodwork.
        </p>
      </p>
      <Steps className="blood-work-popup__steps-wrapper">
        <Step title="Download the blood slip" isActive>
          <p className="blood-work-popup__steps-wrapper__step-description">
            You can find your lab slip in your Documents tab.
          </p>
        </Step>
        <Step
          title="Visit your local lab and complete your blood test"
          isActive
        >
          <p className="blood-work-popup__steps-wrapper__step-description">
            If you need lab suggestions, try a LabCorp or Quest. If you’re
            paying with cash, try JasonHealth.com for better pricing.
          </p>
        </Step>
        <Step
          title="Once done, confirm that you completed your blood test"
          isActive
        >
          <div className="blood-work-popup__steps-wrapper__confirm-step">
            <p className="blood-work-popup__steps-wrapper__step-description">
              You don’t need to send us the results. The lab will send them to
              us.
            </p>
            <Button
              text="blood test complete"
              size="small"
              className="blood-work-popup__steps-wrapper__confirm-step__button"
              onClick={onClick}
            />
          </div>
        </Step>
        <Step title="Your lab will send us your test results " />
        <Step
          title="Your provider will review the results and confirm if you can safely continue 
the medication"
        />
      </Steps>
      <div className="blood-work-popup__confirmation-button-wrapper">
        <Button
          text="Blood test complete"
          size="small"
          className="blood-work-popup__confirmation-button-wrapper__confirmation-button"
          onClick={onClick}
        />
      </div>
    </div>
  );
}

export function BloodWorkPopupGenerator(userId: string, dispatch: Dispatch) {
  return function BloodWorkPopupRenderer({ onClose }: Props) {
    const { isFeatureEnabled } = useFeatureToggle();
    const enabled = useMemo(() => isFeatureEnabled(FEATURES.VITAL), []);
    const { cleanup } = useBloodWorkStepper();
    const { showSuccess, showError } = useContext(NotificationLayerContext);
    const { activePatientId } = useSelector(getSessionState);
    const { setShouldReload } = useHotReload();
    const patientQuery = useGetPatientByIdQuery(activePatientId as string);
    const [addBloodWork] = useAddBloodWorkMutation();

    const onClick = async () => {
      if (patientQuery.data) {
        const result: any = await addBloodWork(patientQuery.data.patientId);
        if (result.error) {
          showError({
            title: "Something went wrong...",
            description: "Unable to mark the blood test as complete",
          });
          return;
        }

        showSuccess({
          title: "You’ve successfuly marked the blood test as complete!",
          description: "This task is done now.",
        });
      }
      onClose();
    };

    const handleClose = () => {
      cleanup();
      setShouldReload(true);
      onClose();
    };

    return enabled ? (
      <ActionPopupTemplate title="Get your test done" onClose={handleClose}>
        <BloodWorkForm onClose={handleClose} />
      </ActionPopupTemplate>
    ) : (
      <ActionPopupTemplate
        title="Get your test done"
        onClose={onClose}
        extra={
          <Button
            text="blood test complete"
            size="small"
            className="blood-work-popup__steps-wrapper__confirm-step__button margin-0"
            onClick={onClick}
          />
        }
      >
        <BloodWorkPopup onClose={onClose} onClick={onClick} />
      </ActionPopupTemplate>
    );
  };
}
