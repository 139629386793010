/* eslint-disable */
import React, { useEffect, useState } from "react";
import moment from "moment-timezone";
import MenuLogoIcon from "../../../Assets/NewIcons/logo-icon.png";
import PhoneIcon from "../../../Assets/NewIcons/phone.png";
import EmailIcon from "../../../Assets/NewIcons/email.png";
import "./style.scss";
import capitalizeFirstLetter from "../../../utils/capitalizeFirstLetter";
import { getMomentDate } from "../../../utils/get-date-pretty";
import { ENV_CONFIG } from "../../../config";

import {
  Document,
  Page,
  View,
  Text,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";
import { ConsoleWarnings } from "console-warnings";
import sunIcon from "../../../Assets/NewIcons/sun.png";
import moonIcon from "../../../Assets/NewIcons/moon.png";
import { getFullType } from "../../../utils/get-full-type";
import EmployeeInfo from "./EmployeeInfo";

const PrintPatientInfoPage = ({
  patientQuery,
  followUpsQuery,
  QuestionsSection,
  preparedMedicineMap,
  employeeQuery,
  descriptions,
  acneFeelingText,
  initialVisitPrescriptions,
}) => {
  const isInitialVisit = (followUpsQuery?.data?.length || 0) === 1;
  const [contentArray, setContentArray] = useState([]);

  useEffect(() => {
    const newContentArray = initialVisitPrescriptions.map((prescriptions) =>
      prescriptions.map((prescription) => prescription?.content)
    );
    setContentArray(newContentArray);
  }, []);

  return (
    <Document>
      <Page size="A4" style={styles.body}>
        <View>
          <Image style={{ height: 60, width: 60 }} src={MenuLogoIcon} />
        </View>

        <Text style={{ ...styles.patientInfoHeading, fontSize: 26 }}>
          Patient's information
        </Text>
        <Text style={{ ...styles.patientNameText, fontSize: 16 }}>
          {patientQuery?.data?.fullName}
        </Text>
        <View style={styles.personalInfoContainer}>
          <View style={styles.personalInfoIndividualElement}>
            <Text style={styles.marginRightofTen}>
              {capitalizeFirstLetter(
                patientQuery?.data?.medicalBackground?.sex || null
              )}
            </Text>
            <Text style={styles.marginRightofTen}>
              Age {moment().diff(patientQuery?.data?.dateOfBirth, "years")}
            </Text>
            <Text style={styles.marginRightofTen}>
              {patientQuery?.data?.dateOfBirth}
            </Text>
          </View>

          <View style={styles.personalInfoIndividualElement}>
            <Image style={styles.iconImage} src={PhoneIcon} />
            <Text>{patientQuery?.data?.phone}</Text>
          </View>

          <View style={styles.personalInfoIndividualElement}>
            <Text style={styles.marginRightofTen}>
              H:{" "}
              {patientQuery?.data?.medicalBackground
                ? (patientQuery?.data?.medicalBackground.height -
                    (patientQuery?.data?.medicalBackground.height % 12)) /
                  12
                : "-"}
              '
              {patientQuery?.data?.medicalBackground
                ? patientQuery?.data?.medicalBackground.height % 12
                : "-"}
              "
            </Text>
            <Text>
              W: {patientQuery?.data?.medicalBackground?.weight ?? "—"} lbs
            </Text>
          </View>

          <View style={styles.personalInfoIndividualElement}>
            <Image style={styles.iconImage} src={EmailIcon} />
            <Text>{patientQuery?.data?.email}</Text>
          </View>
        </View>
        <Text
          style={{
            ...styles.patientInfoHeading,
            marginTop: 40,
            marginBottom: 10,
            fontSize: 24,
          }}
        >
          Medical background
        </Text>
        <View>
          {QuestionsSection?.map((item, index) => (
            <View key={index}>
              <Text
                style={{
                  ...styles.patientInfoHeading,
                  fontSize: 18,
                  marginBottom: 10,
                }}
              >
                {capitalizeFirstLetter(item.title)}
              </Text>

              <View
                style={{
                  ...styles.personalInfoContainer,
                  gap: 5,
                  width: "100%",
                }}
              >
                {item.items.map((innerItem, innerIndex) => (
                  <View key={innerIndex} style={styles.individualQuestion}>
                    <Text style={{ ...styles.questionText, fontSize: 14 }}>
                      {innerItem.article === "Had acne for"
                        ? "How long have you had acne?"
                        : innerItem.article}
                    </Text>
                    <Text style={{ fontSize: 12 }}>
                      {innerItem.description === "N/A"
                        ? "I’m new to acne"
                        : innerItem.description}
                    </Text>
                  </View>
                ))}
              </View>
            </View>
          ))}
        </View>

        {followUpsQuery?.currentData?.map((data, index) => (
          <React.Fragment key={index}>
            <View>
              <Image style={{ height: 60, width: 60 }} src={MenuLogoIcon} />
            </View>
            <Text
              style={{
                ...styles.patientInfoHeading,
                marginTop: 40,
                marginBottom: 10,
                fontSize: 24,
              }}
            >
              Visit Details
            </Text>
            <View>
              <View>
                <Text
                  style={{
                    ...styles.patientInfoHeading,
                    fontSize: 16,
                  }}
                >
                  {getMomentDate(data && data?.timestamp).format(
                    "MMMM Do, YYYY"
                  )}
                </Text>
              </View>
              <View>
                <Text
                  style={{
                    ...styles.patientInfoHeading,
                    fontSize: 14,
                    marginBottom: 10,
                  }}
                >
                  {acneFeelingText[index]?.text}
                </Text>
              </View>

              <View
                style={{ ...styles.personalInfoContainer, marginBottom: 10 }}
              >
                {data &&
                  data?.images?.map((image, imgIndex) => (
                    <Image
                      key={imgIndex}
                      style={{ height: 150, width: 150 }}
                      src={`${ENV_CONFIG.AMAZON_S3_USER_IMAGES_DOMAIN}/${image?.fileName}`}
                    />
                  ))}
              </View>

              {descriptions &&
                descriptions[index] &&
                descriptions[index].index.length > 0 && (
                  <View style={{ marginBottom: 20 }}>
                    {descriptions && descriptions[index] && (
                      <React.Fragment>
                        <Text
                          style={{
                            ...styles.patientInfoHeading,
                            fontSize: 18,
                            marginBottom: 10,
                          }}
                        >
                          {isInitialVisit
                            ? "Submitted initial visit"
                            : "Submitted follow up"}
                        </Text>
                        <View
                          style={{
                            ...styles.personalInfoContainer,
                            gap: 5,
                            width: "100%",
                          }}
                        >
                          {descriptions[index].index.map((item, innerIndex) => (
                            <View
                              key={innerIndex}
                              style={styles.individualQuestion}
                            >
                              <Text
                                style={{ ...styles.questionText, fontSize: 14 }}
                              >
                                {item.question}
                              </Text>
                              {descriptions[index].descriptions[innerIndex] && (
                                <Text style={{ fontSize: 12 }}>
                                  {
                                    descriptions[index].descriptions[innerIndex]
                                      .description
                                  }
                                </Text>
                              )}
                            </View>
                          ))}
                        </View>
                      </React.Fragment>
                    )}
                  </View>
                )}
              <View
                style={
                  contentArray[index]?.some((content) => content)
                    ? {
                        marginTop: 30,
                        borderWidth: 1,
                        borderColor: "#eee",
                        padding: 20,
                        borderRadius: 20,
                      }
                    : {}
                }
              >
                {Array.isArray(initialVisitPrescriptions[index]) &&
                  initialVisitPrescriptions[index].map(
                    (prescription, presIndex) =>
                      prescription?.content && (
                        <View
                          key={presIndex}
                          style={styles.instructionInternalContainer}
                        >
                          <Text style={{ marginBottom: 10, fontSize: 14 }}>
                            {prescription.title} {prescription.strength}
                          </Text>
                          <Text style={{ fontSize: 12 }}>
                            {prescription?.content}
                          </Text>
                          <View
                            style={{
                              flexDirection: "row",
                              gap: 10,
                              marginTop: 20,
                              marginBottom: 10,
                            }}
                          >
                            {prescription.whenToUse.map((timeOfDay, idx) => (
                              <View key={idx}>
                                <View
                                  style={{
                                    ...styles.buttonStyling,
                                    backgroundColor:
                                      timeOfDay === "morning"
                                        ? "#fff7e6"
                                        : "#f0f5fe",
                                    fontSize: 14,
                                  }}
                                >
                                  <Image
                                    style={styles.iconImage}
                                    source={
                                      timeOfDay === "evening"
                                        ? moonIcon
                                        : sunIcon
                                    }
                                  />
                                  <Text>
                                    {capitalizeFirstLetter(timeOfDay)}
                                  </Text>
                                </View>
                              </View>
                            ))}
                          </View>
                        </View>
                      )
                  )}
              </View>

              <View>
                <Text style={{ ...styles.patientInfoHeading, fontSize: 24 }}>
                  Provider's response
                </Text>
                <View style={styles.providerContainer}>
                  <View style={styles.providerContent}>
                    <Text style={{ marginBottom: 10, fontSize: 16 }}>
                      Next Follow-up
                    </Text>
                    <View
                      style={{ alignItems: "center", justifyContent: "center" }}
                    >
                      <View
                        style={{
                          fontSize: 16,
                          color: "#60b257",
                          fontWeight: "bold",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Text style={{ marginLeft: -5 }}>
                          {moment
                            .utc(data && data?.treatmentPlan?.nextFollowUpDate)
                            .local()
                            .format("DD")}
                        </Text>
                        <Text
                          style={{ marginLeft: -5, textTransform: "uppercase" }}
                        >
                          {moment
                            .utc(data && data?.treatmentPlan?.nextFollowUpDate)
                            .local()
                            .format("MMMM")}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              </View>
            </View>
            <View>
              {data?.treatmentPlan && <EmployeeInfo followupId={data} />}
              <Text style={{ fontSize: 12 }}>
                {data?.treatmentPlan?.message}
              </Text>
            </View>

            {data?.treatmentPlan?.medications && (
              <View
                style={{
                  marginTop: 30,
                  borderWidth: 1,
                  borderColor: "#eee",
                  padding: 20,
                  borderRadius: 20,
                }}
              >
                {data?.treatmentPlan?.medications.map(
                  (medication, medIndex) => (
                    <View
                      style={styles.instructionInternalContainer}
                      key={medIndex}
                    >
                      <Text style={{ marginBottom: 10, fontSize: 14 }}>
                        {preparedMedicineMap[medication.medicineKey]?.label}
                      </Text>
                      <Text style={{ fontSize: 12 }}>
                        {medication.specialInstructions}
                      </Text>
                      <View
                        style={{
                          flexDirection: "row",
                          gap: 10,
                          marginTop: 20,
                          marginBottom: 10,
                        }}
                      >
                        {medication.instructions.map((instruction, idx) => (
                          <View key={idx}>
                            {instruction && (
                              <View
                                style={{
                                  ...styles.buttonStyling,
                                  backgroundColor:
                                    instruction === "morning"
                                      ? "#fff7e6"
                                      : "#f0f5fe",
                                  fontSize: 14,
                                }}
                              >
                                <Image
                                  style={styles.iconImage}
                                  src={
                                    instruction === "evening"
                                      ? moonIcon
                                      : sunIcon
                                  }
                                />
                                <Text>
                                  {capitalizeFirstLetter(instruction)}
                                </Text>
                              </View>
                            )}
                          </View>
                        ))}
                      </View>
                    </View>
                  )
                )}
              </View>
            )}
          </React.Fragment>
        ))}

        <Text style={styles.pageNumber} fixed>
          © 2024 AcneAway, Inc. All rights reserved.
        </Text>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    lineHeight: 1.5,
  },

  marginRightofTen: {
    marginRight: 10,
  },

  patientInfoHeading: {
    marginTop: 20,
    color: "#403e48",
    fontSize: 32,
    fontWeight: 500,
  },

  patientNameText: {
    marginTop: 30,
    marginBottom: 20,
    color: "#403e48",
    fontSize: 22,
    fontWeight: 600,
  },

  personalInfoContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  personalInfoIndividualElement: {
    display: "flex",
    flexDirection: "row",
    width: "50%",
    padding: 10,
    fontSize: 14,
    boxSizing: "border-box",
  },

  iconImage: {
    width: 20,
    height: 20,
    marginRight: 10,
  },

  individualQuestion: {
    width: "45%",
    marginBottom: 5,
    padding: 10,
    boxSizing: "border-box",
    flexDirection: "column",
  },

  questionText: {
    fontSize: 22,
    fontWeight: 500,
    marginBottom: 10,
  },

  providerContainer: {
    marginTop: 20,
    marginBottom: 30,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: 150,
    border: "1px solid #eee",
    borderRadius: 20,
    backgroundColor: "#fff",
  },

  providerContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    width: "100%",
    height: "100%",
  },

  instructionInternalContainer: {
    marginBottom: 20,
    borderBottom: "1px solid #eee",
    width: "100%",
  },

  buttonStyling: {
    width: 150,
    height: 50,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: 25,
    backgroundColor: "#f0f5fe",
    borderRadius: 30,
    boxSizing: "border-box",
  },

  pageNumber: {
    fontSize: 14,
    position: "absolute",
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
    padding: 15,
  },
});

export default PrintPatientInfoPage;
