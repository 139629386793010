import React, { useState, useEffect } from "react";
import TimePicker from "react-time-picker";
import moment from "moment-timezone";
import { ReactComponent as ClockIcon } from "../../../../Assets/NewIcons/time-off.svg";
import { TextInput } from "../TextInput";
import "./style.scss";
import OutsideClickHandler from "../../../Components/Common/OutsideClickHandler/OutsideClickHandler";

interface DateTimeSelectProps {
  placeholder: string;
  value?: string;
  onChange: (v: string) => void;
}

function DateTimeSelect({ placeholder, value, onChange }: DateTimeSelectProps) {
  const [isOpened, setOpened] = useState(false);
  const [time, setTime] = useState(value || "");

  const formatTime = (clockTime: string) => {
    if (!clockTime) return clockTime;
    const momentTime = moment(clockTime, ["HH:mm", "hh:mm A"]);
    return momentTime.format("hh:mm A");
  };

  useEffect(() => {
    if (value) {
      setTime(formatTime(value));
    }
  }, [value]);

  const toggleCalendarVisibility = () => setOpened(!isOpened);
  const toggleCalendarClose = () => setOpened(false);

  const handleTimeChange = (selectedTime: string) => {
    // Parse the selected time to ensure correct format for display and state update
    console.log("recevdTime", selectedTime);
    const formattedTime = formatTime(selectedTime);
    console.log("formattdTime", formattedTime);
    setTime(formattedTime);
    onChange(formattedTime);
  };

  const parseTimeForPicker = (timeToParse: string) => {
    if (!timeToParse) return timeToParse;
    const momentTime = moment(timeToParse, ["hh:mm A"]);
    return momentTime.format("HH:mm");
  };

  return (
    <div className="date-time-select-wrapper">
      {/* <OutsideClickHandler onClickOutside={toggleCalendarClose}>
        <>
          <TextInput
            id="date_time_select"
            name="date-time-select"
            readOnly
            type="text"
            value={time || placeholder}
            onChange={() => null}
            placeholder={placeholder}
            onBlur={toggleCalendarVisibility}
          />
          
          {isOpened && ( */}
      <div className="date-time-select-wrapper__picker">
        <TimePicker
          onChange={handleTimeChange}
          value={parseTimeForPicker(time)}
          clearIcon={false}
          disableClock
        />
        <ClockIcon
          className="date-time-select-wrapper__calendar-icon"
          onClick={toggleCalendarVisibility}
        />
      </div>
      {/* )}
        </>
      </OutsideClickHandler> */}
    </div>
  );
}

export default DateTimeSelect;
