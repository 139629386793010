import React from "react";
import Welcome from "./Welcome";
import InsuranceSelection from "./InsuranceSelection";
import FindLab from "./FindLab";
import PaymentDetails from "./Payment";
import ChangePayment from "./Payment/ChangePaymentMethod";
import AddNewCard from "./Payment/AddNewCard";
import Checkout from "./Checkout";
import { BloodWorkPopup } from ".";
import CheckoutInsurance from "./CheckoutInsurance";
import LabTips from "../LabTips";

export enum BloodWorkPopupSteps {
  WELCOME = 0,
  INSURANCE_SELECTION = 1,
  FIND_LAB = 2,
  PAYMENT_DETAILS = 3,
  PAYMENT_METHOD = 4,
  NEW_CARD = 5,
  CHECKOUT = 6,
  INSURANCE = 7,
  CHECKOUT_INSURANCE = 8,
  TIPS_FOR_LAB = 9,
}

export const renderStep = (step: BloodWorkPopupSteps) => {
  switch (step) {
    case BloodWorkPopupSteps.WELCOME:
      return <Welcome />;
    case BloodWorkPopupSteps.INSURANCE_SELECTION:
      return <InsuranceSelection />;
    case BloodWorkPopupSteps.INSURANCE:
      return <BloodWorkPopup />;
    case BloodWorkPopupSteps.FIND_LAB:
      return <FindLab />;
    case BloodWorkPopupSteps.PAYMENT_DETAILS:
      return <PaymentDetails />;
    case BloodWorkPopupSteps.PAYMENT_METHOD:
      return <ChangePayment />;
    case BloodWorkPopupSteps.NEW_CARD:
      return <AddNewCard />;
    case BloodWorkPopupSteps.CHECKOUT:
      return <Checkout />;
    case BloodWorkPopupSteps.CHECKOUT_INSURANCE:
      return <CheckoutInsurance />;
    case BloodWorkPopupSteps.TIPS_FOR_LAB:
      return <LabTips type="blood" />;
    default:
      return <Welcome />;
  }
};
