import moment from "moment-timezone";

interface FormattedRecord {
  leaveId: string;
  start: string;
  end: string;
  duration: number;
  employeeId: string;
  employeeRole: string;
}

export function formatNewTimeOffData(
  response: TimeOffRecord[]
): FormattedRecord[] {
  const formattedData: FormattedRecord[] = response.map((record) => {
    const { leaveId, start, end, employeeId, employeeRole } = record;

    const startDate = moment(start * 1000);
    const endDate = moment(end * 1000);

    const duration = Math.round(
      endDate.diff(startDate, "milliseconds") / (1000 * 60 * 60 * 24)
    );

    return {
      leaveId,
      start: startDate.format("MM/DD/YYYY, h:mm:ss A"),
      end: endDate.format("MM/DD/YYYY, h:mm:ss A"),
      duration,
      employeeId,
      employeeRole,
    };
  });

  return formattedData;
}
