import { FEATURES } from "../data/available-features";
import { HONEYDEW_ELIGIBLE_STATES } from "../data/states";

export const prodEnvConfig = {
  ENVIRONMENT: (process.env.REACT_APP_ENV as string).toLowerCase(),
  GROWTHBOOK_API_KEY: process.env.REACT_APP_GROWTHBOOK_API_KEY,
  PATIENTS_SERVICE_GRAPHQL_URL:
    "https://svcgkcxbxfdtnpqgonmfgvsoqi.appsync-api.us-east-1.amazonaws.com/graphql",
  NOTIFICATION_SERVICE_GRAPHQL_URL:
    "https://rtahxy4glnbrtjbhxa3qkivubi.appsync-api.us-east-1.amazonaws.com/graphql",
  WEBSOCKET_URL:
    "wss://rtahxy4glnbrtjbhxa3qkivubi.appsync-realtime-api.us-east-1.amazonaws.com/graphql",
  PAYMENT_SERVICE_GRAPHQL_URL:
    "https://de3ek7ltg5g53i5f2u3hve5jxm.appsync-api.us-east-1.amazonaws.com/graphql",
  REFERRAL_SERVICE_GRAPHQL_URL:
    "https://svcgkcxbxfdtnpqgonmfgvsoqi.appsync-api.us-east-1.amazonaws.com/graphql",
  FOLLOW_UP_SERVICE_GRAPHQL_URL:
    "https://svcgkcxbxfdtnpqgonmfgvsoqi.appsync-api.us-east-1.amazonaws.com/graphql",
  APPOINTMENT_SERVICE_GRAPHQL_URL:
    "https://ixidat6kcradfex2uf2rxbxida.appsync-api.us-east-1.amazonaws.com/graphql",
  MEDICATIONS_SERVICE_GRAPHQL_URL:
    "https://svcgkcxbxfdtnpqgonmfgvsoqi.appsync-api.us-east-1.amazonaws.com/graphql",
  MARKETING_SERVICE_GRAPHQL_URL:
    "https://svcgkcxbxfdtnpqgonmfgvsoqi.appsync-api.us-east-1.amazonaws.com/graphql",
  EMPLOYEES_SERVICE_GRAPHQL_URL:
    "https://svcgkcxbxfdtnpqgonmfgvsoqi.appsync-api.us-east-1.amazonaws.com/graphql",
  EMPLOYEES_SERVICE_REST_URL:
    "https://rov2fptgdk.execute-api.us-east-1.amazonaws.com/prod",
  CHATS_SERVICE_GRAPHQL_URL:
    "https://svcgkcxbxfdtnpqgonmfgvsoqi.appsync-api.us-east-1.amazonaws.com/graphql",
  LAB_SERVICE_GRAPHQL_URL:
    "https://lo7ygqvrnrautnykkzudkkc7d4.appsync-api.us-east-1.amazonaws.com/graphql",
  ADD_UNSUPPORTED_VISITOR_URL:
    "https://uxtqp7w5xa.execute-api.us-east-1.amazonaws.com/prod/api/add-unsupported-visitor",
  QUERY_DASHBOARD_ITEMS_URL:
    "https://agppunmcme.execute-api.us-east-1.amazonaws.com/prod",
  AMAZON_API_DOMAIN: "",
  ROUTE_53_BASE_URL: "",
  USER_POOL_ID: "us-east-1_5Tf9KqX4c",
  AWS_REGION: "us-east-1",
  CLIENT_ID: "198go0vl0ugtmtlphlng9bj74n",
  STRIPE_KEY:
    "pk_live_51H22XaB4dX8zjKdOnr79kzg6KMlqVSGKUhv75ExLKVA3vriB7z5NpOmPlEDweURXWBLqdWLBPGfP65Ggcn3DoNkM00ImPPBPvu",
  ACCUTANE_API_PATH:
    "https://smrud7qbql.execute-api.us-east-1.amazonaws.com/prod/accutane",
  CALENDLY_APPOINTMENT_WIGDET_URLS: {
    [HONEYDEW_ELIGIBLE_STATES.AK]:
      "https://calendly.com/acneawayteam/honeydew-initial-consultation-alaska",
    [HONEYDEW_ELIGIBLE_STATES.AL]:
      "https://calendly.com/acneawayteam/honeydew-initial-consultation-alabama",
    [HONEYDEW_ELIGIBLE_STATES.AR]:
      "https://calendly.com/acneawayteam/honeydew-initial-consultation-arkansas",
    [HONEYDEW_ELIGIBLE_STATES.AZ]:
      "https://calendly.com/acneawayteam/honeydew-initial-consultation-arizona",
    [HONEYDEW_ELIGIBLE_STATES.CA]:
      "https://calendly.com/acneawayteam/honeydew-initial-consultation-california",
    [HONEYDEW_ELIGIBLE_STATES.CO]:
      "https://calendly.com/acneawayteam/honeydew-initial-consultation-colorado",
    [HONEYDEW_ELIGIBLE_STATES.CT]:
      "https://calendly.com/acneawayteam/honeydew-initial-consultation-connecticut",
    [HONEYDEW_ELIGIBLE_STATES.DC]:
      "https://calendly.com/acneawayteam/honeydew-initial-consultation-district-of-columbia",
    [HONEYDEW_ELIGIBLE_STATES.DE]:
      "https://calendly.com/acneawayteam/honeydew-initial-consultation-delaware",
    [HONEYDEW_ELIGIBLE_STATES.FL]:
      "https://calendly.com/acneawayteam/honeydew-initial-consultation-florida",
    [HONEYDEW_ELIGIBLE_STATES.GA]:
      "https://calendly.com/acneawayteam/honeydew-initial-consultation-georgia",
    [HONEYDEW_ELIGIBLE_STATES.HI]:
      "https://calendly.com/acneawayteam/honeydew-initial-consultation-hawaii",
    [HONEYDEW_ELIGIBLE_STATES.IA]:
      "https://calendly.com/acneawayteam/honeydew-initial-consultation-iowa",
    [HONEYDEW_ELIGIBLE_STATES.ID]:
      "https://calendly.com/acneawayteam/honeydew-initial-consultation-idaho",
    [HONEYDEW_ELIGIBLE_STATES.IL]:
      "https://calendly.com/acneawayteam/honeydew-initial-consultation-illinois",
    [HONEYDEW_ELIGIBLE_STATES.IN]:
      "https://calendly.com/acneawayteam/honeydew-initial-consultation-indiana",
    [HONEYDEW_ELIGIBLE_STATES.KS]:
      "https://calendly.com/acneawayteam/honeydew-initial-consultation-kansas",
    [HONEYDEW_ELIGIBLE_STATES.KY]:
      "https://calendly.com/acneawayteam/honeydew-initial-consultation-kentucky",
    [HONEYDEW_ELIGIBLE_STATES.LA]:
      "https://calendly.com/acneawayteam/honeydew-initial-consultation-louisiana",
    [HONEYDEW_ELIGIBLE_STATES.MD]:
      "https://calendly.com/acneawayteam/honeydew-initial-consultation-maryland",
    [HONEYDEW_ELIGIBLE_STATES.ME]:
      "https://calendly.com/acneawayteam/honeydew-initial-consultation-maine",
    [HONEYDEW_ELIGIBLE_STATES.MA]:
      "https://calendly.com/acneawayteam/honeydew-initial-consultation-massachusetts",
    [HONEYDEW_ELIGIBLE_STATES.MI]:
      "https://calendly.com/acneawayteam/honeydew-initial-consultation-michigan",
    [HONEYDEW_ELIGIBLE_STATES.MN]:
      "https://calendly.com/acneawayteam/honeydew-initial-consultation-minnesota",
    [HONEYDEW_ELIGIBLE_STATES.MO]:
      "https://calendly.com/acneawayteam/honeydew-initial-consultation-missouri",
    [HONEYDEW_ELIGIBLE_STATES.MS]:
      "https://calendly.com/acneawayteam/honeydew-initial-consultation-mississippi",
    [HONEYDEW_ELIGIBLE_STATES.MT]:
      "https://calendly.com/acneawayteam/honeydew-initial-consultation-montana",
    [HONEYDEW_ELIGIBLE_STATES.NC]:
      "https://calendly.com/acneawayteam/honeydew-initial-consultation-north-carolina",
    [HONEYDEW_ELIGIBLE_STATES.ND]:
      "https://calendly.com/acneawayteam/honeydew-initial-consultation-north-dakota",
    [HONEYDEW_ELIGIBLE_STATES.NE]:
      "https://calendly.com/acneawayteam/honeydew-initial-consultation-nebraska",
    [HONEYDEW_ELIGIBLE_STATES.NH]:
      "https://calendly.com/acneawayteam/honeydew-initial-consultation-new-hampshire",
    [HONEYDEW_ELIGIBLE_STATES.NJ]:
      "https://calendly.com/acneawayteam/honeydew-initial-consultation-new-jersey",
    [HONEYDEW_ELIGIBLE_STATES.NM]:
      "https://calendly.com/acneawayteam/honeydew-initial-consultation-new-mexico",
    [HONEYDEW_ELIGIBLE_STATES.NV]:
      "https://calendly.com/acneawayteam/honeydew-initial-consultation-nevada",
    [HONEYDEW_ELIGIBLE_STATES.NY]:
      "https://calendly.com/acneawayteam/acne-consultation",
    [HONEYDEW_ELIGIBLE_STATES.OH]:
      "https://calendly.com/acneawayteam/honeydew-initial-consultation-ohio",
    [HONEYDEW_ELIGIBLE_STATES.OK]:
      "https://calendly.com/acneawayteam/honeydew-initial-consultation-oklahoma",
    [HONEYDEW_ELIGIBLE_STATES.OR]:
      "https://calendly.com/acneawayteam/honeydew-initial-consultation-oregon",
    [HONEYDEW_ELIGIBLE_STATES.PA]:
      "https://calendly.com/acneawayteam/honeydew-initial-consultation-pennsylvania",
    [HONEYDEW_ELIGIBLE_STATES.RI]:
      "https://calendly.com/acneawayteam/honeydew-initial-consultation-rhode-island",
    [HONEYDEW_ELIGIBLE_STATES.SC]:
      "https://calendly.com/acneawayteam/honeydew-initial-consultation-south-carolina",
    [HONEYDEW_ELIGIBLE_STATES.SD]:
      "https://calendly.com/acneawayteam/honeydew-initial-consultation-south-dakota",
    [HONEYDEW_ELIGIBLE_STATES.TN]:
      "https://calendly.com/acneawayteam/honeydew-initial-consultation-tennessee",
    [HONEYDEW_ELIGIBLE_STATES.TX]:
      "https://calendly.com/acneawayteam/honeydew-initial-consultation-texas",
    [HONEYDEW_ELIGIBLE_STATES.UT]:
      "https://calendly.com/acneawayteam/honeydew-initial-consultation-utah",
    [HONEYDEW_ELIGIBLE_STATES.VA]:
      "https://calendly.com/acneawayteam/honeydew-initial-consultation-virginia",
    [HONEYDEW_ELIGIBLE_STATES.VT]:
      "https://calendly.com/acneawayteam/honeydew-initial-consultation-vermont",
    [HONEYDEW_ELIGIBLE_STATES.WA]:
      "https://calendly.com/acneawayteam/honeydew-initial-consultation-washington",
    [HONEYDEW_ELIGIBLE_STATES.WI]:
      "https://calendly.com/acneawayteam/honeydew-initial-consultation-wisconsin",
    [HONEYDEW_ELIGIBLE_STATES.WV]:
      "https://calendly.com/acneawayteam/honeydew-initial-consultation-west-virginia",
    [HONEYDEW_ELIGIBLE_STATES.WY]:
      "https://calendly.com/acneawayteam/honeydew-initial-consultation-wyoming",
  },
  AMAZON_S3_USER_IMAGES_DOMAIN: "https://d2ivgz4ubs3hfo.cloudfront.net",
  BLOOD_SLIP_TEMPLATE_ID: "7c196df400d343469a8117c22d4689b263fd8a3e",
  AMAZON_S3_PATIENT_DOCUMENT_DOMAIN:
    "https://honeydew-accutane-service-documents-bucket-prod.s3.amazonaws.com",
  PUBNUB_PUBLISH_KEY: "pub-c-685c56d0-49c1-443b-8015-e42475d11093",
  ENABLED_FEATURES: [FEATURES.VITAL],
  // ENABLED_IDS: [
  //   "273cc046-557d-4d20-b3d3-e48a71a607ab", // david+2@honeydewcare.com
  //   "46ab1152-c410-4886-a880-9af447631a30", // david@acneawaycare.com
  //   "c2472b9e-e60a-4278-88c9-1e3e273437c4", // david+03@honeydewcare.com
  //   "fc100b32-96b3-4084-8d74-fa53e376c100", // dfutoran@gmail.com
  //   "f1ea44e4-05c1-4aff-b9ac-4d42758dbd96", // juan.gomez+testprod@honeydewcare.com
  // ],
};
