import { USER_ROLES } from "../types/Main";

export type RealTimeNotification = {
  id: string;
  type: string;
  message: string;
  metadata: string;
  broadcast: boolean;
  roles?: USER_ROLES[];
  recipientIds?: string[];
};

export const REAL_TIME_TYPES = {
  NEW_CHAT_MESSAGE: "NEW_CHAT_MESSAGE",
};

export const isForMe = ({
  notification,
  userId,
  role,
}: {
  notification: RealTimeNotification;
  userId: string;
  role: USER_ROLES;
}) => {
  if (notification.broadcast) return true;
  if (notification.roles?.includes(role)) return true;
  if (notification.recipientIds?.includes(userId)) return true;
  return false;
};
