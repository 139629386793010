import moment from "moment-timezone";
import React from "react";
import { Employee } from "../../../../types/Employee";
import { FollowUpTreatmentPlan } from "../../../../types/FollowUp";
import { USER_ROLES } from "../../../../types/Main";
import "./style.scss";
import { getFullType } from "../../../../utils/get-full-type";

interface Props {
  employee: Employee;
  treatmentPlan: FollowUpTreatmentPlan;
}

export function TreatmentPlanProviderComment({
  employee,
  treatmentPlan,
}: Props) {
  return (
    <div className="treatment-plan-provider-comment">
      <img
        src={employee.image}
        className="treatment-plan-provider-comment__provider-image"
      />
      <div className="treatment-plan-provider-comment__comment-title">
        <p className="treatment-plan-provider-comment__provider-name">
          {employee.firstName} {employee.lastName}
          {employee.role === USER_ROLES.PROVIDER ? `, ${employee.title}` : ""}
        </p>
        <p className="treatment-plan-provider-comment__provider-title">
          {getFullType(employee.role)}
        </p>
      </div>
      <p className="treatment-plan-provider-comment__date">
        {moment(treatmentPlan.timestamp).fromNow()}
      </p>
      <p className="treatment-plan-provider-comment__comment-text paragraph-font">
        {treatmentPlan.message}
      </p>
    </div>
  );
}
