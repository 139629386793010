import moment from "moment-timezone";
import { logInfo } from "../../../shared/logger";
import { ShowNotificationProps } from "../../NewComponents/Common/NotificationLayer";

interface HolidayRecord {
  key: string;
  title: string;
  day: number;
  month: number;
  year?: number;
  untilNextMonday?: boolean;
}

// Dates last updated 8/29/2024
// TODO: Improve this so that these don't need to be updated every year: HD-1176
const holidaysList: HolidayRecord[] = [
  {
    key: "new-year-day", // 2025
    title: "New Year's Day",
    day: 1,
    month: 1,
    year: 2025,
  },
  {
    key: "martin-luther-king-day", // 2025
    title: "Martin Luther King Day",
    day: 20,
    month: 1,
  },
  {
    key: "presidents-day", // 2025
    title: "Presidents' Day",
    day: 17,
    month: 2,
  },
  {
    key: "memorial-day", // 2025
    title: "Memorial Day",
    day: 26,
    month: 5,
  },
  {
    key: "juneteenth", // 2025
    title: "Juneteenth",
    day: 19,
    month: 6,
  },
  {
    key: "independence-day", // 2025
    title: "Independence Day",
    day: 4,
    month: 7,
  },
  {
    key: "labor-day", // 2024
    title: "Labor Day",
    day: 2,
    month: 9,
  },
  {
    key: "columbus-day", // 2024
    title: "Columbus Day",
    day: 14,
    month: 10,
  },
  {
    key: "veterans-day", // 2024
    title: "Veterans Day",
    day: 11,
    month: 11,
  },
  {
    key: "thanksgiving-day", // 2024
    title: "Thanksgiving Day",
    day: 28,
    month: 11,
    untilNextMonday: true,
  },
  {
    key: "christmas-day", // 2024
    title: "Christmas Day",
    day: 25,
    month: 12,
  },
];

function createDate(year: number, month: number, day: number) {
  return moment(
    `${year}-${month > 9 ? month : `0${month}`}-${day > 9 ? day : `0${day}`}`
  );
}

export function processHolidays(
  showInfo: (props: ShowNotificationProps) => void
) {
  holidaysList.forEach(
    ({ key, title, day, month, year: setupYear, untilNextMonday }) => {
      const now = moment();
      const year = setupYear || now.year();

      const holidayDate = createDate(year, month, day);

      const isNotified = !!window.localStorage.getItem(`${key}-${year}`);

      // Start showing banner 3 days before holiday
      const from = holidayDate.clone().subtract(3, "days").startOf("day");

      let to;

      const isFriday = holidayDate.day() === 5;

      if (isFriday || untilNextMonday) {
        to = holidayDate.clone().day(8).startOf("day"); // Go to next monday
      } else {
        to = holidayDate.clone().endOf("day"); // Show the banner through the entire holiday
      }

      logInfo(key, {
        from,
        to,
      });

      if (!isNotified && now.isBetween(from, to)) {
        showInfo({
          title: "Information regarding holidays",
          description: `Please be advised that there might be delayed response times due to ${title}`,
          duration: 100,
        });

        window.localStorage.setItem(`${key}-${year}`, "true");
      }
    }
  );
}
