import React, { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as UnreadChatIcon } from "../../../../Assets/icons/unread-chat.svg";
import { AcneAwayAPI } from "../../../../services/acneaway-api";
import { logError } from "../../../../shared/logger";
import { Loader } from "../Loader";
import { NotificationLayerContext } from "../NotificationLayer";
import { HoneydewAPI } from "../../../../services/honeydew-api";
import { getSessionState } from "../../../../features/session";
import { pubnubClient } from "../../../../services/pubnub";
import { getTimeOfLatestMsg } from "../../../../utils/patient/pubnubChatCount";
import { useUpdateChatDataMutation } from "../../../../features/api/patients";

enum STATUSES {
  IDLE = "idle",
  LOADING = "loading",
}

interface Props {
  patientId: string;
}

export function MarkChatAsUnreadButton() {
  const [status, setStatus] = useState(STATUSES.IDLE);
  const { userRole, userId, activePatientId } = useSelector(getSessionState);
  const { showError, showSuccess } = useContext(NotificationLayerContext);
  const [updateChatData] = useUpdateChatDataMutation();

  if (userRole === "patients") {
    return null;
  }

  async function submit() {
    setStatus(STATUSES.LOADING);

    try {
      const patientId = activePatientId || "";

      const [pubNubSubscribeKey, pubnubToken] = await Promise.all([
        HoneydewAPI.chats.pubNubSubscribeKey(),
        HoneydewAPI.chats.getTokenGenerated([patientId], userId),
      ]);

      const pubnubInstance = pubnubClient({
        userId,
        pubNubSubscribeKey,
      });

      pubnubInstance.setToken(pubnubToken);

      const [existingMetadata, lastMsgTimestamp] = await Promise.all([
        pubnubInstance.objects.getChannelMetadata({ channel: patientId }),
        getTimeOfLatestMsg(patientId, pubnubInstance),
      ]);

      const existingCustom = existingMetadata?.data?.custom || {};
      const updatedCustom = {
        ...existingCustom,
        [userId]: JSON.stringify({
          ...JSON.parse(existingCustom?.[userId] || "{}"),
          isUnread: true,
          timestamp: lastMsgTimestamp,
        }),
      };

      await pubnubInstance.objects.setChannelMetadata({
        channel: patientId,
        data: {
          ...existingMetadata.data,
          custom: updatedCustom,
        },
      });

      await updateChatData({
        patientId,
        isUnread: true,
        userIds: [userId],
      });

      showSuccess({
        title: "Success!",
        description: "Chat successfully marked as unread",
      });
    } catch (error: any) {
      logError("Cannot mark chat as unread", error);
      showError({
        title: "Something went wrong",
        description: "Cannot mark chat as unread. Please, try again later",
      });
    } finally {
      setStatus(STATUSES.IDLE);
    }
  }

  return (
    <div
      className={`unread-chat unread-chat--${status}`}
      onClick={status === STATUSES.IDLE ? submit : undefined}
      data-tooltip="Mark As Unread"
    >
      {status === STATUSES.LOADING ? (
        <Loader className="notify-provider__icon" />
      ) : (
        <UnreadChatIcon className="unread-chat__icon" />
      )}
    </div>
  );
}
