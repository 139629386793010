import React from "react";
import { usePregnancyWorkStepper } from "../../../../../../contexts/pregnancy-work";
import AddNewCardWrapper from "../../../../Common/Payment/AddNewCardWrapper/AddNewCardWrapper";

export default function PregnancyTestAddNewCard() {
  return (
    <AddNewCardWrapper
      useWorkflowStepper={usePregnancyWorkStepper}
      workflowKey="pregnancyWork"
    />
  );
}
