import { PregnancyWorkPopupSteps } from "../app/NewComponents/Patient/PregnancyTestPopup/steps";

export const getNextStep = (step: number) => {
  switch (step) {
    case PregnancyWorkPopupSteps.WELCOME:
      return PregnancyWorkPopupSteps.INSURANCE_SELECTION;
    case PregnancyWorkPopupSteps.INSURANCE_SELECTION:
      return PregnancyWorkPopupSteps.LAB_SELECTION;
    case PregnancyWorkPopupSteps.LAB_SELECTION:
      return PregnancyWorkPopupSteps.FIND_LAB;
    case PregnancyWorkPopupSteps.FIND_LAB:
      return PregnancyWorkPopupSteps.PAYMENT_DETAILS;
    case PregnancyWorkPopupSteps.PAYMENT_DETAILS:
      return PregnancyWorkPopupSteps.CHECKOUT;
    case PregnancyWorkPopupSteps.PAYMENT_METHOD:
      return PregnancyWorkPopupSteps.PAYMENT_DETAILS;
    case PregnancyWorkPopupSteps.NEW_CARD:
      return PregnancyWorkPopupSteps.PAYMENT_DETAILS;
    default:
      return PregnancyWorkPopupSteps.WELCOME;
  }
};

export const getPreviousStep = (step: number) => {
  switch (step) {
    case PregnancyWorkPopupSteps.INSURANCE_SELECTION:
      return PregnancyWorkPopupSteps.WELCOME;
    case PregnancyWorkPopupSteps.FIND_LAB:
      return PregnancyWorkPopupSteps.LAB_SELECTION;
    case PregnancyWorkPopupSteps.LAB_SELECTION:
      return PregnancyWorkPopupSteps.INSURANCE_SELECTION;
    case PregnancyWorkPopupSteps.MEDICAL_OFFICE:
      return PregnancyWorkPopupSteps.LAB_SELECTION;
    case PregnancyWorkPopupSteps.INSURANCE_LAB:
      return PregnancyWorkPopupSteps.LAB_SELECTION;
    case PregnancyWorkPopupSteps.PAYMENT_DETAILS:
      return PregnancyWorkPopupSteps.FIND_LAB;
    case PregnancyWorkPopupSteps.PAYMENT_METHOD:
      return PregnancyWorkPopupSteps.PAYMENT_DETAILS;
    case PregnancyWorkPopupSteps.NEW_CARD:
      return PregnancyWorkPopupSteps.PAYMENT_METHOD;
    case PregnancyWorkPopupSteps.CHECKOUT:
      return PregnancyWorkPopupSteps.PAYMENT_DETAILS;
    case PregnancyWorkPopupSteps.INSURANCE:
      return PregnancyWorkPopupSteps.INSURANCE_SELECTION;
    default:
      return PregnancyWorkPopupSteps.WELCOME;
  }
};

export const hasCustomButtons = (step: number) => {
  switch (step) {
    case PregnancyWorkPopupSteps.PAYMENT_DETAILS:
      return true;
    case PregnancyWorkPopupSteps.NEW_CARD:
      return true;
    case PregnancyWorkPopupSteps.CHECKOUT:
      return true;
    case PregnancyWorkPopupSteps.MEDICAL_OFFICE:
      return true;
    case PregnancyWorkPopupSteps.INSURANCE_LAB:
      return true;
    case PregnancyWorkPopupSteps.LAB_SELECTION:
      return true;
    case PregnancyWorkPopupSteps.TIPS_FOR_LAB:
      return true;
    default:
      return false;
  }
};
