import React from "react";
import { InfoBlock } from "../app/NewComponents/Common/InfoBlock";
import { Accutane } from "../types/Entities/Accutane";

const renderActive = () => (
  <>
    <span className="ipledge-details__title">Status: </span>
    <span>Active</span>
  </>
);

const renderActiveWithDuration = (duration: string | null | undefined) => (
  <>
    <span className="ipledge-details__title">Status: </span>
    <span>Active ({duration})</span>
  </>
);

const renderWarning = (pastText: string) => (
  <InfoBlock
    text={
      <>
        <span className="ipledge-details__title">Status: </span> Active (
        {pastText})
      </>
    }
    view="warn"
  />
);

export function accutaneDuration(accutane: Accutane) {
  const {
    months = 0,
    days = 0,
    past = false,
  } = accutane?.confirmationPeriod || {};

  const formatDuration = (numOfmonths: number, numOfdays: number) => {
    if (numOfmonths === 0 && numOfdays > 0) return `Day ${numOfdays}`;
    if (numOfmonths > 0 && numOfdays > 0)
      return `Month ${numOfmonths}, Day ${numOfdays}`;
    if (numOfmonths > 0) return `Month ${numOfmonths}`;
    return null;
  };

  const activeText = formatDuration(months, days);

  if (months === 0 && days === 0) {
    return renderActive();
  }

  if (!past) {
    return renderActiveWithDuration(activeText);
  }

  const pastText =
    months > 0
      ? `Month ${months}, ${days} days past expected next confirmation`
      : `${days} days past expected next confirmation`;

  return renderWarning(pastText);
}
