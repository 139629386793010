import React, { useMemo, useState } from "react";
import { Typography } from "@mui/material";
import { HelpPopupTemplate } from "../PopupLayer/help";
import { TextInput } from "../TextInput";
import { Button } from "../Button";
import "./style.scss";
import { logInfo } from "../../../../shared/logger";

interface PopupWithInputProps {
  onClose: () => void;
  action: (input: string) => any;
  title: string;
  message?: string;
  defaultValue?: string;
  placeholder?: string;
  validator?: (input: string) => boolean;
}

function PopupWithInput({
  onClose,
  title,
  message,
  action,
  defaultValue = "",
  placeholder = "",
  validator = (input: string) => true,
}: PopupWithInputProps) {
  const [input, setInput] = useState(defaultValue);

  const isValidInput = useMemo(() => validator(input), [input]);

  return (
    <div className="sign-up-document-popup">
      <p className="sign-up-document-popup__title">{title}</p>
      <div className="sign-up-document-popup__e-signature">
        <p className="sign-up-document-popup__e-signature__title">{message}</p>
        <TextInput
          onChange={(text: string) => setInput(text)}
          value={input}
          id="eSignatureInput"
          name="eSignatureInput"
          placeholder={placeholder}
        />
        {!isValidInput ? (
          <Typography variant="subtitle2" mt={1} ml={2}>
            Please enter a valid zip code.
          </Typography>
        ) : null}
      </div>
      <div className="sign-up-document-popup__control-buttons">
        <Button text="cancel" view="secondary" onClick={onClose} size="big" />
        <Button
          text="submit"
          size="big"
          disabled={!isValidInput}
          onClick={async () => {
            const response = await action(input);
            onClose();
          }}
        />
      </div>
    </div>
  );
}

function PopupWithInputGenerator({
  title,
  action,
  message,
  placeholder,
  defaultValue,
  validator,
}: {
  title: string;
  action: (input: string) => any;
  message: string;
  placeholder?: string;
  defaultValue?: string;
  validator: (input: string) => boolean;
}) {
  return function render({ onClose }: { onClose: () => void }) {
    return (
      <HelpPopupTemplate onClose={onClose}>
        <PopupWithInput
          onClose={onClose}
          title={title}
          message={message}
          action={action}
          placeholder={placeholder}
          defaultValue={defaultValue}
          validator={validator}
        />
      </HelpPopupTemplate>
    );
  };
}

export default PopupWithInputGenerator;
