export const MONTH_NAMES = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december",
];

export const literal = {
  character: {
    FORWARD_SLASH: "/",
  },
};

const development = {
  ADMINS_LIST: [
    {
      cognitoUserId: "b5b5a9cf-3348-44f3-ae8f-6cf558125d6f",
      phone: "+48603467716",
      fullName: "Yahor Admin",
      email: "philipok4596@gmail.com",
      group: "admin",
      groupFriendly: "Administrator",
    },
  ],
};

const production = {
  ADMINS_LIST: [
    {
      cognitoUserId: "6e7f5a4f-865f-4013-b79a-93a5fcf0521d",
      phone: "+15165321199",
      fullName: "David Futoran",
      email: "david.futoran@honeydewcare.com",
      group: "admin",
      groupFriendly: "Administrator",
    },
    {
      cognitoUserId: "33145a4f-865f-4013-b79a-93a5fcf0521d",
      phone: "+18018343024", // TODO
      fullName: "Tamazine Davison",
      email: "tamazine.davison@honeydewcare.com",
      group: "admin",
      groupFriendly: "Administrator",
    },
  ],
};

export const diagnosisCodesList = [
  "Acne Vulgaris L70.0",
  "Rosacea L71.9",
  "Atopic Dermatitis L20.9",
  "Plaque Psoriasis L40.0",
  "Vitiligo L80",
  "Hydradenitis Suppurativa L73.2",
  "Alopecia L63.9",
];

export const MODE = process.env.REACT_APP_ENV as string;
export const { ADMINS_LIST } =
  process.env.ENVIRONMENT === "PROD" ? production : development;

// July 7th, 2020
export const APPLICATION_START_POINT_TIMESTAMP = 1599764800000;

export const PAYMENT_CONSTANTS = {
  // Error messages
  CARD_ERROR_TITLE: "Card Declined",
  DEFAULT_ERROR_MESSAGE: "Please try again or use a different card.",
  ERROR_DURATION: 8,

  // Processing messages
  INITIAL_PROCESSING_MESSAGE:
    "Please be patient and do not refresh the page while we process your payment.",
  EXTENDED_PROCESSING_MESSAGE:
    "Sorry, this is taking longer than expected. We are still processing your payment.",
  PROCESSING_MESSAGE_DELAY: 8000,

  // Contact information
  SUPPORT_EMAIL: "membership@honeydewcare.com",
  CARE_COORDINATOR_TEXT: "Care Coordinator",
} as const;

// Type for expected error shapes
export interface PaymentError {
  message?: string;
  code?: string;
  type?: string;
}

// Shared support message component text
export const SUPPORT_MESSAGE = {
  COLOR: "#5E39F5",
  TEXT: "For assistance, contact your Care Coordinator or send an email to membership@honeydewcare.com",
};
