interface BillingPatient {
  email?: string;
  shippingInfo?: {
    addressLine1: string;
    addressLine2?: string;
    state: string;
    zipCode: string;
    city: string;
  };
}

interface BillingDetails {
  name: string;
  email?: string;
  address?: {
    line1: string;
    line2: string;
    state: string;
    postal_code: string;
    city: string;
  };
}

export const getBillingDetailsFromPatient = (
  cardHolderName: string,
  patient?: BillingPatient
): BillingDetails => ({
  name: cardHolderName,
  email: patient?.email,
  address: patient?.shippingInfo
    ? {
        line1: patient.shippingInfo.addressLine1,
        line2: patient.shippingInfo.addressLine2 || "",
        state: patient.shippingInfo.state,
        postal_code: patient.shippingInfo.zipCode,
        city: patient.shippingInfo.city,
      }
    : undefined,
});
