import { Task } from ".";
import * as patientsTasks from "./patient";

interface StaticTaskClass<T> {
  new (...args: any[]): T;
  type: string;
}

const AllTaskClasses = [...Object.values(patientsTasks)];

/*
 * The idea here is to have a handler for each type of tasks that a patient can have.
 * Only those tasks that have a correpsonding class (based on the class static type) will be picked up an handled here. It's done this way to
 * so it's retro-compatible with how tasks have been handled in the past
 *
 * If you want to handle a task in this way just create a class that extends from BaseTask and assign a static prop with the task type that you want to handle
 *
 * */

export function createObjectForTasks(tasks: Task[]) {
  const classMap = new Map(
    AllTaskClasses.map((cls: StaticTaskClass<any>) => [cls.type, cls])
  );

  return tasks.reduce<InstanceType<(typeof AllTaskClasses)[number]>[]>(
    (acc, task) => {
      const MatchingClass = classMap.get(task.type);
      if (MatchingClass) acc.push(new MatchingClass(task));
      return acc;
    },
    []
  );
}
