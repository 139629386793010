import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import zipState from "zip-state";
import { RegistrationSurvey, SurveyPage } from "../../../../types/CustomSurvey";
import { getAge } from "../../../../utils/get-age";
import { isEmpty } from "../../../../utils/is-empty";
import { CustomSurvey } from "../../../NewComponents/Common/CustomSurvey";
import { SimplePageLayout } from "../../../NewComponents/Common/SimplePageLayout";
import { IsChildSurveyQuestion } from "./survey-questions/is-child";
import { ParentsDataSurveyQuestion } from "./survey-questions/parents-data";
import { SignUpSurveyQuestion } from "./survey-questions/sign-up";
import "./style.scss";
import { AuthenticationService } from "../../../../services/cognito";
import { ActionPopupGenerator } from "../../../NewComponents/Common/ActionPopup";
import { PopupLayerContext } from "../../../NewComponents/Common/PopupLayer";
import { NotificationLayerContext } from "../../../NewComponents/Common/NotificationLayer";
import { signIn } from "../../../../features/session";
import { sendSurveyStarted } from "../../../../shared/analytics";
import { HONEYDEW_ELIGIBLE_STATES } from "../../../../data/states";
import { logError } from "../../../../shared/logger";
import { CustomSurveyStepperProvider } from "../../../../contexts/custom-survey";
import {
  Data,
  DataInputQuestion,
  InputType,
} from "./survey-questions/data-input-question";
import {
  BirthDateValidator,
  FullNameValidator,
  ZipCodeValidator,
} from "../../../../services/validator";
import { getFirstName } from "../../../../utils/survey/get-first-name";
import { growthbook } from "../../../../App";
import { Step, useSurveySteps } from "../../../../hooks/use-survey-steps";
import { PatientAccountType } from "../../../../types/Patient";
import { getAccountType } from "../../../../utils/survey/get-account-type";
import { storeUTMParameters } from "../../../../utils/marketing/store-utm-parameters";

const surveyQuestions: SurveyPage[] = [
  {
    isFilled: (data) => !isEmpty(data.isChild),
    Component: IsChildSurveyQuestion,
  },
  {
    isFilled: (data) => !isEmpty(data.fullName),
    Component: ({
      onDone,
      data,
    }: {
      onDone: (data: Data) => void | Promise<void>;
      data: Data;
    }) => (
      <DataInputQuestion
        validator={new FullNameValidator()}
        onDone={onDone}
        type={InputType.Text}
        data={data}
        placeholder="e.g. Jane Smith"
        getTitle={({ isChild }) =>
          isChild
            ? "We can’t wait to help. What is your child’s name?"
            : "Great, what is your name?"
        }
        getDescription={({ isChild }) =>
          isChild
            ? "Please enter your child's full name"
            : "Please enter your full name"
        }
        questionKey="fullName"
        name="full-name"
      />
    ),
  },
  {
    isFilled: (data) => !isEmpty(data.birthDate),
    Component: ({
      onDone,
      data,
    }: {
      onDone: (data: Data) => void | Promise<void>;
      data: Data;
    }) => (
      <DataInputQuestion
        validator={new BirthDateValidator()}
        type={InputType.Date}
        onDone={onDone}
        data={data}
        placeholder="MM / DD / YYYY"
        getTitle={({ isChild }) =>
          isChild
            ? `Thanks, and what is ${getFirstName(
                data.fullName
              )}’s date of birth? `
            : `Nice to meet you, ${getFirstName(
                data.fullName
              )}. What is your date of birth?`
        }
        getDescription={({ isChild }) =>
          isChild
            ? "We’ll need your child’s age to verify your eligibility for Honeydew."
            : "We’ll need your age to verify your eligibility for Honeydew."
        }
        questionKey="birthDate"
        name="birth-date"
      />
    ),
  },
  {
    isFilled: (data) => !isEmpty(data.zipCode),
    Component: ({
      onDone,
      data,
    }: {
      onDone: (data: Data) => void | Promise<void>;
      data: Data;
    }) => (
      <DataInputQuestion
        validator={new ZipCodeValidator()}
        type={InputType.Text}
        onDone={onDone}
        data={data}
        placeholder="10001"
        getTitle={({ isChild }) =>
          isChild
            ? `What zip code is ${getFirstName(data.fullName)} in?`
            : "Lastly, what is your zip code?"
        }
        getDescription={({ isChild }) =>
          isChild
            ? `We'll match your child to a provider that's licensed in the state they reside in.`
            : "We'll match you to a provider that's licensed in your state."
        }
        questionKey="zipCode"
        name="zip-code"
      />
    ),
  },
  {
    isFilled: (data) => !isEmpty(data.parents),
    when: (data) => data.isChild || getAge(data.birthDate) < 18,
    Component: ParentsDataSurveyQuestion,
  },
  {
    isFilled: (data) => !isEmpty(data.creds),
    Component: SignUpSurveyQuestion,
  },
];

export function RegistrationPage() {
  const history = useHistory();
  const dispatch = useDispatch();
  const searchParams = new URLSearchParams(window.location.search);
  const referralCode = searchParams.get("code");
  const { showPopup } = useContext(PopupLayerContext);
  const { showSuccess, showError, showInfo } = useContext(
    NotificationLayerContext
  );
  const [moveFromCurrentStep] = useSurveySteps(Step.REGISTRATION);

  useEffect(() => {
    sendSurveyStarted();
  }, []);

  async function autoSignIn(email: string, password: string) {
    const session = await AuthenticationService.signIn({
      email,
      password,
    });

    await growthbook.init({ streaming: true });
    await growthbook.setAttributes({
      id: session.sub,
    });

    const isFeatureOn = growthbook.isOn("paywall");
    storeUTMParameters(session.sub);
    dispatch(signIn(session));
    moveFromCurrentStep(session.sub, isFeatureOn);
  }

  async function doSignUp(data: RegistrationSurvey) {
    const state = zipState(data.zipCode);
    const isEligibleState =
      state && Object.keys(HONEYDEW_ELIGIBLE_STATES).includes(state);

    if (!isEligibleState) {
      logError(`Cannot find state for zipCode ${data.zipCode}`);
      showError({
        title: "Oops... Something went wrong",
        description: "Cannot find state for provided zip code",
      });
      return;
    }

    const result = await AuthenticationService.signUp(
      {
        email: data.creds.email,
        password: data.creds.pwd,
      },
      {
        fullName: data.fullName.trim(),
        state,
        dateOfBirth: data.birthDate.replaceAll(" ", ""),
        referralCode: data.referral,
        parentInfo: data.parents,
        agreedToMarketingCampaign: data.agreedToMarketingCampaign,
        accountType: getAccountType(data),
      }
    );
    if (result?.name === "UsernameExistsException") {
      showPopup(
        ActionPopupGenerator({
          title: "This account already exists",
          text: `An account under ${data.creds.email} email has already been created. To log in, press “cancel” and then “log in” at the button of the page, or add a new patient to your account`,
          action: async () => {
            window.sessionStorage.setItem(
              "patientInfoMultiAcc",
              JSON.stringify({
                fullName: data.fullName.trim(),
                state,
                email: data.creds.email,
                dateOfBirth: data.birthDate.replaceAll(" ", ""),
                parentInfo: data.parents,
                accountType: PatientAccountType.PARENT,
              })
            );
            history.push("/login");
            showInfo({
              title: "Last step",
              description:
                "Log in to continue adding new patient to your new account",
            });
          },
          actionText: "Add another patient",
        })
      );
      return;
    }

    await autoSignIn(data.creds.email, data.creds.pwd);
  }

  return (
    <SimplePageLayout onBack={() => history.push("/login")}>
      <CustomSurveyStepperProvider>
        <CustomSurvey
          title="GETTING STARTED"
          showProgressBar
          questions={surveyQuestions}
          data={referralCode ? { referral: referralCode } : {}}
          customBack
          onComplete={async (_data) => {
            await doSignUp(_data as RegistrationSurvey);
            return true;
          }}
        />
      </CustomSurveyStepperProvider>
    </SimplePageLayout>
  );
}
