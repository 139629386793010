import { BloodWorkPopupSteps } from "../app/NewComponents/Patient/BloodWorkPopup/steps";

export const getNextStep = (step: number) => {
  switch (step) {
    case BloodWorkPopupSteps.WELCOME:
      return BloodWorkPopupSteps.INSURANCE_SELECTION;
    case BloodWorkPopupSteps.INSURANCE_SELECTION:
      return BloodWorkPopupSteps.FIND_LAB;
    case BloodWorkPopupSteps.FIND_LAB:
      return BloodWorkPopupSteps.PAYMENT_DETAILS;
    case BloodWorkPopupSteps.PAYMENT_DETAILS:
      return BloodWorkPopupSteps.CHECKOUT;
    case BloodWorkPopupSteps.PAYMENT_METHOD:
      return BloodWorkPopupSteps.PAYMENT_DETAILS;
    case BloodWorkPopupSteps.NEW_CARD:
      return BloodWorkPopupSteps.PAYMENT_DETAILS;
    default:
      return BloodWorkPopupSteps.WELCOME;
  }
};

export const getPreviousStep = (step: number) => {
  switch (step) {
    case BloodWorkPopupSteps.INSURANCE_SELECTION:
      return BloodWorkPopupSteps.WELCOME;
    case BloodWorkPopupSteps.FIND_LAB:
      return BloodWorkPopupSteps.INSURANCE_SELECTION;
    case BloodWorkPopupSteps.PAYMENT_DETAILS:
      return BloodWorkPopupSteps.FIND_LAB;
    case BloodWorkPopupSteps.PAYMENT_METHOD:
      return BloodWorkPopupSteps.PAYMENT_DETAILS;
    case BloodWorkPopupSteps.NEW_CARD:
      return BloodWorkPopupSteps.PAYMENT_METHOD;
    case BloodWorkPopupSteps.CHECKOUT:
      return BloodWorkPopupSteps.PAYMENT_DETAILS;
    case BloodWorkPopupSteps.CHECKOUT_INSURANCE:
      return BloodWorkPopupSteps.INSURANCE_SELECTION;
    case BloodWorkPopupSteps.INSURANCE:
      return BloodWorkPopupSteps.INSURANCE_SELECTION;
    default:
      return BloodWorkPopupSteps.WELCOME;
  }
};

export const hasCustomButtons = (step: number) => {
  switch (step) {
    case BloodWorkPopupSteps.PAYMENT_DETAILS:
      return true;
    case BloodWorkPopupSteps.NEW_CARD:
      return true;
    case BloodWorkPopupSteps.CHECKOUT:
      return true;
    case BloodWorkPopupSteps.CHECKOUT_INSURANCE:
      return true;
    case BloodWorkPopupSteps.INSURANCE_SELECTION:
      return true;
    case BloodWorkPopupSteps.TIPS_FOR_LAB:
      return true;
    default:
      return false;
  }
};
