import React, { useContext, useEffect, useState } from "react";
import {
  Switch,
  Route,
  useLocation,
  useParams,
  Redirect,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { PatientDashboardPageForCareCoordinator } from "../../NewPages/CareCoordinator/PatientDashboard";
import { PatientsPageForCareCoordinator } from "../../NewPages/CareCoordinator/PatientsPage";
import { PatientFollowUpVisit } from "../../Pages/Provider/PatientFollowUpVisit";
import { Menu } from "../../NewComponents/Common/Menu";
import { Footer } from "../../NewComponents/Common/Footer";
import PrintPatientInfoPage from "../../NewPages/PrintPatientInfo";
import { getSessionState } from "../../../features/session";
import { ChatLayer } from "../../NewComponents/Common/ChatLayer";
import { ChatWidget } from "../../NewComponents/Common/ChatWidget";
import { NotificationLayerContext } from "../../NewComponents/Common/NotificationLayer";
import {
  useGetCareTeamQuery,
  useGetAssignedPatientsQuery,
} from "../../../features/api/patients";
import PatientFollowUpVisitComponent from "./PatientFollowUpVisit";
import PrintPatientInfoComponent from "./PrintPatientInfoComponent";
import PatientDashboardPageForCareCoordinatorComponent from "./PatientDashboardPageForCareCoordinator";
import ProtectedRoutesCare from "./ProtectedRoutes";
import { WebSocketProvider } from "../../../contexts/web-socket";
// import { PatientDashboardPageForCareCoordinator } from "../../Pages/CareCoordinator/PatientDashboardPage";

export function CareCoordinatorView() {
  const [showMenu, setMenuVisibility] = useState(true);
  const location = useLocation();
  const { userId, userRole } = useSelector(getSessionState);
  const { showInfo } = useContext(NotificationLayerContext);

  if (!userId || !userRole) return null;

  return (
    <WebSocketProvider>
      <ChatLayer identity={userId} userRole={userRole}>
        {(showMenu && <Menu />) || null}
        <ProtectedRoutesCare setMenuVisibility={setMenuVisibility} />
      </ChatLayer>
    </WebSocketProvider>
  );
}

export default CareCoordinatorView;
