import React, { useContext, useEffect, useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { addNewPaymentMethod } from "../../../../../utils/stripe/add-new-card";
import StepperButtons from "../../../Stepper/StepperButtons";
import { useAttachPaymentMethodMutation } from "../../../../../features/api/payment";
import AddNewCard from "../AddNewCard";
import { NotificationLayerContext } from "../../NotificationLayer";
import { PAYMENT_CONSTANTS, PaymentError } from "../../../../../constants";
import { getBillingDetailsFromPatient } from "../../../../../utils/billing";

interface StepperHook {
  handleNext: () => void;
  handleBack: () => void;
  updateValues: (values: { selectedPaymentMethod: string }) => void;
  setIsNextDisabled: (disabled: boolean) => void;
  isNextDisabled: boolean;
}

interface WorkflowContext {
  patient?: {
    email?: string;
    customerId?: string;
    shippingInfo?: {
      addressLine1: string;
      addressLine2?: string;
      state: string;
      zipCode: string;
      city: string;
    };
  };
}

interface AddNewCardWrapperProps {
  useWorkflowStepper: () => StepperHook & { [key: string]: WorkflowContext };
  workflowKey: string; // 'bloodwork' or 'pregnancyWork'
}

export default function AddNewCardWrapper({
  useWorkflowStepper,
  workflowKey,
}: AddNewCardWrapperProps) {
  const stepperHook = useWorkflowStepper();
  const {
    handleNext,
    handleBack,
    updateValues,
    setIsNextDisabled,
    isNextDisabled,
  } = stepperHook;
  const workflowContext = stepperHook[workflowKey];

  const [asDefault, setAsDefault] = useState(false);
  const [cardHolderName, setCardHolderName] = useState("");
  const [validCard, setIsValidCard] = useState(false);
  const [loading, setLoading] = useState(false);
  const { showError } = useContext(NotificationLayerContext);
  const elements = useElements();
  const stripe = useStripe();
  const [attachPaymentMethod] = useAttachPaymentMethodMutation();

  useEffect(() => {
    setIsNextDisabled(!cardHolderName || !validCard);
  }, [validCard, cardHolderName, setIsNextDisabled]);

  const handleError = (error?: PaymentError) => {
    showError({
      title: PAYMENT_CONSTANTS.CARD_ERROR_TITLE,
      description: error?.message || PAYMENT_CONSTANTS.DEFAULT_ERROR_MESSAGE,
      duration: PAYMENT_CONSTANTS.ERROR_DURATION,
    });
  };

  const handleNewCard = async () => {
    try {
      setLoading(true);

      const billingDetails = getBillingDetailsFromPatient(
        cardHolderName,
        workflowContext.patient
      );

      const result = await addNewPaymentMethod({
        elements,
        stripe,
        billingDetails,
        CardElement,
      });

      if (result.error) {
        handleError(result.error);
        return;
      }

      await attachPaymentMethod({
        customerId: workflowContext.patient?.customerId,
        setAsDefault: asDefault,
        paymentMethodId: result.paymentId,
      }).unwrap();

      updateValues({ selectedPaymentMethod: result.paymentId });
      handleNext();
    } catch (error: unknown) {
      handleError(error as PaymentError);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <AddNewCard
        setAsDefault={setAsDefault}
        asDefault={asDefault}
        setCardHolderName={setCardHolderName}
        setIsValidCard={setIsValidCard}
      />
      <StepperButtons
        backText="BACK"
        confirmText="ADD AND CONTINUE"
        onBack={handleBack}
        onConfirm={handleNewCard}
        loading={loading}
        isConfirmDisabled={isNextDisabled}
      />
    </>
  );
}
