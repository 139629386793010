import React from "react";
import { Grid, Typography } from "@mui/material";
import { ReactComponent as CheckIcon } from "../../Assets/NewIcons/check-icon.svg";
import { StatusBadge } from "../../app/NewComponents/Common/Material/StatusBadge";

export type Task = {
  id: string;
  compositeKey: string;
  message: string;
  type: string;
  patientId?: string;
  employeeId?: string;
  validAfter?: string;
  createdAt: string;
  assignedTo: {
    id: string;
    role: string;
  };
  assignedBy?: {
    id: string;
    role: string;
  } | null;
  document?: {
    documentGroupId: string;
    inviteId: string;
  };
  documents?: {
    documentId: string;
    url: string;
    createdAt: string;
  }[];
  status: string;
  onLeaveDelayed?: boolean;
  histories?: [
    {
      status: string;
      timestamp: number;
      updatedBy: {
        id: string;
        role: string;
      };
    }
  ];
};

export interface TaskInterface {
  render(...params: any): React.ReactNode;

  available(...params: any): boolean;

  action(...params: any): void;
}

export class BaseTask implements TaskInterface {
  task: Task;

  completing = false;

  constructor(task: Task) {
    this.task = task;
  }

  // eslint-disable-next-line class-methods-use-this
  action(...params: any): void {
    console.error("Action method not implemented.");
  }

  render(params?: any): React.ReactNode {
    return (
      <div
        className={`action-items__item${!this.available() ? " checked" : ""}`}
        onClick={() => this.action(params)}
        key={this.task.id}
      >
        <div className="action-items__checkbox">
          <CheckIcon className="action-items__check-icon" />
        </div>
        <Grid container rowGap={1}>
          <Typography className="action-items__text">
            {this.task.message}
          </Typography>
        </Grid>
      </div>
    );
  }

  available(): boolean {
    return this.task.status === "ASSIGNED";
  }
}
