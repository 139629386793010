/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from "react";
import { Provider, useSelector } from "react-redux";
import moment from "moment-timezone";
import { BrowserRouter, useHistory } from "react-router-dom";
import { PDFDownloadLink } from "@react-pdf/renderer";
import isEqual from "lodash/isEqual";
import { Patient } from "../../../../types/Entities/Patient";
import { ReactComponent as ProfileDocument } from "../../../../Assets/icons/profile-document.svg";
import { ReactComponent as EditIcon } from "../../../../Assets/NewIcons/pencil.svg";
import { ReactComponent as PhoneIcon } from "../../../../Assets/NewIcons/phone.svg";
import { ReactComponent as EmailIcon } from "../../../../Assets/NewIcons/email.svg";
import { ReactComponent as WarningIcon } from "../../../../Assets/NewIcons/warning-rounded.svg";
import { ReactComponent as PrintIcon } from "../../../../Assets/NewIcons/print.svg";
import { getSessionState } from "../../../../features/session";
import "./index.scss";
import { HeightAndWeightLine } from "./height-and-weight";
import { PopupLayerContext } from "../PopupLayer";
import EditPatientDetailsPopupGenerator from "../EditPatientDetailsPopup";
import { useGetPatientByIdQuery } from "../../../../features/api/patients";
import { Skeleton } from "../Skeleton";
import PrintPatientInfoPage from "../../../NewPages/PrintPatientInfo";
import store from "../../../../store";
import { useGetFollowUpsByPatientIdQuery } from "../../../../features/api/follow-ups";
import { mapPatientInfoToDashboard } from "../../../../utils/map-user-info-to-dashboard";
import { ENV_CONFIG } from "../../../../config";
import { useGetAllMedicationsQuery } from "../../../../features/api/medications";
import { followupSkinIssues } from "../../../NewPages/Patient/FollowUpSurvey/follow-up-questions";
import { useGetEmployeeQuery } from "../../../../features/api/employees";
import InitialVisitAnswers, { initialVisitAnswers } from "../VisitDetailsPopup";
import { ReactComponent as GoodIcon } from "../../../../Assets/icons/good-icon.svg";
import { ReactComponent as OkIcon } from "../../../../Assets/icons/OkIcon.svg";
import { ReactComponent as BadIcon } from "../../../../Assets/icons/bad-icon.svg";
import { TreatmentAnswer } from "../../../../types/CustomSurvey";

interface FollowUpCardProps {
  editable?: boolean;
}

function PatientInfo({ patient }: { patient: Patient | null }) {
  return (
    <div className="followUp-card__patient-info">
      {!patient ? (
        <Skeleton count={2} flex column />
      ) : (
        <>
          {" "}
          <span className="followUp-card__patient-info__item">
            {patient.medicalBackground?.sex}
          </span>
          <span className="followUp-card__patient-info__splitter" />
          <span className="followUp-card__patient-info__item">
            Age {moment().diff(patient.dateOfBirth, "years")}
          </span>
          <span className="followUp-card__patient-info__splitter" />
          <span className="followUp-card__patient-info__item">
            {patient.dateOfBirth}
          </span>
          <HeightAndWeightLine patient={patient} />
        </>
      )}
    </div>
  );
}

function PatientContacts({ patient }: { patient: Patient | null }) {
  return (
    <div className="followUp-card__patient-contacts">
      <p className="followUp-card__patient-contacts__item">
        <PhoneIcon /> {!patient ? <Skeleton fullWidth /> : patient.phone ?? "-"}
      </p>
      <p className="followUp-card__patient-contacts__item">
        <EmailIcon /> {!patient ? <Skeleton fullWidth /> : patient.email}
      </p>
    </div>
  );
}

function PatientAllergies({ patient }: { patient: Patient | null }) {
  const allergies = patient?.medicalBackground?.skinSurvey?.find(
    ({ id }) => id === "prescriptionAllergies"
  );

  if (!allergies) {
    return null;
  }

  return (
    <div className="followUp-card__allergies">
      <WarningIcon />
      <span>
        {allergies.value.split(",").length} allergies:{" "}
        {allergies.value.split(",")}
      </span>
    </div>
  );
}

function getFeelingIcon(
  feeling: string,
  skinType: any
): { icon: JSX.Element; text: string } {
  switch (feeling) {
    case "Good Day":
      return {
        icon: (
          <GoodIcon className="visit-details-popup__content__item__feeling-icon" />
        ),
        text: `Good day for my ${skinType} in the last 2 weeks`,
      };
    case "Typical Day": {
      return {
        icon: (
          <OkIcon className="visit-details-popup__content__item__feeling-icon" />
        ),
        text: `Typical day for my ${skinType} in the last 2 weeks`,
      };
    }
    case "Bad Day": {
      return {
        icon: (
          <BadIcon className="visit-details-popup__content__item__feeling-icon" />
        ),
        text: `Bad day for my ${skinType} in the last 2 weeks`,
      };
    }
    default: {
      return {
        icon: <div />,
        text: "",
      };
    }
  }
}

const convertBoolValue = (value) => (value === "true" ? "Yes" : "No");

const processAnswers = (questionsArray) =>
  questionsArray.length !== 0 &&
  questionsArray.map((obj) => {
    let description;
    if (obj.type === "bool") {
      description = convertBoolValue(obj.answer);
    } else if (obj.type === "list" && Array.isArray(JSON.parse(obj.answer))) {
      description = JSON.parse(obj.answer).join(", ");
    } else {
      description = obj.answer;
    }
    return { id: obj.id, description };
  });
enum ANSWERS {
  NORMAL = "NORMAL",
  STOPPED = "STOPPED",
  MODIFIED = "MODIFIED",
}

const ANSWER_TO_TEXT = {
  [ANSWERS.NORMAL]: "I use it exactly as directed",
  [ANSWERS.STOPPED]: "I stopped using this",
  [ANSWERS.MODIFIED]: "I modified the amount or the frequency of use",
};

function FollowUpCard({ editable = true }: FollowUpCardProps) {
  const history = useHistory();
  const [patientSkinType, setPatientSkinType] = useState("");
  const [followUpQuestionnaire, setFollowUpQuestionnaire] = useState();
  const [answers, setAnswers] = useState(null);
  const [descriptions, setDescriptions] = useState(null);
  const [isReadyToPrint, setIsReadyToPrint] = useState(false);
  const [initialVisitPrescriptions, setInitialVisitPrescriptions] = useState(
    []
  );
  const { showPopup } = useContext(PopupLayerContext);
  const { userRole, activePatientId } = useSelector(getSessionState);
  const patientQuery = useGetPatientByIdQuery(activePatientId as string, {
    skip: !activePatientId,
    // skip: true,
  });

  const skinSurvey = patientQuery?.currentData?.medicalBackground.skinSurvey;

  const MedicalBackgroundQuestionsSection = mapPatientInfoToDashboard(
    patientQuery?.data
  );

  const followUpsQuery = useGetFollowUpsByPatientIdQuery(
    activePatientId as string,
    {
      skip: !activePatientId,
    }
  );
  /* eslint-disable no-self-compare */
  const followUp = followUpsQuery.data?.find(
    ({ followUpId }) => followUpId === followUpId
  );
  /* eslint-enable no-self-compare */

  const submittedBy = followUp?.treatmentPlan?.submittedBy;
  const employeeQuery = useGetEmployeeQuery(submittedBy as string, {
    skip: !submittedBy,
  });

  const userCanEditPatientInfo =
    userRole === "admins" || userRole === "care-coordinators";

  const editPatientDetailsHandler = () => {
    showPopup(EditPatientDetailsPopupGenerator(patientQuery.data as Patient));
  };

  const followUpQuestions = followUpsQuery?.currentData?.map((followup) => (
    <InitialVisitAnswers
      questionnaire={followup.questionnaire}
      questions={followUpQuestionnaire}
    />
  ));

  const medicationsQuery = useGetAllMedicationsQuery(null);

  useEffect(() => {
    const medicalBackground: any = patientQuery.currentData?.medicalBackground;
    if (medicalBackground) {
      const skinIssueTypeObject = medicalBackground?.skinSurvey?.find(
        (obj) => obj.id === "skinIssueType"
      );

      setPatientSkinType(
        skinIssueTypeObject ? skinIssueTypeObject.value : "acne"
      );
    }
  }, [patientQuery]);

  useEffect(() => {
    if (patientSkinType) {
      const getFollowUpQuestionnaire = followupSkinIssues.find(
        (obj) => obj.value === patientSkinType
      );
      skinSurvey?.forEach((skin) => {
        const patientFollowUp = getFollowUpQuestionnaire?.subQuestions?.find(
          (question) => question.value === skin.id
        );
        if (patientFollowUp)
          patientFollowUp.label = patientFollowUp.label.replace(
            "part from survey",
            `${skin.value}`
          );
      });
      setFollowUpQuestionnaire(getFollowUpQuestionnaire?.subQuestions);
    }
  }, [patientSkinType]);

  const preparedMedicineMap =
    medicationsQuery.data?.reduce(
      (acc: { [p: string]: { value: string; label: string } }, item) => {
        acc[item.id] = {
          value: item.id,
          label: `${item.name}${item.strength ? ` ${item.strength}` : ""}${
            item.size ? `, ${item.size}` : ""
          }`,
        };

        return acc;
      },
      {}
    ) || {};

  const acneFeelingsArray = followUpsQuery?.currentData?.map((item) => {
    const questionnaireData = item.questionnaire;
    const acneFeeling = questionnaireData?.find(
      ({ id }) => id === "acneFeelings"
    );
    return acneFeeling?.value ?? "";
  });

  const processedAcneInfoMessage = acneFeelingsArray?.map((feeling) =>
    getFeelingIcon(feeling, patientSkinType)
  );
  useEffect(() => {
    const prescriptions = followUpsQuery?.data?.map(
      (previousFollowUp) =>
        previousFollowUp?.treatmentPlan?.medications.map((it, index) => {
          const medicationFromList = medicationsQuery?.data?.find(
            (medication) => medication.id === it.medicineKey
          );

          const treatmentSurveyAnswer = previousFollowUp.questionnaire?.find(
            ({ id }) => id === "treatmentSurvey"
          );

          let treatmentSurvey;

          try {
            treatmentSurvey = treatmentSurveyAnswer
              ? JSON.parse(treatmentSurveyAnswer.value)
              : null;
          } catch (error) {
            console.error("Error parsing JSON:", error.message);
          }

          const hasTreatmentSurvey: TreatmentAnswer | null =
            treatmentSurvey?.[index] ?? null;

          return {
            title: medicationFromList?.name || "",
            strength: medicationFromList?.strength || null,
            content: hasTreatmentSurvey
              ? ANSWER_TO_TEXT[hasTreatmentSurvey.answer]
              : "",
            description: hasTreatmentSurvey?.comment ?? "",
            whenToUse: it?.instructions ?? [],
            instruction: it?.specialInstructions ?? "",
          };
        }) ?? []
    );

    setInitialVisitPrescriptions(prescriptions);
  }, [followUpsQuery, medicationsQuery]);
  useEffect(() => {
    if (
      followUpsQuery?.currentData?.length > 0 &&
      followUpQuestions?.length > 0 &&
      MedicalBackgroundQuestionsSection?.length > 0
    ) {
      setIsReadyToPrint(true);
    }
  }, [
    followUpsQuery?.currentData?.length,
    followUpQuestions?.length,
    MedicalBackgroundQuestionsSection?.length,
  ]);
  useEffect(() => {
    const combinedArray = followUpQuestions?.map(({ props }) => {
      const { questionnaire, questions } = props;
      const result = [];
      if (questionnaire && questions) {
        questionnaire?.forEach((questionnaireItem) => {
          const matchingQuestion = questions.find(
            (question) => question.value === questionnaireItem.id
          );
          if (!matchingQuestion) return;
          if (
            matchingQuestion.parentQuestion ||
            matchingQuestion.value === "treatmentSurvey"
          ) {
            return;
          }
          if (
            !matchingQuestion.hasChild ||
            matchingQuestion.hasChild !== true
          ) {
            result.push({
              question: matchingQuestion.label,
              answer: questionnaireItem.value,
              type: questionnaireItem.type,
            });
          } else {
            const childQuestion = questionnaire.find(
              (child) => child.id === matchingQuestion.childId
            );
            if (childQuestion) {
              result.push({
                question: matchingQuestion.label,
                answer: childQuestion.value,
                type: childQuestion.type,
              });
            } else {
              const question = questionnaire.find(
                (child) => child.id === matchingQuestion.value
              );
              result.push({
                question: matchingQuestion.label,
                answer: question.value,
                type: question.type,
              });
            }
          }
        });
      }
      return { index: result };
    });
    setAnswers((prevAnswer) => {
      if (!isEqual(prevAnswer, combinedArray)) {
        return combinedArray;
      }
      return prevAnswer;
    });
  }, [followUpQuestions]);
  useEffect(() => {
    if (answers?.length > 0) {
      const processedDescriptions = answers.map((answerGroup) => ({
        index: answerGroup.index,
        descriptions: processAnswers(answerGroup.index),
      }));
      setDescriptions(processedDescriptions);
    }
  }, [answers]);

  return (
    <div className="followUp-card">
      <div className="followUp-card__header">
        <ProfileDocument className="followUp-card__header__avatar" />
        <div>
          {patientQuery.isSuccess && editable && userCanEditPatientInfo && (
            <EditIcon
              onClick={editPatientDetailsHandler}
              className="followUp-card__header__edit"
            />
          )}
          {followUpsQuery?.currentData?.length > 0 &&
            patientQuery.isSuccess &&
            userCanEditPatientInfo &&
            (!isReadyToPrint ? (
              <p>Loading...</p>
            ) : (
              <PDFDownloadLink
                fileName={`Medical-Background-of-${patientQuery.data?.fullName}.pdf`}
                document={
                  <PrintPatientInfoPage
                    patientQuery={patientQuery}
                    followUpsQuery={followUpsQuery}
                    QuestionsSection={MedicalBackgroundQuestionsSection}
                    descriptions={descriptions}
                    preparedMedicineMap={preparedMedicineMap}
                    employeeQuery={employeeQuery}
                    acneFeelingText={processedAcneInfoMessage}
                    initialVisitPrescriptions={initialVisitPrescriptions}
                  />
                }
                style={{ marginRight: "20px" }}
              >
                {({ loading }) =>
                  loading ? (
                    <p>Loading...</p>
                  ) : (
                    <PrintIcon className="followUp-card__header__print" />
                  )
                }
              </PDFDownloadLink>
            ))}
        </div>
      </div>
      <p className="followUp-card__full-name">
        {!patientQuery.isSuccess || patientQuery.isLoading ? (
          <Skeleton />
        ) : (
          patientQuery.data.fullName
        )}
      </p>
      <PatientInfo patient={patientQuery.data || null} />
      <PatientContacts patient={patientQuery.data || null} />
      <PatientAllergies patient={patientQuery.data || null} />
    </div>
  );
}

export default FollowUpCard;
/* eslint-enable react/prop-types */
