import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { CognitoUserPool } from "amazon-cognito-identity-js";
import { ENV_CONFIG } from "../config";
import { RealTimeNotification } from "../utils/real-time";

const poolData = {
  UserPoolId: ENV_CONFIG.USER_POOL_ID,
  ClientId: ENV_CONFIG.CLIENT_ID,
};

export type WebSocketContext = {
  notifications: any[];
  registerHandler: (handler: (message: any) => void) => void;
  deregisterHandler: (handler: (message: any) => void) => void;
};

const WebSocketContext = createContext<WebSocketContext>({
  notifications: [],
  registerHandler: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
  deregisterHandler: () => {}, // eslint-disable-line @typescript-eslint/no-empty-function
});

function WebSocketProvider({ children }: { children: ReactNode }) {
  const userPool = new CognitoUserPool(poolData);
  const [notifications, setNotifications] = useState<RealTimeNotification[]>(
    []
  );
  const [token, setToken] = useState<string | null>(null);
  const wsRef = useRef(null);
  const handlersRef = useRef<((notification: RealTimeNotification) => void)[]>(
    []
  );

  const getToken = async () => {
    const cognitoUser = userPool.getCurrentUser();

    if (!cognitoUser) {
      console.error("No user is currently signed in.");
      return null;
    }

    return new Promise((resolve, reject) => {
      cognitoUser.getSession((error, session) => {
        if (error) {
          console.error("Error retrieving session:", error);
          reject(error);
          return;
        }

        const idToken = session.getIdToken().getJwtToken();
        resolve(idToken);
      });
    });
  };

  const getWebsocketUrl = () => {
    const creds = {
      host: `${ENV_CONFIG.NOTIFICATION_SERVICE_GRAPHQL_URL.replace(
        "https://",
        ""
      ).replace("/graphql", "")}`,
      Authorization: token,
      "Sec-WebSocket-Protocol": "graphql-ws",
    };

    const header = window.btoa(JSON.stringify(creds));
    const payload = window.btoa(JSON.stringify({}));
    return `${ENV_CONFIG.WEBSOCKET_URL}?header=${header}&payload=${payload}`;
  };

  const startSubscription = () => {
    if (!wsRef.current) return;

    const subscribeMessage = {
      id: window.crypto.randomUUID(),
      type: "start",
      payload: {
        data: JSON.stringify({
          query: `subscription sendNotification {
              sendNotification {
                id
                message
                type
                timestamp
                recipientIds
                roles
                broadcast
              }
            }`,
        }),
        extensions: {
          authorization: {
            Authorization: token,
          },
        },
      },
    };
    wsRef.current?.send(JSON.stringify(subscribeMessage));
    console.log("Subscription started", subscribeMessage);
  };

  const handleNotification = (notification: RealTimeNotification) => {
    handlersRef.current?.forEach((handler) => handler?.(notification));
    setNotifications((prev) => [...prev, notification]);
  };

  useEffect(() => {
    getToken()
      .then((_token) => {
        setToken(_token as string);
      })
      .catch((error) => {
        console.error("Failed to retrieve ID Token:", error);
      });
  }, []);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (token) {
      const url = getWebsocketUrl();

      const ws = new WebSocket(url, ["graphql-ws"]);

      ws.addEventListener("open", () => {
        ws.send(
          JSON.stringify({
            type: "connection_init",
          })
        );
      });

      ws.addEventListener("message", (event) => {
        const message = JSON.parse(event.data);
        switch (message.type) {
          case "connection_ack":
            startSubscription();
            break;
          case "start_ack":
            console.log("Subscription acknowledged");
            break;
          case "error":
            console.error("WebSocket error:", message);
            break;
          case "data":
            handleNotification(message.payload.data?.sendNotification);
            break;
          default:
            break;
        }
      });

      ws.addEventListener("close", () => {
        console.log("WebSocket connection closed.");
      });

      wsRef.current = ws;

      return () => {
        if (wsRef.current) {
          wsRef.current.close();
          wsRef.current = null;
        }
      };
    }
  }, [token]);

  const registerHandler = (
    handler: (notification: RealTimeNotification) => void
  ) => {
    handlersRef.current.push(handler);
  };

  const deregisterHandler = (
    handler: (notification: RealTimeNotification) => void
  ) => {
    handlersRef.current = handlersRef.current.filter((h) => h !== handler);
  };

  const memoizedValue = useMemo(
    () => ({
      notifications,
      registerHandler,
      deregisterHandler,
    }),
    [notifications]
  );
  return (
    <WebSocketContext.Provider value={memoizedValue}>
      {children}
    </WebSocketContext.Provider>
  );
}

const useWebSocket = () => useContext(WebSocketContext);

export { WebSocketProvider, useWebSocket };
