import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ENV_CONFIG } from "../../config";
import { AuthenticationService } from "../../services/cognito";
import { Accutane } from "../../types/Entities/Accutane";
import { ProviderTask } from "../../types/Provider";

interface EditIpledge {
  patientId: string;
  enrollmentDate: string;
  lastConfirmationDate: string;
  nextConfirmationDate: string;
  remsNumber: string;
  employeeId: string;
}

export interface DocumentImageFileProps {
  filename: string;
  contentType: string;
  source: string;
}

interface Document extends DocumentImageFileProps {
  title: string | null; // todo should not be nullable
  uploadedBy: {
    id: string | null;
    name: string | null;
  };
  assignedTo?: {
    id: string;
    name: string;
    role: string;
  } | null;
  assignedBy?: {
    id: string;
    role: string;
  };
  signedBy?: {
    id: string;
    name: string;
    role: string;
    createdAt: string;
  };
  taskName: string | null;
  comment: string;
  type: string;
}

type AddDocumentComment = {
  documentId: string;
  comment: string;
  patientId: string;
  signedBy: { id: string; name: string; role: string; createdAt: string };
};

interface AccutaneTask {
  message: string;
  type: string;
  createdAt: string;
  patientId: string;
  assignedTo: {
    id: string | undefined;
    role: string | undefined;
    name: string | undefined;
  };
  assignedBy: {
    id: string;
    role: string;
  };
  employeeId?: string | null;
  documentId?: string;
}
export interface AccutaneDocument {
  id: string;
  title: string;
  uploadedBy: {
    id: string;
    name: string;
  };
  assignedTo: {
    id: string;
    name: string;
  };
  assignedBy?: {
    id: string;
    role: string;
  };
  createdAt: string;
  compositeKey: string;
  signedBy: {
    id: string;
    name: string;
    role: string;
    createdAt: string;
  };
  comment: string;
  type: string;
  url: string;
}

export const accutaneApiSlice = createApi({
  reducerPath: "api/accutane",
  tagTypes: ["accutane", "documents", "tasks"],
  baseQuery: fetchBaseQuery({
    baseUrl: ENV_CONFIG.ACCUTANE_API_PATH,
    prepareHeaders: async (headers) => {
      const token = await AuthenticationService.getAccessToken();
      headers.set("Authorization", token);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAccutaneByPatientId: builder.query<Accutane | null, string>({
      query: (patientId: string) => ({
        url: `/${patientId}`,
      }),
      transformResponse: (response: any) => response.body,
      providesTags: (response) =>
        response
          ? [{ type: "accutane", id: response.patientId }]
          : [{ type: "accutane" }],
    }),
    getTasks: builder.query<
      ProviderTask[],
      {
        patientId: string;
        employeeIds?: [];
        documentId?: string;
        taskId?: string;
      }
    >({
      query: ({ patientId, employeeIds, documentId, taskId }) => ({
        url: `/get-tasks/${patientId}`,
        params: {
          employeeIds,
          documentId,
          taskId,
        },
      }),
      transformResponse: (response: any) => response.tasks,
      providesTags: (response) =>
        response?.length
          ? response.map(({ id }) => ({ type: "tasks", id }))
          : [{ type: "tasks" }],
    }),
    getDocumentsByPatientId: builder.query<AccutaneDocument[], string>({
      query: (patientId: string) => ({
        url: `/get-documents/${patientId}`,
      }),
      transformResponse: (response: any) => response.body,
      providesTags: (response) =>
        response?.length
          ? response.map((document) => ({ type: "documents", id: document.id }))
          : [{ type: "documents" }],
    }),
    initiateAccutane: builder.mutation<Accutane, string>({
      query: (patientId) => ({
        method: "POST",
        url: `/initiate/${patientId}`,
      }),
      transformResponse: (response: any) => response,
      invalidatesTags: (response) => (response ? [{ type: "accutane" }] : []),
    }),
    confirmPregnancyPrevention: builder.mutation<
      Accutane,
      {
        patientId: string;
        primaryForm: string;
        secondaryForm?: string;
      }
    >({
      query: ({ patientId, primaryForm, secondaryForm }) => ({
        method: "POST",
        url: `/add-birth-control/${patientId}`,
        body: {
          primaryForm,
          secondaryForm,
        },
      }),
      transformResponse: (response: any) => response.accutane,
      invalidatesTags: (response) =>
        response ? [{ type: "accutane", id: response.patientId }] : [],
    }),
    generateDocument: builder.mutation<
      null,
      { patientId: string; documentGroupName: string }
    >({
      query: ({ patientId, documentGroupName }) => ({
        method: "POST",
        url: `/document-group-from-templates/${patientId}`,
        body: {
          documentGroupName,
        },
      }),
      transformResponse: (response: any) => response,
      invalidatesTags: (response) => [{ type: "accutane" }],
    }),
    generateIpledgeLinks: builder.mutation<
      null,
      { patientId: string; documentGroupId: string; role: string }
    >({
      query: ({ patientId, documentGroupId, role }) => ({
        method: "POST",
        url: `/ipledge-embedded-link/${patientId}`,
        body: {
          documentGroupId,
          role,
        },
      }),
      transformResponse: (response: any) => response,
      invalidatesTags: (response) => [{ type: "accutane" }],
    }),
    assignTask: builder.mutation<null, AccutaneTask>({
      query: (payload) => ({
        method: "POST",
        url: `/assign-task`,
        body: payload,
      }),
      transformResponse: (response: any) => response,
      invalidatesTags: (response) => [{ type: "tasks" }],
    }),
    completeTask: builder.mutation<
      null,
      { taskId: string; compositeKey: string; patientId: string }
    >({
      query: ({ taskId, compositeKey, patientId }) => ({
        method: "POST",
        url: `/complete-task/${taskId}`,
        body: { compositeKey, patientId },
      }),
      transformResponse: (response: any) => response,
      invalidatesTags: (response) => [{ type: "tasks" }],
    }),
    uploadDocument: builder.mutation<
      null,
      { patientId: string; document: Document }
    >({
      query: ({ patientId, document }) => ({
        method: "POST",
        url: `/upload-document/${patientId}`,
        body: { document },
      }),
      transformResponse: (response: any) => response,
      invalidatesTags: (response) => [
        { type: "documents" },
        { type: "accutane" },
        { type: "tasks" },
      ],
    }),
    getPresignedUrl: builder.mutation<
      null,
      { patientId: string; document: Document }
    >({
      query: ({ patientId, document }) => ({
        method: "POST",
        url: `/get-presigned-url/${patientId}`,
        body: { document },
      }),
      transformResponse: (response: any) => response,
      invalidatesTags: (response) => [{ type: "documents" }],
    }),
    addDocumentComment: builder.mutation<null, AddDocumentComment>({
      query: ({ comment, documentId, signedBy, patientId }) => ({
        method: "POST",
        url: `/add-document-comment/${documentId}`,
        body: {
          comment,
          patientId,
          signedBy,
        },
      }),
      transformResponse: (response: any) => response,
      invalidatesTags: (response) => [{ type: "documents" }],
    }),
    setRemNumber: builder.mutation<
      null,
      { patientId: string; remsNumber: string }
    >({
      query: ({ patientId, remsNumber }) => ({
        method: "POST",
        url: `/set-rems-number/${patientId}`,
        body: {
          remsNumber,
        },
      }),
      transformResponse: (response: any) => response,
      invalidatesTags: (response) => [{ type: "accutane" }],
    }),
    enrollAccutane: builder.mutation<
      null,
      { patientId: string; taskId: string }
    >({
      query: ({ patientId, taskId }) => ({
        method: "POST",
        url: `/enroll/${patientId}`,
        body: {
          taskId,
        },
      }),
      transformResponse: (response: any) => response,
      invalidatesTags: (response) => [{ type: "accutane" }, { type: "tasks" }],
    }),
    confirmIpledge: builder.mutation<
      null,
      { patientId: string; employeeId: string }
    >({
      query: ({ patientId, employeeId }) => ({
        method: "POST",
        url: `/confirm-ipledge/${patientId}`,
        body: {
          employeeId,
        },
      }),
      transformResponse: (response: any) => response,
      invalidatesTags: (response) => [{ type: "accutane" }],
    }),
    endIsotretinoin: builder.mutation<null, string>({
      query: (patientId) => ({
        method: "POST",
        url: `/end/${patientId}`,
        body: {
          status: "stop",
        },
      }),
      transformResponse: (response: any) => response,
      invalidatesTags: (response) => [{ type: "accutane" }],
    }),
    pauseIsotretinoin: builder.mutation<
      null,
      { patientId: string; daysAmount: number }
    >({
      query: ({ patientId, daysAmount }) => ({
        method: "POST",
        url: `/pause/${patientId}`,
        body: {
          daysAmount,
        },
      }),
      transformResponse: (response: any) => response,
      invalidatesTags: (response) => [{ type: "accutane" }],
    }),
    populateBloodSlipPdf: builder.mutation<
      null,
      {
        patientId: string;
        documentName: string;
        documentId: string;
        fields: { field_name: string; prefilled_text: string }[];
        isDefaultData?: boolean;
      }
    >({
      query: ({
        patientId,
        documentName,
        documentId,
        isDefaultData,
        fields,
      }) => ({
        method: "POST",
        url: `/populate-blood-slip/${documentId}`,
        body: {
          patientId,
          documentName,
          isDefaultData,
          fields,
        },
      }),
      transformResponse: (response: any) => response,
      invalidatesTags: (response) => [{ type: "accutane" }],
    }),
    deleteDocument: builder.mutation<
      null,
      { patientId: string; documentId: string }
    >({
      query: ({ patientId, documentId }) => ({
        method: "POST",
        url: `/delete-document/${documentId}`,
        body: {
          patientId,
        },
      }),
      transformResponse: (response: any) => response,
      invalidatesTags: (response) => [{ type: "tasks" }, { type: "documents" }],
    }),
    signBloodSlip: builder.mutation<
      null,
      {
        taskId: string;
        compositeKey: string;
        documentGroupId: string;
        inviteId: string;
        patientId: string;
      }
    >({
      query: ({
        patientId,
        taskId,
        compositeKey,
        documentGroupId,
        inviteId,
      }) => ({
        method: "POST",
        url: `/sign-blood-slip/${patientId}`,
        body: {
          taskId,
          recordId: compositeKey,
          documentGroupId,
          inviteId,
        },
      }),
      transformResponse: (response: any) => response,
      invalidatesTags: (response) => [{ type: "tasks" }, { type: "documents" }],
    }),
    addBloodWork: builder.mutation<null, string>({
      query: (patientId) => ({
        method: "POST",
        url: `/add-blood-work/${patientId}`,
      }),
      transformResponse: (response: any) => response,
      invalidatesTags: (response) => [{ type: "accutane" }],
    }),
    updateIpledgeDetails: builder.mutation<null, EditIpledge>({
      query: ({
        patientId,
        enrollmentDate,
        lastConfirmationDate,
        nextConfirmationDate,
        remsNumber,
        employeeId,
      }) => ({
        method: "POST",
        url: `/update-ipledge-details/${patientId}`,
        body: {
          enrollmentDate,
          lastConfirmationDate,
          nextConfirmationDate,
          remsNumber,
          employeeId,
        },
      }),
      transformResponse: (response: any) => response,
      invalidatesTags: (response) => [{ type: "accutane" }],
    }),
    resetIpledgeConsent: builder.mutation<null, { patientId: string }>({
      query: ({ patientId }) => ({
        method: "POST",
        url: `/reset-ipledge-consent/${patientId}`,
      }),
      transformResponse: (response: any) => response,
      invalidatesTags: (response) => [{ type: "accutane" }],
    }),
    acceptEnrollAccutane: builder.mutation<
      null,
      { patientId: string; taskId: string }
    >({
      query: ({ patientId, taskId }) => ({
        method: "POST",
        url: `/accept-enroll/${patientId}`,
        body: {
          taskId,
        },
      }),
      transformResponse: (response: any) => response,
      invalidatesTags: (response) => [{ type: "accutane" }, { type: "tasks" }],
    }),
    deleteTask: builder.mutation<
      null,
      {
        taskId: string;
        compositeKey: string;
        deletedBy: string;
        patientId: string;
      }
    >({
      query: ({ taskId, compositeKey, deletedBy, patientId }) => ({
        method: "POST",
        url: `/delete-task/${taskId}`,
        body: { compositeKey, deletedBy, patientId },
      }),
      transformResponse: (response: any) => response,
      invalidatesTags: (response) => [{ type: "tasks" }],
    }),
    updateTaskStatus: builder.mutation<
      null,
      {
        taskId: string;
        compositeKey: string;
        updatedBy: any;
        patientId: string;
        status: string;
      }
    >({
      query: ({ taskId, compositeKey, updatedBy, patientId, status }) => ({
        method: "POST",
        url: `/update-task/${taskId}`,
        body: { compositeKey, updatedBy, patientId, status },
      }),
      transformResponse: (response: any) => response,
      invalidatesTags: (response) => [{ type: "tasks" }],
    }),
    removePatientTask: builder.mutation<
      Accutane,
      {
        patientId: string;
        taskName: string;
        flagName?: string;
        modalName?: string;
      }
    >({
      query: ({ patientId, taskName, flagName, modalName }) => ({
        method: "POST",
        url: `/remove-patient-task/${patientId}`,
        body: {
          taskName,
          flagName,
          modalName,
        },
      }),
      transformResponse: (response: any) => response.accutane,
      invalidatesTags: (response) =>
        response ? [{ type: "accutane", id: response.patientId }] : [],
    }),
    sendMinorConsentToParent: builder.mutation<
      null,
      {
        patientId: string;
        emailToSend: string;
        employeeId: string;
        employeeRole: string;
      }
    >({
      query: ({ patientId, emailToSend, employeeId, employeeRole }) => ({
        method: "POST",
        url: `/send-minor-consent-form/${patientId}`,
        body: {
          email: emailToSend,
          employeeId,
          employeeRole,
        },
      }),
    }),
  }),
});

export const {
  useGetAccutaneByPatientIdQuery,
  useGetDocumentsByPatientIdQuery,
  useGetTasksQuery,
  useInitiateAccutaneMutation,
  useConfirmPregnancyPreventionMutation,
  useGenerateDocumentMutation,
  useGenerateIpledgeLinksMutation,
  useAssignTaskMutation,
  useCompleteTaskMutation,
  useAddDocumentCommentMutation,
  useUploadDocumentMutation,
  useSetRemNumberMutation,
  useEnrollAccutaneMutation,
  useConfirmIpledgeMutation,
  useEndIsotretinoinMutation,
  usePauseIsotretinoinMutation,
  usePopulateBloodSlipPdfMutation,
  useDeleteDocumentMutation,
  useSignBloodSlipMutation,
  useAddBloodWorkMutation,
  useUpdateIpledgeDetailsMutation,
  useGetPresignedUrlMutation,
  useResetIpledgeConsentMutation,
  useAcceptEnrollAccutaneMutation,
  useDeleteTaskMutation,
  useUpdateTaskStatusMutation,
  useRemovePatientTaskMutation,
  useSendMinorConsentToParentMutation,
} = accutaneApiSlice;
