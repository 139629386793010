import PubNub from "pubnub";
import { v4 as uuidv4 } from "uuid";
import { ENV_CONFIG } from "../../config";

export function pubnubClient({ userId, pubNubSubscribeKey }) {
  return new PubNub({
    publishKey: ENV_CONFIG.PUBNUB_PUBLISH_KEY,
    subscribeKey: pubNubSubscribeKey,
    userId,
  });
}
