import { AuthenticationService } from "../../cognito";
import { ENV_CONFIG } from "../../../config";
import { logError } from "../../../shared/logger";

interface UtmValue {
  key: string;
  value: string;
}

export const storeUTM = async ({
  utmValues,
  patientId,
}: {
  utmValues: UtmValue[];
  patientId: string;
}): Promise<any[]> => {
  try {
    const token = await AuthenticationService.getAccessToken();

    const result = await fetch(`${ENV_CONFIG.MARKETING_SERVICE_GRAPHQL_URL}`, {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: `
            mutation storeUTMParametersForPatient($patientId: ID!, $utm_values: [UTMParametersInput]!) {
              storeUTMParametersForPatient(patientId: $patientId, utmParameters: $utm_values) {
                nothing
              }
            }
          `,
        variables: {
          patientId,
          utm_values: utmValues,
        },
      }),
    });

    if (result.data?.errors) {
      throw new Error(result.data.errors[0].message);
    }

    return result.data?.storeUTMParametersForPatient;
  } catch (error: any) {
    logError("Error occurred. Cannot store UTM parameters", error);
    throw error;
  }
};
