import React, { useContext, useEffect, useState } from "react";
import { Dialog, Grid, Typography } from "@mui/material";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useHistory } from "react-router-dom";
import { ReactComponent as ClockIcon } from "./clock.svg";
import AddNewCard from "../../../../NewComponents/Common/Payment/AddNewCard";
import { Button } from "../../../../NewComponents/Common/Material/Button";
import { addNewPaymentMethod } from "../../../../../utils/stripe/add-new-card";
import { useAttachPaymentMethodMutation } from "../../../../../features/api/payment";
import { NotificationLayerContext } from "../../../../NewComponents/Common/NotificationLayer";
import { Patient } from "../../../../../types/Entities/Patient";
import {
  sendCardSavedEvent,
  sendPaywallCompleted,
} from "../../../../../shared/analytics";

const providers = [
  "https://acneaway-user-images.s3.us-east-1.amazonaws.com/providers/asma_ahmed.png",
  "https://acneaway-user-images.s3.amazonaws.com/providers/joel_spitz_square.jpeg",
  "https://acneaway-user-images.s3.amazonaws.com/providers/jessica-cheng.jpg",
  "https://acneaway-user-images.s3.amazonaws.com/providers/erica-colton.jpg",
  "https://acneaway-user-images.s3.amazonaws.com/providers/whitney_talbott_square.jpeg",
  "https://acneaway-user-images.s3.amazonaws.com/providers/emily-lee.png",
];

interface Props {
  patient: Patient;
  onSuccess: () => void;
}

export function Paywall({ patient, onSuccess }: Props) {
  const [showOverlay, setShowOverlay] = React.useState(false);
  const [timeLeft, setTimeLeft] = useState(420);
  const [isRunning, setIsRunning] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cardHolderName, setCardHolderName] = useState("");
  const [attachPaymentMethod] = useAttachPaymentMethodMutation();
  const history = useHistory();
  const { showError, showSuccess } = useContext(NotificationLayerContext);
  const [validCard, setIsValidCard] = useState(false);
  const elements = useElements();
  const stripe = useStripe();

  useEffect(() => {
    setTimeout(() => {
      setShowOverlay(true);
      setIsRunning(true);
    }, 200);
  }, []);

  useEffect(() => {
    if (isRunning && timeLeft === 0) {
      history.go(0);
    }
    if (!isRunning || timeLeft <= 0) return;

    const _timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);
    // eslint-disable-next-line consistent-return
    return () => clearInterval(_timer); // Cleanup interval on unmount
  }, [isRunning, timeLeft]);

  // Format timeLeft as mm:ss
  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${String(secs).padStart(2, "0")}`;
  };

  const handleNewCard = async () => {
    try {
      setLoading(true);
      const result = await addNewPaymentMethod({
        elements,
        stripe,
        billingDetails: {
          name: cardHolderName,
          email: patient.email,
          address: undefined,
        },
        CardElement,
      });

      if (!result.error) {
        await attachPaymentMethod({
          customerId: patient?.customerId,
          paymentMethodId: result.paymentId,
          setAsDefault: true,
        });
        showSuccess({
          title: "Card Information Saved",
          description:
            "Your card details have been securely stored. You can now proceed to book your appointment.",
        });
        sendCardSavedEvent(patient.patientId);
        sendPaywallCompleted({
          experiment: { variation: true },
          userId: patient.accountId,
        });
        onSuccess();
      }
    } catch (e) {
      showError({
        title: "Add payment failed",
        description: "Please try again or use a different card.",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog
      open={showOverlay}
      PaperProps={{
        sx: {
          height: "80%",
          borderRadius: "8px",
          padding: "16px",
        },
      }}
    >
      <Typography fontSize={32} fontWeight={700}>
        Last step! We need a credit card on file to hold your spot.
      </Typography>
      <Typography fontSize={16} color="#6C6A7C" mt={2} mb={2}>
        We’ll only use this if you miss your appointment without cancelling at
        least 4 hours before ($25 no-show fee).
      </Typography>
      <Grid container mb={2}>
        <Grid
          item
          style={{
            backgroundColor: "#FBFBFE",
            border: "1px solid #DFD7FD",
            borderRadius: "8px",
            marginTop: "16px",
            padding: "16px",
          }}
          container
          rowGap={1}
          justifyContent="space-between"
          mb={2}
        >
          <Grid item>
            <Typography fontWeight={500} fontSize={16}>
              Initial Consultation
            </Typography>
          </Grid>
          <Grid
            item
            display="flex"
            alignItems="center"
            style={{
              borderRadius: "24px",
              backgroundColor: "#F3FAF2",
              color: "#60B257",
              padding: "0px 6px",
            }}
          >
            <ClockIcon width={16} height={16} />
            <Typography style={{ width: "35px" }}>
              {formatTime(timeLeft)}
            </Typography>
          </Grid>
          <Typography fontSize={14} fontWeight={400} color="#6C6A7C">
            You’ll meet via Zoom with one of our licensed dermatology providers
          </Typography>
          <Grid item container columnGap={1}>
            {providers.map((provider) => (
              <img
                src={provider}
                alt="provider"
                style={{
                  width: "32px",
                  height: "32px",
                  borderRadius: "8px",
                  objectFit: "cover",
                }}
              />
            ))}
          </Grid>
        </Grid>
        <AddNewCard
          setCardHolderName={setCardHolderName}
          setIsValidCard={setIsValidCard}
          padding={0}
        />
        <Typography
          fontSize={12}
          fontWeight={400}
          color="#6C6A7C"
          mb={2}
          mt={2}
        >
          By providing your card information, you allow Honeydew to charge your
          card for future payments in accordance with their terms.
        </Typography>
        <Button
          onClick={() => history.go(0)}
          text="Back"
          size="small"
          style={{ fontSize: "12px" }}
        />
        <Button
          onClick={() => handleNewCard()}
          text="CONFIRM APPOINTMENT"
          size="small"
          disabled={!validCard || !cardHolderName || loading}
          isLoading={loading}
          style={{ marginLeft: "16px", fontSize: "12px" }}
        />
      </Grid>
    </Dialog>
  );
}
