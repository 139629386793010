import React, { useContext, useEffect, useMemo, useState } from "react";
import { Autocomplete } from "@mui/material";
import { useSelector } from "react-redux";
import { ActionPopupTemplate } from "../../../Components/Common/ActionPopup";
import { Button } from "../Button";
import "./style.scss";
import { StyledSelectCreatable } from "../StyledSelect";
import { Input } from "../Input";
import { NotificationLayerContext } from "../NotificationLayer";
import DateSelect from "../DateSelect";
import { Patient } from "../../../../types/Entities/Patient";
import { Loader } from "../Loader";
import { getSessionState } from "../../../../features/session";
import { Provider } from "../../../../types/Employee";
import { ENV_CONFIG } from "../../../../config";
import { getLabCatalog } from "../../../../services/honeydew-api/labs/get-lab-catalog";
import { getLabPresets } from "../../../../services/honeydew-api/labs/get-lab-presets";
import { AutocompleteInput } from "../Material/AutocompleteInput";
import { createLabOrder } from "../../../../services/honeydew-api/labs/create-lab-order";
import { getToday } from "../../../../utils/dates";
import { parseFieldsToPdf } from "../../../../utils/lab-work/parse-fields-to-pdf";
import { getFormFieldsByTemplateOption } from "../../../../utils/lab-work/get-fields-from-option";
import { useFeatureToggle } from "../../../../contexts/feature-toggle";
import { FEATURES } from "../../../../data/available-features";
import { usePopulateBloodSlipPdfMutation } from "../../../../features/api/accutane";
import { getDocumentName } from "../../../../utils/lab-work/get-document-name";
import { LabCatalog } from "../../../../types/LabCatalog";
import { isPregnancyOnly } from "../../../../utils/lab-work/is-pregnancy-only";
import {
  LAB_WORK_TASK_TITLE,
  PREGNANCY_TASK_TITLE,
} from "../../../../utils/lab-work/constants";

interface Props {
  onClose: () => void;
}

interface SendBloodSlipData {
  patient: Patient;
  provider: Provider;
  isImmediate?: boolean;
}

interface SendBloodSlipProps extends Props {
  patient: Patient;
  provider: Provider;
  isImmediate?: boolean;
}

interface FieldStructure {
  field_name: string;
  prefilled_text: string;
}

interface BloodSlipPopupSectionProps {
  title: string;
  description: string;
  fields: any[];
}
interface Option {
  readonly label: string;
  readonly value: string;
}
// TODO extract to separate component for popups
function BloodSlipPopupSection({
  title,
  description,
  fields,
}: BloodSlipPopupSectionProps) {
  return (
    <div className="blood-slip-popup__content__section">
      <div className="blood-slip-popup__content__section__head">
        <p className="blood-slip-popup__content__section__head__title">
          {title}
        </p>
        <p className="blood-slip-popup__content__section__head__description">
          {description}
        </p>
      </div>
      <div className="blood-slip-popup__content__section__fields">
        {fields.map((field) => (
          <div
            className="blood-slip-popup__content__section__fields__field"
            key={`${title}__${description}`}
          >
            {field}
          </div>
        ))}
      </div>
    </div>
  );
}

/**
 * Lipid Profile, CMP - if can not get pregnant
 * Lipid Profile, CMP, HCG - if can get pregnant
 */
export enum TestsOrderValues {
  LIPID_PROFILE = "Lipid Profile",
  HEPATIC_PANEL = "Hepatic Panel",
  HCG = "Qualitative (Urine) HCG test. If patient declines, Quantitative (blood) HCG is acceptable",
  CBC = "CBC",
  CMP = "CMP",
  PSA = "PSA",
}

export enum TemplatesList {
  MALE_PATTERN_HAIR_LOSS = "MALE_PATTERN_HAIR_LOSS",
  FEMALE_FIRST_PREGNANCY = "FEMALE_FIRST_PREGNANCY",
  FEMALE_BASELINE_BLOODWORK = "FEMALE_BASELINE_BLOODWORK",
  MALE_BASELINE_OR_ANYONE_2_MONTH = "MALE_BASELINE_OR_ANYONE_2_MONTH",
  FEMALE_CANNOT_GET_PREGNANT_OVER_54 = "FEMALE_CANNOT_GET_PREGNANT_OVER_54",
  FEMALE_CANNOT_GET_PREGNANT_UNDER_54 = "FEMALE_CANNOT_GET_PREGNANT_UNDER_54",
}

export function SendBloodSlipPopup({
  onClose,
  patient,
  provider,
  isImmediate,
}: SendBloodSlipProps) {
  const { showSuccess, showError } = useContext(NotificationLayerContext);
  const state = useSelector(getSessionState);
  const [catalog, setCatalog] = useState<LabCatalog[]>([]);
  const [presets, setPresets] = useState<any>([]);
  const [fields, setFields] = useState();
  const [shouldShowCreation, setShowCreation] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [documentName, setDocumentName] = useState("");
  const { isFeatureEnabled } = useFeatureToggle();
  const enabled = useMemo(() => isFeatureEnabled(FEATURES.VITAL), []);
  const [populatePdf] = usePopulateBloodSlipPdfMutation();

  const getTests = async () => {
    const labCatalogQuery = getLabCatalog();
    const labPresetsQuery = getLabPresets();
    const [labCatalog, labPresets] = await Promise.all([
      labCatalogQuery,
      labPresetsQuery,
    ]);
    setPresets(
      labPresets.map((preset) => ({
        label: preset.name,
        value: preset.id,
        type: preset.type,
        tests: labCatalog
          .filter((test) => preset.tests.includes(test.id))
          .map((test) => ({ label: test.name, value: test.id })),
      }))
    );
    setCatalog(labCatalog);
    // getFormFieldsByTemplateOption(labPresets[0], patient, provider);
  };

  useEffect(() => {
    getTests();
  }, []);

  const getFieldChangeHandler =
    (fieldId: string) => (value: string | Option[]) => {
      let updatedFields: any = { ...fields };
      // Check if Lipid Profile is included in the selected tests
      const isLipidProfilePresent =
        fieldId === "bloodSlipDetails.levelsToBeMeasured" &&
        value &&
        value.some(
          (test: Option) => test.value === TestsOrderValues.LIPID_PROFILE
        );
      // If Lipid Profile is included, set the alert text; otherwise, set it to null
      if (fieldId === "bloodSlipDetails.levelsToBeMeasured") {
        updatedFields = {
          ...updatedFields,
          [fieldId]: value,
          "bloodSlipDetails.alertText": isLipidProfilePresent
            ? "Important: Please fast for 12 hours before getting your bloodwork done. You are able to drink water while fasting."
            : null,
        };
      } else {
        updatedFields[fieldId] = value;
      }

      // Update the state with the new values
      setFields(updatedFields);
    };

  const onTemplateChangeHandler = (selectedTemplate: {
    label: string;
    value: TemplatesList | string;
  }) => {
    if (!selectedTemplate) {
      setUpdating(true);
      setFields(undefined);
      setDocumentName("");
      setUpdating(false);
      return;
    }

    const newFieldsValues = getFormFieldsByTemplateOption({
      presets,
      patient,
      provider,
      option: selectedTemplate,
    });
    setUpdating(true);
    setTimeout(() => {
      setFields({ ...fields, ...newFieldsValues });
      setDocumentName(
        getDocumentName(
          presets.find((preset) => preset.value === selectedTemplate.value).type
        )
      );
      setUpdating(false);
    }, 500);
  };

  const submitLabOrder = async () => {
    const fieldsToSend = parseFieldsToPdf(fields);

    const pdfMetadataObj = {
      patientId: patient.patientId,
      documentName:
        documentName ?? `Lab Slip for ${patient.fullName} ${getToday()}`,
      documentId: ENV_CONFIG.BLOOD_SLIP_TEMPLATE_ID,
      fields: fieldsToSend,
      isDefaultData: true,
    };

    const pdfMetadata = JSON.stringify(pdfMetadataObj);

    const testsValues = fields?.["bloodSlipDetails.levelsToBeMeasured"]?.map(
      (test: Option) => test.value
    );

    setUpdating(true);
    try {
      const result = await createLabOrder({
        patientId: patient.patientId,
        employeeId: state.userId,
        pdfMetadata,
        isImmediate,
        featureToggle: enabled,
        tests: { bloodwork: testsValues },
        message: isPregnancyOnly(
          fields?.["bloodSlipDetails.levelsToBeMeasured"]
        )
          ? PREGNANCY_TASK_TITLE
          : LAB_WORK_TASK_TITLE,
      });

      setUpdating(false);
      if (result.error) {
        showError({
          title: "Something went wrong...",
          description: "Unable to populate a blood slip",
        });
        return;
      }

      if (!enabled) {
        // populate blood slip the old way
        populatePdf(pdfMetadataObj);
      }

      showSuccess({
        title: "Success",
        description:
          "The patient will receive a task to choose their preferred lab",
      });
      onClose();
    } catch (error) {
      showError({
        title: "Something went wrong...",
        description: "Unable to create lab order",
      });
    }
  };

  return updating ? (
    <Loader className="updating-form-loader" />
  ) : (
    <div className="blood-slip-popup">
      <Button
        text="Create Lab Order"
        size="small"
        onClick={submitLabOrder}
        disabled={presets.length === 0 || catalog.length === 0 || !fields}
        className="blood-slip-popup__submit-mobile"
      />
      <div className="blood-slip-popup__content">
        <BloodSlipPopupSection
          title="Select Lab slip template"
          description=""
          fields={[
            <>
              <p className="select-label">Lab slip template</p>
              <StyledSelectCreatable
                id="blood-slip-template"
                name="bloodSlipTemplate"
                value={fields?.["bloodSlipDetails.template"]}
                onChange={onTemplateChangeHandler}
                placeholder="Select template"
                options={presets}
                formatCreateLabel={(inputValue: string) =>
                  `${inputValue} (custom)`
                }
                createOptionPosition="first"
                filterOption={(option: any, searchText: any) => {
                  if (!searchText) {
                    setShowCreation(false);
                    return true;
                  }
                  if (
                    option?.label
                      .toLowerCase()
                      .includes(searchText.toLowerCase())
                  ) {
                    return true;
                  }

                  setShowCreation(true);
                  return false;
                }}
                onCreateOption={(option) => {
                  const newFieldsValues = getFormFieldsByTemplateOption({
                    option,
                    patient,
                    provider,
                  });
                  setDocumentName(option);
                  setUpdating(true);
                  setTimeout(() => {
                    setFields({ ...fields, ...newFieldsValues });
                    setUpdating(false);
                  }, 0);
                }}
                isValidNewOption={() => shouldShowCreation}
                isSearchable
              />
            </>,
          ]}
        />
        <BloodSlipPopupSection
          title="Lab slip details"
          description=""
          fields={[
            <>
              <p className="select-label">Diagnosis code</p>
              <Input
                id="diagnosis-code"
                name="diagnosisCode"
                placeholder="Enter diagnosis code"
                value={fields?.["bloodSlipDetails.diagnosisCode"]}
                onChange={getFieldChangeHandler(
                  "bloodSlipDetails.diagnosisCode"
                )}
              />
            </>,
            <>
              <p className="select-label">Tests ordered</p>
              <Autocomplete
                disablePortal
                options={catalog.map((option) => ({
                  label: option.name,
                  value: option.id,
                }))}
                isOptionEqualToValue={(option, value) =>
                  option.value === value.value
                }
                multiple
                sx={{ height: "auto" }}
                defaultValue={
                  fields?.["bloodSlipDetails.levelsToBeMeasured"] ?? []
                }
                onChange={(_, values) => {
                  getFieldChangeHandler("bloodSlipDetails.levelsToBeMeasured")(
                    values
                  );
                }}
                renderInput={(params) => <AutocompleteInput {...params} />}
              />
            </>,
            <>
              <p className="select-label">Valid from</p>
              <DateSelect
                placeholder="Select date"
                value={fields?.["bloodSlipDetails.validFrom"]}
                onChange={getFieldChangeHandler("bloodSlipDetails.validFrom")}
              />
            </>,
            <>
              <p className="select-label">Frequency / duration</p>
              <Input
                id="frequencyDuration"
                name="frequencyDuration"
                placeholder="Enter duration"
                value={fields?.["bloodSlipDetails.duration"]}
                onChange={getFieldChangeHandler("bloodSlipDetails.duration")}
              />
            </>,
          ]}
        />
        <BloodSlipPopupSection
          title="Patient details"
          description=""
          fields={[
            <>
              <p className="select-label">Patient's name</p>
              <Input
                id="patientName"
                name="patientName"
                placeholder="Enter patient name"
                value={fields?.["patientDetails.name"]}
                onChange={getFieldChangeHandler("patientDetails.name")}
              />
            </>,
            <>
              <p className="select-label">Date of birth</p>
              <DateSelect
                disableFutureDates
                placeholder="Select date"
                value={fields?.["patientDetails.dateOfBirth"]}
                onChange={getFieldChangeHandler("patientDetails.dateOfBirth")}
              />
            </>,
          ]}
        />
        <BloodSlipPopupSection
          title="Provider's details"
          description=""
          fields={[
            <>
              <p className="select-label">Provider's name</p>
              <Input
                id="providerName"
                name="providerName"
                placeholder="Enter provider's name"
                value={fields?.["providerDetails.name"]}
                onChange={getFieldChangeHandler("providerDetails.name")}
              />
            </>,
            <>
              <p className="select-label">Npi number</p>
              <Input
                id="npiNumber"
                name="npiNumber"
                placeholder="Enter npi number"
                value={fields?.["providerDetails.npi"]}
                onChange={getFieldChangeHandler("providerDetails.npi")}
              />
            </>,
          ]}
        />
      </div>
      {/* <div className="blood-slip-popup__submit-desktop">
        <Button
          text="Create Lab Order"
          size="small"
          onClick={submitLabOrder}
          disabled={
            presets.length === 0 ||
            catalog.length === 0 ||
            fields?.["bloodSlipDetails.levelsToBeMeasured"]?.length === 0
          }
        />
      </div> */}
    </div>
  );
}

function SendBloodSlipPopupGenerator(sendBloodSlipData: SendBloodSlipData) {
  return function render({ onClose }: Props) {
    return (
      <ActionPopupTemplate title="Send blood slip form" onClose={onClose}>
        <SendBloodSlipPopup
          onClose={onClose}
          patient={sendBloodSlipData.patient}
          provider={sendBloodSlipData.provider}
          isImmediate={sendBloodSlipData.isImmediate}
        />
      </ActionPopupTemplate>
    );
  };
}

export default SendBloodSlipPopupGenerator;

export const getBloodSlipTemplate = (
  patient: Patient,
  provider: Provider,
  isPregnantCapability: boolean,
  option: any
) =>
  getFormFieldsByTemplateOption({
    option,
    patient,
    provider,
    isPregnantCapability,
  });

export const getPregnancyTestTemplate = (
  patient: Patient,
  provider: Provider,
  isPregnantCapability: boolean,
  option: any
) =>
  getFormFieldsByTemplateOption({
    option,
    patient,
    provider,
    isPregnantCapability,
  });
