import React from "react";
import { CardElement } from "@stripe/react-stripe-js";
import { Box, Grid, Typography } from "@mui/material";
import { ReactComponent as LockIcon } from "../../../../../Assets/NewIcons/lock.svg";
import { StyledCardInput } from "./StyledCardInput";
import { CustomTextInput } from "../../Material/TextInput";
import { CustomCheckbox } from "../../Material/Checkbox";
import { SectionTitle, Title } from "../../FindLab/styles";

interface Props {
  setCardHolderName: (value: string) => void;
  setIsValidCard: (value: boolean) => void;
  setAsDefault?: (value: boolean) => void;
  asDefault?: boolean;
  padding?: number;
}

export default function AddNewCard({
  setCardHolderName,
  setIsValidCard,
  setAsDefault,
  asDefault,
  padding = 2,
}: Props) {
  return (
    <Grid container direction="column" p={padding}>
      <Title gutterBottom>Add a credit or debit card</Title>
      <Grid container columnSpacing={2}>
        <Grid item xs={12} md={6}>
          <SectionTitle ml={2} mb={1}>
            ADD A CREDIT OR DEBIT CARD
          </SectionTitle>
          <StyledCardInput>
            <Box sx={{ flexGrow: 1 }}>
              <CardElement
                onChange={(event) => {
                  if (event.complete) {
                    setIsValidCard(true);
                  } else {
                    setIsValidCard(false);
                  }
                }}
              />
            </Box>
          </StyledCardInput>
          <Grid container columnGap={1} alignItems="center" mb={2} ml={2}>
            <LockIcon width={14} height={14} />
            <Typography variant="caption">
              Secured 256-bit TLS encrypted payment
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <SectionTitle ml={2} mb={1}>
            NAME ON CARD
          </SectionTitle>
          <CustomTextInput
            variant="outlined"
            placeholder="First and last name"
            onChange={(e) => setCardHolderName(e.target.value)}
          />
        </Grid>
      </Grid>
      {setAsDefault && (
        <Grid container alignItems="center" mb={2} mt={4}>
          <Grid item mr={1}>
            <CustomCheckbox
              checked={asDefault}
              onChange={() => setAsDefault(!asDefault)}
            />
          </Grid>
          <Grid item xs={11}>
            <Typography variant="body2" height="16px">
              Save and set as default payment method
            </Typography>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
}
