import React from "react";
import { useSelector } from "react-redux";
import { useFeatureIsOn } from "@growthbook/growthbook-react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { SurveyPage } from "../../../../types/CustomSurvey";
import { CustomSurvey } from "../../../NewComponents/Common/CustomSurvey";
import "./style.scss";
import { CalendlySurveyQuestion } from "./survey-questions/calendly";
import { useGetPatientByIdQuery } from "../../../../features/api/patients";
import { Loader } from "../../../NewComponents/Common/Loader";
import { getSessionState } from "../../../../features/session";
import { Step, useSurveySteps } from "../../../../hooks/use-survey-steps";
import { ENV_CONFIG } from "../../../../config";

const stripePromise = loadStripe(ENV_CONFIG.STRIPE_KEY);

export function SubmitAppointmentPage({ patientId }: { patientId: string }) {
  const patientQuery = useGetPatientByIdQuery(patientId);
  const { userId } = useSelector(getSessionState);
  const [moveFromCurrentStep] = useSurveySteps(Step.SCHEDULE_APPOINTMENT);
  const paywallFlag = useFeatureIsOn("paywall");

  if (!patientQuery.isSuccess) {
    return (
      <div className="main-area__loader-wrap">
        <div className="main-area__loader">
          <Loader />
        </div>
      </div>
    );
  }

  const surveyQuestions: SurveyPage[] = [
    {
      isFilled: () => false,
      Component: CalendlySurveyQuestion,
    },
  ];

  return (
    <Elements stripe={stripePromise}>
      <CustomSurvey
        title="SCHEDULE YOUR INITIAL VISIT"
        questions={surveyQuestions}
        data={{
          patient: patientQuery.data,
        }}
        onComplete={() => {
          moveFromCurrentStep(userId, paywallFlag);
          return true;
        }}
      />
    </Elements>
  );
}
