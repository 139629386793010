import React, { useRef, useState, useEffect } from "react";
import OutsideClickHandler from "../../../Components/Common/OutsideClickHandler/OutsideClickHandler";
import "./ActionButton.scss";

interface ActionButtonProps {
  children: JSX.Element | JSX.Element[];
  onOpenChange?: (isOpen: boolean) => void;
}

interface Position {
  top: number;
  left: number;
}

export function ActionButton({ children, onOpenChange }: ActionButtonProps) {
  const ref = useRef<HTMLButtonElement>(null);
  const [actionActivity, setActive] = useState({
    dotsActive: false,
    popupActive: false,
  });
  const [position, setPosition] = useState<Position>({ top: 0, left: 0 });

  const calculatePosition = () => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      setPosition({
        top: rect.bottom + 4,
        left: rect.right - 160,
      });
    }
  };

  const handleOnClick = () => {
    calculatePosition();
    const newState = {
      popupActive: !actionActivity.popupActive,
      dotsActive: !actionActivity.dotsActive,
    };
    setActive(newState);
    onOpenChange?.(newState.popupActive);
  };

  const onClickOutside = () => {
    setActive({ dotsActive: false, popupActive: false });
    onOpenChange?.(false);
  };

  useEffect(() => {
    if (!actionActivity.popupActive) {
      return undefined;
    }

    const handleScroll = () => calculatePosition();
    const handleResize = () => calculatePosition();

    window.addEventListener("scroll", handleScroll, true);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll, true);
      window.removeEventListener("resize", handleResize);
    };
  }, [actionActivity.popupActive]);

  const dotClassName = actionActivity.dotsActive
    ? "table-body-row-action-button-dot__active"
    : "table-body-row-action-button-dot";

  const buttonClassName = actionActivity.popupActive
    ? "table-body-row-action-button__active"
    : "table-body-row-action-button";

  return (
    <OutsideClickHandler onClickOutside={onClickOutside}>
      <button
        className={buttonClassName}
        onClick={handleOnClick}
        type="button"
        ref={ref}
      >
        <div className={dotClassName} />
        <div className={dotClassName} />
        <div className={dotClassName} />
        {actionActivity.popupActive && (
          <div
            className="table-body-row-action-button__popup"
            style={{
              top: `${position.top}px`,
              left: `${position.left}px`,
            }}
          >
            {children}
          </div>
        )}
      </button>
    </OutsideClickHandler>
  );
}
