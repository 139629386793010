import React from "react";
import { useBloodWorkStepper } from "../../../../../../contexts/blood-work";
import AddNewCardWrapper from "../../../../Common/Payment/AddNewCardWrapper/AddNewCardWrapper";

export default function BloodWorkAddNewCard() {
  return (
    <AddNewCardWrapper
      useWorkflowStepper={useBloodWorkStepper}
      workflowKey="bloodwork"
    />
  );
}
