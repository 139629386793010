import React from "react";
import { BaseTask, TaskInterface } from "..";
import { ActionPopupTemplate } from "../../../app/Components/Common/ActionPopup";
import { PopupLayerContext } from "../../../app/NewComponents/Common/PopupLayer";

export class MinorConsentFormTaskForParent
  extends BaseTask
  implements TaskInterface
{
  static type = "signMinorConsentForm";

  render(params: any) {
    const alreadyCompleted = false;

    window.hasTriggered = false;

    const listenerForIFrameEvents = async (e: MessageEvent) => {
      // Use a flag to ensure the listener only triggers once
      if (!window.hasTriggered) {
        const isThankYouPage = e.data.path?.includes("thank-you");
        if (isThankYouPage && typeof params.onComplete === "function") {
          window.hasTriggered = true;

          try {
            await params.onComplete(); // Complete task
          } catch (error) {
            console.error("Error in onComplete:", error);
          } finally {
            window.removeEventListener("message", listenerForIFrameEvents);
          }
        }
      }
    };

    window.addEventListener("message", listenerForIFrameEvents, true);
    return super.render(params);
  }

  action({ showPopup }: any) {
    showPopup(() => (
      <ActionPopupTemplate
        title="Sign minor consent form"
        onClose={() => console.log("CLOSE POPUP")}
      >
        <div
          className="sign-ipledge-consent-popup"
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          <div
            style={{
              position: "relative",
              margin: "24px 0 0",
              width: "100%",
              height: "100%",
            }}
          >
            <iframe
              src={this.task.document.link}
              style={{
                overflow: "scroll",
                height: "100%",
                width: "100%",
                position: "absolute",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                border: "none",
              }}
            />
          </div>
        </div>
      </ActionPopupTemplate>
    ));
  }
}
