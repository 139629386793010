import React, { useContext, useState } from "react";
import { CircularProgress } from "@mui/material";
import { PopupLayerContext } from "../../Common/PopupLayer";
import { ColumnsConfig } from "../../../Components/Common/Table/types";
import { ReactComponent as AddIcon } from "../../../../Assets/NewIcons/add.svg";
import Table from "../../../Components/Common/Table/Table";
import UploadDocumentsPopupGenerator from "../UploadDocumentsPopup";
import "./style.scss";
import { AccutaneDocument } from "../../../../services/acneaway-api/entities/accutane/get-documents";
import { getMomentDate } from "../../../../utils/get-date-pretty";
import DocumentOverviewPopupGenerator from "../../Provider/DocumentOverviewPopup";
import { downloadDocument } from "../../../../utils/download-document";
import { useGetDocumentsByPatientIdQuery } from "../../../../features/api/accutane";
import { ActionButton } from "../../Common/ActionButton/ActionButton";

const dateFormat = "MM/DD/YY";

function CommentRenderer(
  text: string | number | undefined,
  record: AccutaneDocument
) {
  const hasComment = !!text;
  const { signedBy } = record;

  return (
    <div className="comment-column">
      <p className="comment-column__comment">
        {hasComment ? text : "No comment"}
      </p>
      <p className="comment-column__signed">
        {signedBy?.name
          ? `Signed by ${signedBy.name} on ${getMomentDate(
              signedBy.createdAt
            ).format(dateFormat)}`
          : ""}
      </p>
    </div>
  );
}

const getTableData = (documents: AccutaneDocument[]) =>
  documents.map((document) => ({
    id: document.id,
    key: document.id,
    title: document.title,
    date: getMomentDate(document.createdAt).format("MM/DD/YY"),
    uploadedBy: document.uploadedBy.name,
    comment: document.comment,
    url: document.url,
    signedBy: document.signedBy,
  }));

function DocumentsActionsColumn(_: any, record: AccutaneDocument) {
  const { url, title } = record;

  return (
    <ActionButton>
      <p
        className="table-body-row-action-button__popup-options"
        onClick={downloadDocument(url, title)}
      >
        Download document
      </p>
    </ActionButton>
  );
}

function PatientDocuments({ userId }: { userId: string }) {
  const { showPopup } = useContext(PopupLayerContext);
  const maxPage = 8;
  const [currentPage, setPage] = useState(0);
  const { data: documents, isSuccess } =
    useGetDocumentsByPatientIdQuery(userId);

  const onRowClick = (rowData: AccutaneDocument) => {
    showPopup(
      DocumentOverviewPopupGenerator(
        rowData.title,
        userId,
        rowData.id,
        rowData.url,
        "",
        true
      )
    );
  };

  const onPageChange = (current: number) => {
    setPage(current);
  };

  const pagination = {
    maxPage: Math.ceil((documents?.length || 1) / 8),
    currentPage,
    onPageChange,
  };

  const columns: ColumnsConfig[] = [
    {
      header: "TITLE",
      dataIndex: "title",
      key: "title",
      onClick: onRowClick,
      render: (text, record) => (
        <div>
          <p>{record.title} </p>
          <p>{record.date}</p>
        </div>
      ),
    },
    {
      header: "UPLOADED BY",
      dataIndex: "uploadedBy",
      key: "age",
      onClick: onRowClick,
      render: (text) => <span>{text || "N/A"}</span>,
    },
    {
      header: "COMMENT",
      dataIndex: "comment",
      key: "address",
      width: "496px",
      onClick: onRowClick,
      render: CommentRenderer,
    },
    {
      header: " ",
      dataIndex: "actions",
      key: "actions",
      render: DocumentsActionsColumn,
    },
  ];

  const handleAddClick = () => {
    showPopup(UploadDocumentsPopupGenerator());
  };

  return (
    <div className="patient-documents">
      <div className="patient-documents-header__wrapper">
        <p className="patient-documents-header__wrapper-title">Documents</p>
        <div className="patient-documents-header__wrapper-extra">
          <AddIcon onClick={handleAddClick} />
        </div>
      </div>
      <div>
        {!isSuccess ? (
          <CircularProgress />
        ) : (
          <Table
            pagination={pagination}
            data={getTableData(documents)}
            columns={columns}
            className="patient-documents__table"
          />
        )}
      </div>
    </div>
  );
}

export default PatientDocuments;
