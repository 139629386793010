import React, { useContext, useEffect, useState, useRef } from "react";
import { Typography } from "@mui/material";
import StepperButtons from "../../../Stepper/StepperButtons";
import PaymentDetails from "../../../Common/Payment/PaymentDetails";
import { CustomerInfo } from "../../../../../types/CustomerInfo";
import { usePregnancyWorkStepper } from "../../../../../contexts/pregnancy-work";
import { PregnancyWorkPopupSteps } from "../steps";
import { NotificationLayerContext } from "../../../Common/NotificationLayer";
import { updateLabOrder } from "../../../../../services/honeydew-api/labs/update-lab-order";
import { getChargeItems } from "../../../../../utils/lab-work/get-charge-items";
import {
  parseBackendError,
  getUserFriendlyErrorMessage,
  getErrorTitle,
} from "../../../../../types/Errors/lab-errors";
import { PAYMENT_CONSTANTS, SUPPORT_MESSAGE } from "../../../../../constants";

export default function PaymentDetailsWrapper() {
  const {
    handleBack,
    handleNext,
    setIsNextDisabled,
    isNextDisabled,
    updateValues,
    pregnancyWork,
  } = usePregnancyWorkStepper();

  const [consent, setConsent] = useState(false);
  const [loading, setLoading] = useState(false);
  const [processingMessage, setProcessingMessage] = useState<string>("");
  const { showError } = useContext(NotificationLayerContext);
  const messageTimerRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    setIsNextDisabled(!consent);
  }, [consent]);

  useEffect(
    () => () => {
      setLoading(false);
      setProcessingMessage("");
      if (messageTimerRef.current) {
        clearTimeout(messageTimerRef.current);
      }
    },
    []
  );

  const onPaymentChange = (customerInfo: CustomerInfo) => {
    updateValues({ customerInfo });
    handleNext(PregnancyWorkPopupSteps.PAYMENT_METHOD);
  };

  const onDefaultPaymentMethodChange = (paymentMethodId?: string) => {
    if (paymentMethodId) {
      updateValues({ selectedPaymentMethod: paymentMethodId });
    }
  };

  const handleCheckout = async () => {
    setLoading(true);
    setProcessingMessage(PAYMENT_CONSTANTS.INITIAL_PROCESSING_MESSAGE);

    messageTimerRef.current = setTimeout(() => {
      setProcessingMessage(PAYMENT_CONSTANTS.EXTENDED_PROCESSING_MESSAGE);
    }, PAYMENT_CONSTANTS.PROCESSING_MESSAGE_DELAY);

    try {
      const { priceDetails, totalPrice, ...lab } = pregnancyWork.selectedLab;

      await updateLabOrder({
        taskId: pregnancyWork.task?.id,
        placeOfService: "lab",
        selectedPaymentMethod: pregnancyWork.selectedPaymentMethod,
        charges: getChargeItems({ priceDetails }),
        customerId: pregnancyWork.patient?.customerId,
        paymentMethod: pregnancyWork.paymentMethod,
        lab,
      });

      handleNext();
    } catch (error: unknown) {
      const labOrderError = parseBackendError(error as Error);

      showError({
        title: labOrderError ? getErrorTitle(labOrderError) : "Error",
        description: (
          <Typography>
            {labOrderError
              ? getUserFriendlyErrorMessage(labOrderError)
              : "An unexpected error occurred. Please try again."}
            <Typography color={SUPPORT_MESSAGE.COLOR} sx={{ mt: 1 }}>
              {SUPPORT_MESSAGE.TEXT}
            </Typography>
          </Typography>
        ),
        duration: PAYMENT_CONSTANTS.ERROR_DURATION,
      });
    } finally {
      if (messageTimerRef.current) {
        clearTimeout(messageTimerRef.current);
      }
      setLoading(false);
      setProcessingMessage("");
    }
  };

  return (
    <>
      <PaymentDetails
        consent={consent}
        setConsent={setConsent}
        handleChangePaymentMethod={onPaymentChange}
        defaultPaymentMethod={pregnancyWork?.selectedPaymentMethod}
        handleDefaultPaymentMethodChange={onDefaultPaymentMethodChange}
        lab={pregnancyWork?.selectedLab}
      />
      {processingMessage && (
        <Typography pl={2} variant="subtitle2">
          {processingMessage}
        </Typography>
      )}
      <StepperButtons
        backText="BACK"
        confirmText="Checkout"
        onBack={handleBack}
        onConfirm={handleCheckout}
        loading={loading}
        isConfirmDisabled={
          isNextDisabled || !pregnancyWork.selectedPaymentMethod
        }
      />
    </>
  );
}
