import React, { useMemo } from "react";
import { Typography, Grid } from "@mui/material";
import { useBloodWorkStepper } from "../../../../../contexts/blood-work";
import { getSteps } from "./steps";
import CustomStepper from "../../../Common/Material/Stepper";
import { Button } from "../../../Common/Material/Button";
import { calculateProgress } from "../../../../../utils/lab-work/get-bloodwork-step";
import { BloodWorkPopupSteps } from "../steps";

export default function Checkout() {
  const { handleNext, bloodwork, setCurrentStep } = useBloodWorkStepper();

  const activeStep = useMemo(
    () => calculateProgress(bloodwork.task?.histories ?? []),
    [bloodwork]
  );

  const handleTipsClick = () => {
    setCurrentStep(BloodWorkPopupSteps.TIPS_FOR_LAB);
  };

  return (
    <>
      <Grid container direction="column" p={2}>
        <Typography variant="h5" gutterBottom>
          How to complete your lab test
        </Typography>
        <CustomStepper
          steps={getSteps({
            needsFasting: bloodwork.needsFasting,
            lab: bloodwork?.selectedLab || bloodwork?.labOrder?.lab,
          })}
          activeStep={activeStep}
        />
      </Grid>
      <Grid
        container
        gap={2}
        justifyContent="center"
        padding={2}
        paddingTop={0}
      >
        <Grid item>
          <Button
            text="Got it, back to care hub"
            size="small"
            fullWidth
            onClick={handleNext}
          />
        </Grid>
        <Grid item>
          <Button
            view="secondary"
            text="Tips for your lab visit"
            size="small"
            fullWidth
            onClick={handleTipsClick}
          />
        </Grid>
      </Grid>
    </>
  );
}
