export enum LabOrderErrorCode {
  // Payment related errors
  PAYMENT_FAILED = "PAYMENT_FAILED",
  PAYMENT_DECLINED = "PAYMENT_DECLINED",
  PAYMENT_METHOD_INVALID = "PAYMENT_METHOD_INVALID",
  PAYMENT_DATA_INCOMPLETE = "PAYMENT_DATA_INCOMPLETE",

  // Order processing errors
  ORDER_ALREADY_PROCESSED = "ORDER_ALREADY_PROCESSED",
  ORDER_NOT_FOUND = "ORDER_NOT_FOUND",
  ORDER_VALIDATION_FAILED = "ORDER_VALIDATION_FAILED",

  // Vital integration errors
  VITAL_PROCESS_FAILED = "VITAL_PROCESS_FAILED",
  VITAL_USER_CREATION_FAILED = "VITAL_USER_CREATION_FAILED",
  VITAL_ORDER_CREATION_FAILED = "VITAL_ORDER_CREATION_FAILED",

  // System errors
  SYSTEM_ERROR = "SYSTEM_ERROR",
}

export enum ErrorCategory {
  PAYMENT = "PAYMENT",
  ORDER = "ORDER",
  VITAL = "VITAL",
  SYSTEM = "SYSTEM",
}

export interface LabOrderError {
  code: LabOrderErrorCode;
  category: ErrorCategory;
  message: string;
  details?: string;
  userMessage?: string;
}

// Helper function to parse error from backend
export const parseBackendError = (error: any): LabOrderError | null => {
  try {
    if (error?.message) {
      const parsedError = JSON.parse(error.message);

      // Check if the details contain another structured error
      if (parsedError.details) {
        try {
          const nestedError = JSON.parse(parsedError.details);
          if (nestedError.code && nestedError.category) {
            return nestedError as LabOrderError;
          }
        } catch {
          // If nested parsing fails, continue with original error
        }
      }

      if (parsedError.code && parsedError.category) {
        return parsedError as LabOrderError;
      }
    }
    return null;
  } catch {
    return null;
  }
};

// Helper to get user-friendly error messages
export const getUserFriendlyErrorMessage = (error: LabOrderError): string =>
  error.userMessage || error.message || "An unexpected error occurred";

// Helper to get error title based on category
export const getErrorTitle = (error: LabOrderError): string => {
  switch (error.category) {
    case ErrorCategory.PAYMENT:
      return "Payment Failed";
    case ErrorCategory.ORDER:
      return "Order Processing Failed";
    case ErrorCategory.VITAL:
      return "Lab Order Failed";
    default:
      return "Error";
  }
};
