import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ENV_CONFIG } from "../../config";
import { AuthenticationService } from "../../services/cognito";
import {
  FollowUp,
  FollowUpImage,
  FollowUpQuestionnaire,
  FollowUpTreatmentPlan,
} from "../../types/FollowUp";

const followUpFieldsQuery = `{
  patientId
  followUpId
  timestamp
  images {
    id
    side
    fileName
    description
    type
  }
  treatmentPlan {
    message
    timestamp
    medications {
      instructions
      medicineKey
      refillExpirationDate
      refillsCount
      specialInstructions
    }
    isPrescriptionSubmitted
    isReadByPatient
    submittedBy
    preset
    nextFollowUpDate
  }
  questionnaire {
    id
    type
    value
  }
}`;

export const followUpsApiSlice = createApi({
  reducerPath: "api/follow-ups",
  tagTypes: ["follow-up"],
  baseQuery: fetchBaseQuery({
    baseUrl: ENV_CONFIG.FOLLOW_UP_SERVICE_GRAPHQL_URL,
    prepareHeaders: async (headers) => {
      const token = await AuthenticationService.getAccessToken();
      headers.set("Authorization", token);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getFollowUp: builder.query<FollowUp, string>({
      query: (followUpId: string) => ({
        url: "",
        params: {
          query: `query {
            getFollowUp(followUpId: "${followUpId}") ${followUpFieldsQuery}
          }`,
        },
      }),
      transformResponse: (response: any) => response.data.getFollowUp,
      providesTags: (response: any) =>
        response ? [{ type: "follow-up", id: response.followUpId }] : [],
    }),
    getFollowUpsByPatientId: builder.query<FollowUp[], string>({
      query: (patientId) => ({
        url: "",
        params: {
          query: `query { getFollowUpsByPatientId(patientId: "${patientId}") ${followUpFieldsQuery} }`,
        },
      }),
      transformResponse: (response: any) =>
        response.data.getFollowUpsByPatientId,
      providesTags: (response) =>
        response
          ? response.map((it) => ({ type: "follow-up", id: it.followUpId }))
          : [],
    }),
    submitFollowUp: builder.mutation<
      FollowUp,
      {
        patientId: string;
        images: FollowUpImage[];
        questionnaire?: FollowUpQuestionnaire[];
      }
    >({
      query: (payload) => ({
        url: "",
        method: "POST",
        body: JSON.stringify({
          query: `mutation ($payload: FollowUpPayloadInput!) { submitFollowUp(payload: $payload) { followUpId } }`,
          variables: JSON.stringify({
            payload,
          }),
        }),
      }),
      transformResponse: (result: any) => {
        if (result.data.errors) {
          throw new Error("Error occured during follow-up submission");
        }
        return result.data.submitFollowUp;
      },
      invalidatesTags: (result) => (result ? [{ type: "follow-up" }] : []),
    }),
    submitTreatmentPlan: builder.mutation<
      { followUpId: string },
      { followUpId: string; payload: FollowUpTreatmentPlan; isEdit?: boolean }
    >({
      query: ({ followUpId, payload, isEdit }) => {
        const adoptedPayload = {
          ...payload,
          medications: payload.medications.map(
            ({
              medicineKey,
              refillExpirationDate,
              refillsCount,
              instructions,
              specialInstructions,
            }) => ({
              medicineKey,
              instructions,
              specialInstructions,
              ...(refillsCount === null ? {} : { refillsCount }),
              ...(refillExpirationDate === null
                ? {}
                : { refillExpirationDate }),
            })
          ),
        };
        return {
          url: "",
          method: "POST",
          body: JSON.stringify({
            query: `mutation ($treatmentPlan: TreatmentPlanInput!, $followUpId: String!, $isEdit: Boolean) { submitTreatmentPlan(followUpId: $followUpId, treatmentPlan: $treatmentPlan, isEdit: $isEdit) { followUpId } }`,
            variables: JSON.stringify({
              treatmentPlan: adoptedPayload,
              followUpId,
              isEdit,
            }),
          }),
        };
      },
      transformResponse: (result: any) => {
        if (result.errors) {
          throw new Error("Error occured during treatment plan submission");
        }
        return result.data.submitTreatmentPlan;
      },
      invalidatesTags: (result) =>
        result ? [{ type: "follow-up", id: result.followUpId }] : [],
    }),
    markTreatmentPlanAsRead: builder.mutation<{ followUpId: string }, string>({
      query: (payload) => ({
        url: "",
        method: "POST",
        body: JSON.stringify({
          query: `mutation ($followUpId: String!) { markAsRead(followUpId: $followUpId) { followUpId } }`,
          variables: JSON.stringify({
            followUpId: payload,
          }),
        }),
      }),
      transformResponse: (result: any) => {
        if (result.data.errors) {
          throw new Error("Error occured during marking follow-up as read");
        }
        return result.data.markAsRead;
      },
      invalidatesTags: (result) =>
        result ? [{ type: "follow-up", id: result.followUpId }] : [],
    }),
    submitPrescription: builder.mutation<{ followUpId: string }, string>({
      query: (payload) => ({
        url: "",
        method: "POST",
        body: JSON.stringify({
          query: `mutation ($followUpId: String!) { submitPrescription(followUpId: $followUpId) { followUpId } }`,
          variables: JSON.stringify({
            followUpId: payload,
          }),
        }),
      }),
      transformResponse: (result: any) => {
        if (result.data.errors) {
          throw new Error("Error occured during submitting prescription");
        }
        return result.data.submitPrescription;
      },
      invalidatesTags: (result) =>
        result ? [{ type: "follow-up", id: result.followUpId }] : [],
    }),
  }),
});

export const {
  useGetFollowUpQuery,
  useGetFollowUpsByPatientIdQuery,
  useSubmitFollowUpMutation,
  useSubmitTreatmentPlanMutation,
  useMarkTreatmentPlanAsReadMutation,
  useSubmitPrescriptionMutation,
} = followUpsApiSlice;
